import { createBasicSelector } from 'util/redux'
import { selectIsInSocialChat } from 'selectors/location'

export const selectBasicLinkUrlForRoomId = createBasicSelector(
  selectIsInSocialChat,
  (_state, roomId) => roomId,
  (isSocialChat, roomId) => {
    if (isSocialChat) {
      return `${window.location.origin}/social?conversationId=${roomId}`
    }
    return `${window.location.origin}/chats?conversationId=${roomId}`
  }
)
