import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { withUndoSend } from 'ducks/undoSend/request'
import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import { CONVERSATION_REPLY } from '../actionTypes'
import { updateConversationReplyMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE, SNOOZED } from '../constants'
import { conversationReplyGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import {
  onUpdateAttachEventGroupIdToEvents,
  withAutoRedirect,
  withBeforeSend,
} from '../utils/request'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { selectWillAutoRedirectForTicketId } from '../selectors/selectWillAutoRedirectForTicketId'
import {
  attachmentsToEmailAttachmentInput,
  parseToApiSnoozeUntil,
} from '../utils/payload'

export const doReply = (
  ticketId,
  body,
  to,
  cc,
  bcc,
  subject,
  inputAttachments,
  inputCannedReplyId,
  draftId,
  inputState,
  inputSnoozeUntil,
  tagIdsToAdd,
  tagIdsToRemove,
  removeAllTags,
  inputAgentId,
  inputTeamId,
  inputChannelId,
  options = {}
) => async (dispatch, getState) => {
  const attachments = attachmentsToEmailAttachmentInput(inputAttachments)
  const conversationId = buildId('Conversation', ticketId)
  const agentId = buildId('Agent', inputAgentId)
  const teamId = buildId('Team', inputTeamId)
  const channelId = buildId('Channel', inputChannelId)
  const cannedReplyId = buildId('CannedReply', inputCannedReplyId)

  const store = getState()
  const ticket = selectCurrentConversationById(store, ticketId)
  const snoozeUntil = parseToApiSnoozeUntil(
    inputState === SNOOZED
      ? inputSnoozeUntil || ticket.snoozed?.until
      : inputSnoozeUntil
  )
  const state = inputSnoozeUntil ? SNOOZED : inputState
  if (!ticket || isNullOrUndefined(body) || !to || !to[0]) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      subject,
      state,
      snoozeUntil,
      tagIdsToAdd,
      tagIdsToRemove,
      removeAllTags,
      agentId,
      teamId,
      channelId,
      reply: {
        body,
        attachments: inputAttachments,
        to,
        cc,
        bcc,
      },
    },
    options
  )

  const backHref = window.location.href
  const willAutoRedirect = selectWillAutoRedirectForTicketId(
    getState(),
    ticketId,
    'reply',
    !!options?.moduleOptions?.autoRedirect?.forced
  )

  return dispatch(
    withUndoSend(
      draftId,
      withBeforeSend(
        ticketId,
        draftId,
        withAutoRedirect(
          ticketId,
          (afterStartedActions, afterSuccessActions) => async () =>
            dispatch(
              doGraphqlRequest(
                CONVERSATION_REPLY,
                updateConversationReplyMutation(),
                {
                  conversationId,
                  body,
                  // In future we should remove this once we support having multiple to
                  // addresses
                  to: to[0],
                  cc,
                  bcc,
                  subject,
                  attachments,
                  cannedReplyId,
                  draftId,
                  state,
                  snoozeUntil,
                  tagIdsToAdd,
                  tagIdsToRemove,
                  removeAllTags,
                  agentId,
                  teamId,
                  channelId,
                  skipNotifications: !!options.skipNotifications,
                },
                mergePlain(
                  {
                    app: true,
                    throwOnError: true,
                    concurrency: {
                      key: ticketId,
                      message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                    },
                    normalizationSchema: conversationReplyGraphQlResponseSchema,
                    transformResponse: onUpdateAttachEventGroupIdToEvents(
                      'conversationReply'
                    ),
                    optimist,
                    moduleOptions: {
                      snackbar: {
                        enabled: true,
                        started: {
                          enabled: true,
                          message: 'Sending your reply...',
                        },
                        success: {
                          enabled: true,
                          message: `Your email has been sent.`,
                          link: willAutoRedirect && {
                            href: backHref,
                            text: `Go back to ${app.t('Ticket')}`,
                          },
                        },
                        failed: {
                          enabled: true,
                          message: `Oops, we couldn't send your reply. Please try again in a moment.`,
                        },
                      },
                      entities: {
                        additionalActions,
                      },
                    },
                    meta: {
                      mergeEntities: true,
                    },
                    afterStartedActions,
                    afterSuccessActions,
                  },
                  options
                )
              )
            ),
          mergePlain(
            {
              moduleOptions: {
                autoRedirect: {
                  enabled: true,
                  operation: 'reply',
                },
              },
            },
            options
          )
        )
      )
    )
  )
}
