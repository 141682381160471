/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { FETCH_RULES_SUCCESS } from '../actionTypes'

const totalCountInitialState = null

const reducers = {}

// We only want to update the rules totalCount in state if we're loading rules
// for a completely unflitered state.
reducers[FETCH_RULES_SUCCESS] = (draftState, action) => {
  const {
    payload: { rules: { edges = [] } = {} } = {},
    request: { parameters = {} } = {},
  } = action
  const totalCount = edges?.length
  if ([undefined, null].includes(totalCount) || parameters?.filter?.search)
    return draftState
  return totalCount
}

export default createActionTypeReducer(reducers, totalCountInitialState)
