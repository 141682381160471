import React from 'react'
import { times } from 'util/arrays'
import ExpandedChangesetPlaceholder from 'components/App/DesktopView/Layout/TicketInspector/NormalState/TicketChangesetPlaceholder/ExpandedChangesetPlaceholder'
import styles from './styles.less'

export default function TicketPreviewPlaceholder({ count }) {
  return (
    <>
      {times(count, index => (
        <ExpandedChangesetPlaceholder
          key={`ticket-preview-placeholder-${index}`}
          className={styles.placeholder}
        />
      ))}
    </>
  )
}
