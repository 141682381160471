import merge from 'deepmerge'

export function searchStarted(queryId) {
  return {
    searches: {
      queries: {
        [queryId]: {
          type: 'STARTED',
        },
      },
    },
  }
}

export function searchSuccess({
  queryId,
  nodes,
  currentCursor,
  startCursor,
  endCursor,
  hasNextPage,
  hasPreviousPage,
  totalCount,
  totalPageCount,
}) {
  return {
    searches: {
      queries: {
        [queryId]: {
          type: 'SUCCESS',
          result: {
            nodes,
            pageInfo: { startCursor, endCursor, hasNextPage, hasPreviousPage },
            totalCount,
            totalPageCount,
          },
          request: { cursor: currentCursor },
        },
      },
    },
  }
}

export function searchFailed(queryId, currentCursor, error) {
  return {
    searches: {
      queries: {
        [queryId]: {
          type: 'FAILED',
          error,
          request: { cursor: currentCursor },
        },
      },
    },
  }
}

export function searchInvalidate(queryId) {
  return {
    searches: {
      queries: {
        [queryId]: {
          type: 'INVALIDATE',
        },
      },
    },
  }
}

export function searchUnload(queryId) {
  return {
    searches: {
      queries: {
        [queryId]: {
          type: 'UNLOAD',
        },
      },
    },
  }
}

export function searchInvalidateEntity(entityTypes) {
  return {
    searches: {
      invalidateEntities: entityTypes,
    },
  }
}

export function searchUpdateCursor(queryId, updates) {
  return {
    searches: {
      updateCursor: {
        [queryId]: updates,
      },
    },
  }
}

export function searchAddCursorEntityIds(queryId, entityIds) {
  return {
    searches: {
      addCursorEntityIds: {
        [queryId]: entityIds,
      },
    },
  }
}

export function searchRemoveCursorEntityIds(queryId, entityIds) {
  return {
    searches: {
      removeCursorEntityIds: {
        [queryId]: entityIds,
      },
    },
  }
}

export function mergeSearchChanges(searchChanges) {
  return merge.all([...searchChanges.filter(x => !!x)])
}
