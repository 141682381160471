import { createBasicSelector } from 'util/redux'
import { selectCurrentConversationById } from '.'
import { selectCurrentTicketId } from './selectCurrentTicketId'

export const selectCurrentDenormalizedConversation = createBasicSelector(
  state => state,
  selectCurrentTicketId,
  (state, currentConversationId) => {
    return selectCurrentConversationById(
      state,
      currentConversationId,
      null,
      true
    )
  }
)
