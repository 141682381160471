export const ruleFields = ({
  includeActions = false,
  includeTriggers = false,
  includeConditions = false,
} = {}) => `
createdAt
creator {
    id
}
description
position
matchType
id
name
position
state
updatedAt
lastRunAt
totalExecutions
scheduleType
scheduleSettings {
  day
  dayOfWeek
  days
  seconds
  time
}
stopUpcoming
${
  !includeConditions
    ? ''
    : `
  conditions(first: 2000) {
      edges {
        node {
          id
          param
          operator
          value
          source {
            ...on CustomField {
              id
              key
            }
          }
        }
      }
    }
  `
}

  ${
    !includeTriggers
      ? ''
      : `triggers {
          edges {
            node {
              id
              type
              settings
            }
          }
        }`
  }

${
  !includeActions
    ? ''
    : `
  actions {
    edges {
      node {
        id
        type
        value
        toAgent
        replyTemplate {
          id
          body
          subject
          attachments {
            edges {
              node {
                id
                fileName
                fileSize
                url
                creator {
                  id
                }
                createdAt
              }
            }
          }
        }
      }
    }
  }
`
}  

`

// Abit of a hack here, but im loading all actions triggers and condtions with the list call even
// when this gets re-loaded on the individual rule screen. The GQLV2 api requires all the fields to
// be provided in order to update the rule. This means that even just re-ordering requires all fields.
// If we tweak the mutations to make those fields optional, we could lighten the load here.
export const fetchRulesQuery = () => `
query RulesQuery($after: String, $state: RuleState) {
  rules(first: 100, after: $after, filter: { state: $state }) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      node {
        ${ruleFields({
          includeActions: true,
          includeTriggers: true,
          includeConditions: true,
        })}
      }
    }
  }
}
`

export const fetchRuleQuery = () => `
query RuleQuery($ruleId: ID!) {
  node(id: $ruleId) {
    ... on Rule {
      ${ruleFields({
        includeActions: true,
        includeTriggers: true,
        includeConditions: true,
      })}
    }
  }
}
`

export const deleteRuleMutation = () => `
mutation RuleDelete($ruleId: ID!) {
  ruleDelete(input: { ruleId: $ruleId }) {
    deletedRuleId
    errors {
      path
      message
    }
  }
}
`

export const updateRuleMutation = () => `
mutation RuleUpdate(
  $ruleId: ID!,
  $name: String!,
  $description: String,
  $matchType: RuleMatchTypes!,
  $state: RuleState!,
  $position: Int,
  $conditions: [RuleConditionInput!]!,
  $triggers: [TriggerInput!]!,
  $actions: [ActionInput!]!,
  $scheduleType: ScheduleType,
  $scheduleSettings: FeatureTemplateRuleScheduleSettingsInput,
  $stopUpcoming: Boolean!
) {
  ruleUpdate(
    input: {
      ruleId: $ruleId,
      name: $name,
      description: $description,
      matchType: $matchType,
      state: $state,
      position: $position,
      conditions: $conditions,
      triggers: $triggers,
      actions: $actions,
      scheduleType: $scheduleType,
      scheduleSettings: $scheduleSettings,
      stopUpcoming: $stopUpcoming,
    }
  ) {
    rule {
      ${ruleFields({
        includeActions: true,
        includeTriggers: true,
        includeConditions: true,
      })}
    }
    errors {
      path
      message
    }
  }
}
`

export const createRuleMutation = () => `
mutation RuleCreate(
  $name: String!,
  $description: String,
  $matchType: RuleMatchTypes!,
  $state: RuleState!,
  $position: Int,
  $conditions: [RuleConditionInput!]!,
  $triggers: [TriggerInput!]!,
  $actions: [ActionInput!]!,
  $scheduleType: ScheduleType,
  $scheduleSettings: FeatureTemplateRuleScheduleSettingsInput,
  $stopUpcoming: Boolean!
) {
  ruleCreate(
    input: {
      name: $name,
      description: $description,
      matchType: $matchType,
      state: $state,
      position: $position,
      conditions: $conditions,
      triggers: $triggers,
      actions: $actions,
      scheduleType: $scheduleType,
      scheduleSettings: $scheduleSettings,
      stopUpcoming: $stopUpcoming,
    }
  ) {
    rule {
      ${ruleFields({
        includeActions: true,
        includeTriggers: true,
        includeConditions: true,
      })}
    }
    errors {
      path
      message
    }
  }
}
`
