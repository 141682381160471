// We dont technically need this file, but its a "helper" file which shows all the components using
// useDataTable and also ensures that 2 tables dont have conflicting table ids
export default {
  SETTINGS_CANNED_REPLY_TABLE_ID: 'scr',
  SETTINGS_CANNED_REPLY_CATEGORY_TABLE_ID: 'scrc',
  SETTINGS_FOLDER_TABLE_ID: 'sf',
  SETTINGS_FOLDER_TABLE_ID_INACTIVE: 'sfi',
  SETTINGS_RULE_TABLE_ID: 'sa',
  SETTINGS_RULE_TABLE_ID_INACTIVE: 'sai',
  SETTINGS_USER_TABLE_ID: 'ag',
  SETTINGS_TAG_TABLE_ID: 'st',
  SETTINGS_INTEGRATIONS_TABLE_ID: 'si',
  SETTINGS_CHANNEL_TABLE_ID: 'sc',
  SETTINGS_WEBHOOK_TABLE_ID: 'sw',
  SETTINGS_THIRDPARTY_ACCESSTOKEN_TABLE_ID: 'tat',
  SETTINGS_BLACKLIST_TABLE_ID: 'blk',
  SETTINGS_EXPORTS_TABLE_ID: 'exp',
  SETTINGS_INVOICE_TABLE_ID: 'sin',
  SETTINGS_WIDGET_TABLE_ID: 'swt',
  SETTINGS_CUSTOMER_RATING_SETTINGS_TABLE: 'scrs',
  SETTINGS_CRM_CONTACTS_TABLE: 'sct',
  SETTINGS_CRM_CONTACT_CONVERSATIONS_TABLE: 'sctc',
}
