import { createBasicSelector } from 'util/redux'
import { selectIsInChat } from 'selectors/location'
import { selectBasicLinkUrlForTicketId } from './selectBasicLinkUrlForTicketId'
import { selectBasicLinkUrlForRoomId } from './selectBasicLinkUrlForRoomId'

export const selectBasicLinkUrlForConversationId = createBasicSelector(
  selectBasicLinkUrlForTicketId,
  selectBasicLinkUrlForRoomId,
  selectIsInChat,
  (ticketUrl, chatUrl, isChat) => {
    return isChat ? chatUrl : ticketUrl
  }
)
