import { mergePlain } from 'util/merge'
import { OPENED } from '../constants'
import { doUpdateState } from './doUpdateState'

export const doUnsnooze = (ticketId, options = {}) => dispatch => {
  return dispatch(
    doUpdateState(
      ticketId,
      OPENED,
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('Ticket')} un-snoozed`,
              },
              failed: {
                enabled: true,
                message: `Oops, we couldn't un-snooze your ${app.t('ticket')}.`,
              },
            },
          },
        },
        options
      )
    )
  )
}
