import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { UPDATE_CONVERSATION_UNASSIGN } from '../actionTypes'
import { updateConversationUnassignMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { selectCurrentConversationById } from '../selectors'
import { conversationUnassignGraphQlResponseSchema } from '../schema'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import {
  onUpdateAttachEventGroupIdToEvents,
  withAutoRedirect,
} from '../utils/request'
import { selectWillAutoRedirectForTicketId } from '../selectors/selectWillAutoRedirectForTicketId'

export const doUnassign = (
  ticketId,
  unassignTeam,
  unassignAgent,
  options = {}
) => async (dispatch, getState) => {
  const conversationId = buildId('Conversation', ticketId)
  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)

  if (!ticket && (!unassignTeam && !unassignAgent)) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      agentId: null,
      teamId: null,
    },
    options
  )

  const backHref = window.location.href
  const willAutoRedirect = selectWillAutoRedirectForTicketId(
    getState(),
    ticketId,
    'save',
    !!options?.moduleOptions?.autoRedirect?.forced
  )

  return dispatch(
    withAutoRedirect(
      ticketId,
      (afterStartedActions, afterSuccessActions) => async () =>
        dispatch(
          doGraphqlRequest(
            UPDATE_CONVERSATION_UNASSIGN,
            updateConversationUnassignMutation(),
            {
              conversationId,
              team: unassignTeam,
              agent: unassignAgent,
              skipNotifications: !!options.skipNotifications,
            },
            mergePlain(
              {
                app: true,
                throwOnError: true,
                concurrency: {
                  key: ticketId,
                  message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                },
                normalizationSchema: conversationUnassignGraphQlResponseSchema,
                transformResponse: onUpdateAttachEventGroupIdToEvents(
                  'conversationUnassign'
                ),
                optimist,
                moduleOptions: {
                  snackbar: {
                    enabled: true,
                    success: {
                      enabled: true,
                      message: `${app.t('Ticket')} unassigned`,
                      link: willAutoRedirect && {
                        href: backHref,
                        text: `Go back to ${app.t('Ticket')}`,
                      },
                    },
                    failed: {
                      enabled: true,
                      message: `Oops, we couldn't unassign your ${app.t(
                        'ticket'
                      )}.`,
                    },
                  },
                  entities: {
                    additionalActions,
                  },
                },
                meta: {
                  mergeEntities: true,
                },
                afterStartedActions,
                afterSuccessActions,
              },
              options
            )
          )
        ),
      mergePlain(
        {
          moduleOptions: {
            autoRedirect: {
              enabled: true,
              redirectFirst: true,
              operation: 'save',
            },
          },
        },
        options
      )
    )
  )
}
