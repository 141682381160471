/* eslint-disable no-param-reassign */
import createCachedSelector from 're-reselect'
import { selectReactionsByAgentIdForEventGroupId } from './selectReactionsByAgentIdForEventGroupId'

export const selectReactionsByReactionForEventGroupId = createCachedSelector(
  selectReactionsByAgentIdForEventGroupId,
  reactionsByAgentId => {
    return Object.keys(reactionsByAgentId).reduce((byReaction, agentId) => {
      const reactions = reactionsByAgentId[agentId]

      Object.keys(reactions).forEach(reaction => {
        if (!byReaction[reaction]) {
          byReaction[reaction] = []
        }

        byReaction[reaction].push(reactions[reaction])
      })

      return byReaction
    }, {})
  }
)((_state, groupId) => groupId || 'unknown')
