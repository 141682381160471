import { mergePlain } from 'util/merge'
import { OPENED } from '../constants'
import { doUpdateState } from './doUpdateState'

export const doRestore = (ticketId, options = {}) => dispatch => {
  return dispatch(
    doUpdateState(
      ticketId,
      OPENED,
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('Ticket')} restored`,
                // doUpdateState shows a go back to conversation. We're disabling
                // redirect so we also need to remove that link
                link: null,
              },
              failed: {
                enabled: true,
                message: `Oops, we couldn't restore your ${app.t('ticket')}.`,
              },
            },
            autoRedirect: { enabled: false },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
