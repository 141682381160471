import {
  selectCurrentEntitiesSortedBy,
  selectPendingEntityById,
  selectCurrentEntitiesById,
  selectCurrentEntityById,
} from 'ducks/entities/selectors'
import { emptyArray } from 'util/arrays'

export const selectCurrentTeamsById = state =>
  selectCurrentEntitiesById(state, 'team') || null

export const selectCurrentTeamById = (state, id) =>
  selectCurrentEntityById(state, 'team', id) || null

export const selectCurrentTeamsSortedByName = state =>
  selectCurrentEntitiesSortedBy(state, 'team', 'name', 'asc') || emptyArray

export const selectPendingTeamById = (state, id) =>
  selectPendingEntityById(state, 'team', id) || null
