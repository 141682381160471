import {
  LONGEST_UNANSWERED,
  NEWEST,
  NEWEST_BY_CLOSED,
  NEWEST_BY_COLLABORATOR,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
  OLDEST,
  OLDEST_BY_COLLABORATOR,
} from 'constants/defaults'
import createCachedSelector from 're-reselect'
import {
  byLongestUnanswered,
  byNewest,
  byNewestClosed,
  byNewestDeleted,
  byNewestSpam,
  byOldest,
  sortOrderContextKeyToSortOptionKeys,
} from 'util/search/sorting'
import { selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled } from 'selectors/app/selectAccountPreferences'
import { selectSortContextIdByQueryId } from './selectSortContextIdByQueryId'
import { selectQueryByQueryId } from '.'
import { selectDefaultSortOrderByQueryId } from './selectDefaultSortOrderByQueryId'

export const selectSortOptionsByQueryId = createCachedSelector(
  (state, queryId) => selectQueryByQueryId(state, queryId),
  (state, queryId) => selectSortContextIdByQueryId(state, queryId),
  selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled,
  selectDefaultSortOrderByQueryId,
  (
    query,
    contextId,
    sortByLastUnansweredUserMessageAtEnabled,
    defaultSortOrder
  ) => {
    const options = []
    const contextOptions = sortOrderContextKeyToSortOptionKeys(contextId)
    const sortOrder = query.orderBy || defaultSortOrder

    contextOptions.forEach(orderKey => {
      if (NEWEST_BY_CLOSED === orderKey) {
        options.push({
          id: orderKey,
          active: byNewestClosed(sortOrder),
          key: orderKey,
          text: 'Recently Closed',
          selectedOptionText: 'Recently Closed',
          value: orderKey,
        })
      }

      if (NEWEST_BY_SPAM === orderKey) {
        options.push({
          id: orderKey,
          active: byNewestSpam(sortOrder),
          key: orderKey,
          text: 'Recently Spammed',
          selectedOptionText: 'Recently Spammed',
          value: orderKey,
        })
      }

      if (NEWEST_BY_DELETED === orderKey) {
        options.push({
          id: orderKey,
          active: byNewestDeleted(sortOrder),
          key: orderKey,
          text: 'Recently Trashed',
          selectedOptionText: 'Recently Trashed',
          value: orderKey,
        })
      }

      if (
        LONGEST_UNANSWERED === orderKey &&
        sortByLastUnansweredUserMessageAtEnabled
      ) {
        options.push({
          id: orderKey,
          active: byLongestUnanswered(sortOrder),
          key: orderKey,
          text: 'Waiting Longest',
          selectedOptionText: 'Waiting Longest',
          value: orderKey,
        })
      }

      if ([NEWEST, NEWEST_BY_COLLABORATOR].includes(orderKey)) {
        options.push({
          id: orderKey,
          active: byNewest(sortOrder),
          key: orderKey,
          text: 'Newest',
          selectedOptionText: 'Newest first',
          value: orderKey,
        })
      }

      if ([OLDEST, OLDEST_BY_COLLABORATOR].includes(orderKey)) {
        options.push({
          id: orderKey,
          active: byOldest(sortOrder),
          key: orderKey,
          text: 'Oldest',
          selectedOptionText: 'Oldest first',
          value: orderKey,
        })
      }
    })

    return options
  }
)((_state, queryId) => queryId || 'unknown')
