import createCachedSelector from 're-reselect'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import {
  isClosed,
  isDeleted,
  isSpam,
  isStarred,
  isUnread,
  isOpen,
  isCloseable,
  isSnoozed,
} from 'ducks/tickets/utils/state'

export const selectTicketFlagsByConversationId = createCachedSelector(
  selectCurrentConversationById,
  ticket => {
    return {
      isOpen: isOpen(ticket),
      isClosed: isClosed(ticket),
      isSpam: isSpam(ticket),
      isUnread: isUnread(ticket),
      isStarred: isStarred(ticket),
      isDeleted: isDeleted(ticket),
      isCloseable: isCloseable(ticket?.state),
      isSnoozed: isSnoozed(ticket),
    }
  }
)((_state, ticketId) => ticketId || 'unknown')
