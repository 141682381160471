import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { DELETE_CONVERSATION } from '../actionTypes'
import { updateConversationStateDeleteMutation } from '../mutations'
import {
  CONVERSATION_SAVE_UNLOAD_MESSAGE,
  DELETE_MODE_HARD,
} from '../constants'
import { selectCurrentConversationById } from '../selectors'
import { withAutoRedirect } from '../utils/request'
import { shortTitleTitle } from '../utils/state'
import { buildConversationOptimistDeleteOptions } from '../utils/optimistic'

export const doDelete = (ticketId, options = {}) => (dispatch, getState) => {
  const conversationId = buildId('Conversation', ticketId)

  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)
  if (!ticket) return null

  const { additionalActions } = buildConversationOptimistDeleteOptions(
    getState,
    conversationId
  )

  return dispatch(
    withAutoRedirect(
      ticketId,
      (afterStartedActions, afterSuccessActions) => async () =>
        dispatch(
          doGraphqlRequest(
            DELETE_CONVERSATION,
            updateConversationStateDeleteMutation(),
            {
              conversationIds: [conversationId],
              deleteMode: DELETE_MODE_HARD,
            },
            mergePlain(
              {
                app: true,
                throwOnError: true,
                concurrency: {
                  key: ticketId,
                  message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                },
                optimist: {},
                moduleOptions: {
                  snackbar: {
                    enabled: true,
                    success: {
                      enabled: true,
                      message: `${app.t('Ticket')} ${shortTitleTitle(
                        ticket
                      )} deleted forever`,
                    },
                    failed: {
                      enabled: true,
                      message: `Oops, we couldn't delete your ${app.t(
                        'ticket'
                      )}.`,
                    },
                  },
                  entities: {
                    additionalActions,
                  },
                },
                meta: {
                  mergeEntities: true,
                },
                afterStartedActions,
                afterSuccessActions,
              },
              options
            )
          )
        ),
      mergePlain(
        {
          moduleOptions: {
            autoRedirect: {
              enabled: true,
              redirectFirst: true,
              forced: true,
              operation: 'delete',
            },
          },
        },
        options
      )
    )
  )
}
