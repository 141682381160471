import { mergePlain } from 'util/merge'
import { OPENED } from '../constants'
import { doUpdateState } from './doUpdateState'
import { selectCurrentConversationById } from '../selectors'
import { isUnread } from '../utils/state'

// In the new GQL there technically isnt a pseudo state for marking as read. You
// just open the conversation. However the behavior between opening a conversation and
// marking as read is different. With marking as unread we dont want any redirects or
// snack bar messages
export const doMarkAsRead = (ticketId, options = {}) => (
  dispatch,
  getState
) => {
  const ticket = selectCurrentConversationById(getState(), ticketId)
  if (!isUnread(ticket)) return null
  return dispatch(
    doUpdateState(
      ticketId,
      OPENED,
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: false,
            },
            autoRedirect: {
              enabled: false,
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
