import { createActionTypeReducer } from 'util/reducers'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'
import {
  SEARCH_PAGE,
  TICKET_COMMENT_PAGE,
  TICKET_PAGE,
  TICKETS_PAGE,
  NEW_CONVERSATION_PAGE,
  LOG_CONVERSATION_PAGE,
  MAIN_PAGE,
} from 'constants/pages'

const previousQueryIdFromQueryReducer = (draftState, action) => {
  if (!action?.meta?.previousQueryId) {
    return draftState
  }
  return action?.meta?.previousQueryId
}

export const previousQueryId = createActionTypeReducer(
  {
    [CHAT_PAGE]: previousQueryIdFromQueryReducer,
    [SOCIAL_PAGE]: previousQueryIdFromQueryReducer,
    [SEARCH_PAGE]: previousQueryIdFromQueryReducer,
    [TICKETS_PAGE]: previousQueryIdFromQueryReducer,
    [TICKET_PAGE]: previousQueryIdFromQueryReducer,
    [TICKET_COMMENT_PAGE]: previousQueryIdFromQueryReducer,
    [LOG_CONVERSATION_PAGE]: previousQueryIdFromQueryReducer,
    [NEW_CONVERSATION_PAGE]: previousQueryIdFromQueryReducer,
    [MAIN_PAGE]: previousQueryIdFromQueryReducer,
  },
  null
)
