import createCachedSelector from 're-reselect'
import { selectConversationEventGroupsByConversationId } from './selectConversationEventGroupsByConversationId'

export const selectConversationEventGroupByChangesetId = createCachedSelector(
  selectConversationEventGroupsByConversationId,
  (_state, _conversationId, changesetId) => changesetId,
  (conversationEventGroups, changesetId) => {
    return conversationEventGroups.find(ceg => ceg.changesetId === changesetId)
  }
)(
  (_state, conversationId, changesetId) =>
    `${conversationId || 'unknown'}-${changesetId || 'unknown'}`
)
