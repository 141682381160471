import { createSelector } from 'reselect'
import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { assignmentLabel } from 'util/assignment'
import { selectCurrentTicketAssignedAgent } from './selectCurrentTicketAssignedAgent'
import { selectCurrentTicketAssignedGroup } from './selectCurrentTicketAssignedGroup'

export const selectCurrentTicketAssignmentLabel = createSelector(
  selectCurrentTicketAssignedAgent,
  selectCurrentTicketAssignedGroup,
  selectCurrentUserId,
  assignmentLabel
)
