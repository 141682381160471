import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { selectLastUpdatedAt } from '../selectors'
import { SEARCH_SYNC } from '../actionTypes'
import { convertRealtimeSearchesToSearches } from '../utils/query'

export function doSyncTicketSearches(ticketId, ticketSearches) {
  return async (dispatch, getState) => {
    if (!ticketSearches) return null

    const state = getState()
    // Tickets dont require the last updated to be loaded first, so we can default to the current time.
    const currentLastUpdatedAt = selectLastUpdatedAt(state)
    const currentUser = selectCurrentUser(state)
    const currentUserSearches = ticketSearches[currentUser.id]?.searches
    if (!currentUserSearches) return null

    return dispatch({
      type: SEARCH_SYNC,
      payload: {
        currentLastUpdatedAt,
        lastUpdatedAt: currentLastUpdatedAt + 1,
        searches: convertRealtimeSearchesToSearches(
          ticketId,
          currentUserSearches
        ),
        currentUser,
        unread: {},
        isInChat: false,
        currentConversationId: ticketId,
        pageChannelType: MAILBOX_CHANNEL_TYPE,
      },
    })
  }
}
