/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import debug from 'util/debug'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { camelize } from 'util/strings'
import { buildId } from 'util/globalId'
import { FETCH_CONVERSATION_EVENTS_BY_GROUP_ID } from '../actionTypes'
import { conversationEventsGraphQlResponseSchema } from '../schema'

import { buildConversationEventsByGroupIdRequestKey } from '../utils/request'
import { ticketEventsQuery } from '../queries'

const transformAttachEventGroupIdToEvents = eventGroupId => data => {
  if (data.events) {
    data.events.edges.forEach(e => {
      e.node.eventGroupId = eventGroupId
    })
  }
  return data
}

/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
export function doFetchTicketEventsByGroupId({
  conversationId: inputConversationId,
  eventGroupId,
  channelType,
  options = {},
}) {
  return async dispatch => {
    if (!inputConversationId || inputConversationId === 'new') return null
    const conversationId = buildId('Conversation', inputConversationId)
    if (channelType !== MAILBOX_CHANNEL_TYPE) {
      debug(
        `doFetchTicketEventsByGroupId currently only supports loading the ${MAILBOX_CHANNEL_TYPE} channel type`
      )
    }
    const filter = {
      conversationId,
      eventGroupId,
    }

    return dispatch(
      doGraphqlRequest(
        FETCH_CONVERSATION_EVENTS_BY_GROUP_ID,
        ticketEventsQuery(),
        {
          filter,
        },
        {
          ...options,
          app: true,
          normalizationSchema: conversationEventsGraphQlResponseSchema,
          meta: {
            channelType,
            requestKey: camelize(
              buildConversationEventsByGroupIdRequestKey(eventGroupId)
            ),
            mergeEntities: true,
          },
          throwOnError: true,
          transformResponse: transformAttachEventGroupIdToEvents(eventGroupId),
          // Ensure that after loading the conversationEventGroup, the corrosponding group
          // has its collaposed flag set to false indicating that its loaded
          moduleOptions: {
            entities: {
              additionalActions: [
                (_currentPhase, _transformedData, normalizedEntities) => ({
                  phases: ['SUCCESS'],
                  entityId: eventGroupId,
                  entityType: 'conversationEventGroup',
                  operation: 'update',
                  type: 'change',
                  stores: ['current'],
                  entity: {
                    collapsed: false,
                    events: Object.keys(
                      normalizedEntities.conversationEvent || {}
                    ),
                  },
                }),
              ],
            },
          },
        }
      )
    )
  }
}
