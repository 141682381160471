import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { doShowSnackbar } from 'actions/snackbar'

export const useCopyChangesetLink = changesetId => {
  const dispatch = useDispatch()

  const onCopyLinkClick = useCallback(
    e => {
      const { origin, pathname, search } = window.location
      const messageURL = `${origin}${pathname}${search}#changeset-${changesetId}`

      if (!navigator.clipboard) {
        dispatch(doShowSnackbar('Could not copy a link to the message.'))
        return
      }

      navigator.clipboard
        .writeText(messageURL)
        .then(() =>
          dispatch(
            doShowSnackbar('Link to the message was copied to your clipboard')
          )
        )

      e.stopPropagation()
    },
    [dispatch, changesetId]
  )

  return onCopyLinkClick
}
