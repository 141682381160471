import { actionToPath, selectLocationState } from 'redux-first-router'
import { createBasicSelector } from 'util/redux'
import querySerializer from 'query-string'
import { TICKET_PAGE } from 'constants/pages'

export const selectBasicLinkUrlForTicketId = createBasicSelector(
  selectLocationState,
  (_state, ticketId) => ticketId,
  (locationState, ticketId) => {
    const { routesMap } = locationState
    const path = actionToPath(
      {
        type: TICKET_PAGE,
        payload: {
          id: ticketId,
        },
      },
      routesMap,
      querySerializer
    )
    return `${window.location.origin}${path}`
  }
)
