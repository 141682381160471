/* eslint-disable no-param-reassign */
import { createBasicSelector } from 'util/redux'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { selectReactionsByReactionForEventGroupId } from './selectReactionsByReactionForEventGroupId'

export const selectIsOwnReactionForEventGroupAndReaction = createBasicSelector(
  selectReactionsByReactionForEventGroupId,
  (_state, _groupId, reaction) => reaction,
  selectCurrentUserGlobalId,
  (reactionsByReaction, reaction, currentUserId) => {
    const reactions = reactionsByReaction[reaction]
    return reactions.some(e => e.actor.id === currentUserId)
  }
)
