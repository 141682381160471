import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { CONVERSATION_LINK_RESOURCE } from '../actionTypes'
import { updateConversationLinkResourcedMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { conversationLinkResourceGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import { onUpdateAttachEventGroupIdToEvents } from '../utils/request'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'

export const doLinkResource = (
  ticketId,
  externalId,
  provider,
  removed,
  title,
  url,
  options = {}
) => async (dispatch, getState) => {
  const conversationId = buildId('Conversation', ticketId)
  const store = getState()
  const ticket = selectCurrentConversationById(store, ticketId)
  if (!ticket || !externalId || !provider || !title || !url) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      integration: {
        externalId,
        provider,
        removed,
        title,
        url,
      },
    },
    options
  )

  return dispatch(
    doGraphqlRequest(
      CONVERSATION_LINK_RESOURCE,
      updateConversationLinkResourcedMutation(),
      {
        conversationId,
        externalId,
        provider,
        removed,
        title,
        url,
      },
      mergePlain(
        {
          app: true,
          throwOnError: true,
          concurrency: {
            key: ticketId,
            message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
          },
          normalizationSchema: conversationLinkResourceGraphQlResponseSchema,
          transformResponse: onUpdateAttachEventGroupIdToEvents(
            'conversationLinkResource'
          ),
          optimist,
          moduleOptions: {
            snackbar: {
              enabled: false,
            },
            entities: {
              additionalActions,
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
