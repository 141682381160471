import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { UPDATE_CONVERSATION_STATE_SNOOZE } from '../actionTypes'
import { updateConversationStateSnoozeMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE, SNOOZED } from '../constants'
import { conversationStateSnoozeGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import {
  withAutoRedirect,
  onUpdateAttachEventGroupIdToEvents,
} from '../utils/request'
import { doSaveLastSnoozedDate } from './doSaveLastSnoozedDate'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { parseToApiSnoozeUntil } from '../utils/payload'

export const doSnooze = (ticketId, inputSnoozeUntil, options = {}) => async (
  dispatch,
  getState
) => {
  const snoozeUntil = parseToApiSnoozeUntil(inputSnoozeUntil)
  const conversationId = buildId('Conversation', ticketId)

  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)
  if (!ticket) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      state: SNOOZED,
      snoozeUntil,
    },
    options
  )

  // TODO Change the reducer behind this action to rather
  // use the UPDATE_CONVERSATION_STATE_SNOOZE_STARTED event
  if (!options.disableSaveLastSnoozed) {
    dispatch(doSaveLastSnoozedDate(inputSnoozeUntil))
  }

  return dispatch(
    withAutoRedirect(
      ticketId,
      (afterStartedActions, afterSuccessActions) => async () =>
        dispatch(
          doGraphqlRequest(
            UPDATE_CONVERSATION_STATE_SNOOZE,
            updateConversationStateSnoozeMutation(),
            {
              conversationId,
              until: snoozeUntil,
            },
            mergePlain(
              {
                app: true,
                throwOnError: true,
                concurrency: {
                  key: ticketId,
                  message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                },
                normalizationSchema: conversationStateSnoozeGraphQlResponseSchema,
                transformResponse: onUpdateAttachEventGroupIdToEvents(
                  'conversationSnooze'
                ),
                optimist,
                moduleOptions: {
                  snackbar: {
                    enabled: true,
                    success: {
                      enabled: true,
                      message: `${app.t('Ticket')} snoozed`,
                    },
                    failed: {
                      enabled: true,
                      message: `Oops, we couldn't snooze your ${app.t(
                        'ticket'
                      )}.`,
                    },
                  },
                  entities: {
                    additionalActions,
                  },
                },
                meta: {
                  mergeEntities: true,
                },
                afterStartedActions,
                afterSuccessActions,
              },
              options
            )
          )
        ),
      mergePlain(
        {
          moduleOptions: {
            autoRedirect: {
              enabled: true,
              redirectFirst: true,
              operation: 'save',
            },
          },
        },
        options
      )
    )
  )
}
