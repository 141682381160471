import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { UPDATE_CONVERSATION_STAR } from '../actionTypes'
import { updateStarConversationdMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { conversationStarGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { onUpdateAttachEventGroupIdToEvents } from '../utils/request'

export const doStar = (ticketId, options = {}) => async (
  dispatch,
  getState
) => {
  const conversationId = buildId('Conversation', ticketId)

  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)
  if (!ticket || ticket.starred) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      starred: true,
    },
    options
  )

  return dispatch(
    doGraphqlRequest(
      UPDATE_CONVERSATION_STAR,
      updateStarConversationdMutation(),
      {
        conversationId,
      },
      mergePlain(
        {
          app: true,
          throwOnError: true,
          concurrency: {
            key: ticketId,
            message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
          },
          normalizationSchema: conversationStarGraphQlResponseSchema,
          transformResponse: onUpdateAttachEventGroupIdToEvents(
            'conversationStar'
          ),
          optimist,
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: false,
              },
              failed: {
                enabled: true,
                message: "Oops, we couldn't star your conversation.",
              },
            },
            entities: {
              additionalActions,
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
