import createCachedSelector from 're-reselect'
import { selectTicketSearchOperatorValueMap } from 'selectors/search'
import { selectIsOnSearchPage } from 'selectors/location'
import { toFilterQueryId } from '../utils/query'
import { constructSearchQueryTitle } from '../utils/title'

export const selectSearchQueryTitleByQueryId = createCachedSelector(
  (_state, queryId) => toFilterQueryId(queryId),
  selectIsOnSearchPage,
  state => selectTicketSearchOperatorValueMap(state),
  (queryId, isSearchPage, valueIdMap) => {
    if (!isSearchPage || !queryId) return null
    return constructSearchQueryTitle(queryId, valueIdMap)
  }
)((_state, queryId) => queryId || 'unknown')
