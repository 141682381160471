import { mergePlain } from 'util/merge'
import { doBulkUpdate } from './doBulkUpdate'
import { doUpdateState } from './doUpdateState'

export const doBulkUpdateState = (
  ticketIds,
  newState,
  options = {}
) => dispatch => {
  return dispatch(
    doBulkUpdate(
      ticketIds,
      doUpdateState,
      [newState],
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('N_Tickets', {
                  count: ticketIds.length,
                })} ${newState.toLowerCase()}`,
              },
              failed: {
                enabled: false,
              },
            },
          },
        },
        options
      )
    )
  )
}
