import { createBasicSelector } from 'util/redux'
import { selectConversationEventGroupsByConversationId } from './selectConversationEventGroupsByConversationId'
import { selectCurrentTicketId } from './selectCurrentTicketId'

export const selectCurrentTicketEvents = createBasicSelector(
  state => state,
  selectCurrentTicketId,
  (state, conversationId) =>
    selectConversationEventGroupsByConversationId(state, conversationId)
)
