import emailParser from 'email-addresses'
import { compact } from 'util/arrays'
import { SNOOZED_INDEFINITELY } from 'util/snooze'
import Bugsnag from '@bugsnag/js'
import { GROOVE_NOTE_EMPTY_EMAIL } from '../constants'

// The backend seems to throw nil bombs when empty arrays are passed along which seems wrong.
// This will work around the issue for now, but we should really fix that up.
export const arrayOrNull = array => {
  if (!array) return null
  if (!Array.isArray(array)) return null
  if (array.length === 0) return null

  return array
}

export const emailToEmailRecipientInput = (email, options = {}) => {
  const item = emailParser.parseOneAddress(email)
  if (!item) return null

  // The name has to fallback to an empty string because EmailRecipientInput
  // doesnt support empty name fields.
  const name = item.name || (options.allowEmptyName ? '' : null)

  // Note create allows you to only have a name. In the doLog method we use the preprocessNoteEmail
  // to replace the missing email address with the  GROOVE_NOTE_EMPTY_EMAIL constant. Then over here
  // we can detect this constant and replace it with an empty string. It cant be null because
  // Types::Messages::EmailRecipientInput requires the email field to have a value event if its empty.
  const adress = item.address === GROOVE_NOTE_EMPTY_EMAIL ? '' : item.address
  return { email: adress, name }
}

export const emailArrayToEmailRecipientInputArray = (
  arrayOfEmails,
  options = {}
) => {
  if (!arrayOfEmails) return null
  if (!Array.isArray(arrayOfEmails)) return null

  return compact(
    arrayOfEmails.map(email => emailToEmailRecipientInput(email, options))
  )
}

export const attachmentsToEmailAttachmentInput = attachments => {
  return (attachments || [])
    .map(a => {
      if (a.fileSize === undefined) {
        Bugsnag.notify(
          new Error(
            'Undefined fileSize deteched inside attachmentsToEmailAttachmentInput'
          ),
          event => {
            // eslint-disable-next-line no-param-reassign
            event.severity = 'error'
            event.addMetadata('metaData', {
              meta: {
                attachments: JSON.stringify(attachments),
              },
            })
          }
        )
      }
      return {
        key: a.key,
        fileName: a.fileName,
        fileSize: a.fileSize?.toString() || '0',
        contentType: a.contentType,
      }
    })
    .filter(a => !!a.key)
}

export const parseToApiSnoozeUntil = snoozeUntil => {
  return snoozeUntil === SNOOZED_INDEFINITELY ? null : snoozeUntil
}
