import { connect } from 'react-redux'
import { doHideTicketPreviewModal } from 'actions/modals'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { makeIsTicketPreviewOpen, selectModalOffset } from 'selectors/modals'
import { doToggleStar } from 'ducks/tickets/actions/doToggleStar'
import { selectTicketFlagsByConversationId } from 'ducks/tickets/selectors/selectTicketFlagsByConversationId'
import { selectTicketEventGroupsRequestByConversationId } from 'ducks/tickets/selectors/selectTicketEventGroupsRequestByConversationId'
import { selectMessagesByConversationId } from 'ducks/tickets/selectors/selectMessagesByConversationId'
import { doFetchTicketEventGroups } from 'ducks/tickets/actions/doFetchTicketEventGroups'
import TicketPreviewView from './view'

const select = () => {
  const selectIsModalOpen = makeIsTicketPreviewOpen()
  return (state, props) => {
    return {
      comments: selectMessagesByConversationId(state, props.ticketId),
      flags: selectTicketFlagsByConversationId(state, props.ticketId),
      isModalOpen: selectIsModalOpen(state, props),
      ticket: selectCurrentConversationById(state, props.ticketId),
      modalOffset: selectModalOffset(state),
      isLoading: selectTicketEventGroupsRequestByConversationId(
        state,
        props.ticketId
      )?.loading,
    }
  }
}

const perform = {
  hideTicketPreviewModal: doHideTicketPreviewModal,
  fetchTicketComments: doFetchTicketEventGroups,
  toggleStarred: doToggleStar,
}

export default connect(select, perform)(TicketPreviewView)
