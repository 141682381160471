import {
  DEFAULT_SORT_ORDER,
  DEFAULT_SORT_ORDER_BY_COLLABORATOR,
  LONGEST_UNANSWERED,
  NEWEST,
  NEWEST_BY_CLOSED,
  NEWEST_BY_COLLABORATOR,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
  OLDEST,
  OLDEST_BY_COLLABORATOR,
} from 'constants/defaults'
import { SORT_ORDERS_FOR_FOLDER } from 'util/folders/SORT_ORDERS_FOR_FOLDER'
import { QUERY_PRIMARY_ALIAS, queryIdToQuery } from './query'

const isState = (query, state) => {
  return query?.is?.includes(state) || query?.state === state
}

const defaultFolderSortOrderKey = folder => {
  let sortOrder
  SORT_ORDERS_FOR_FOLDER.forEach((func, key) => {
    if (func(folder)) {
      sortOrder = key
      // eslint-disable-next-line no-useless-return
      return
    }
  })

  return sortOrder
}

export const buildSortContextParts = (
  queryId,
  sortByCollaboratorCommentAtEnabled,
  foldersById
) => {
  const query = queryIdToQuery(queryId) || {}

  const contextIdParts = []
  const hasDeletedQuery = isState(query, 'deleted') || isState(query, 'trash')
  const hasClosedQuery = isState(query, 'closed')
  const hasSpamQuery = isState(query, 'spam')

  if (hasDeletedQuery) {
    contextIdParts.push(NEWEST_BY_DELETED)
  }

  if (hasClosedQuery) {
    contextIdParts.push(NEWEST_BY_CLOSED)
  }

  if (hasSpamQuery) {
    contextIdParts.push(NEWEST_BY_SPAM)
  }

  if (query[QUERY_PRIMARY_ALIAS.folderId]) {
    const folder = foldersById[query[QUERY_PRIMARY_ALIAS.folderId]]
    const folderDefault = defaultFolderSortOrderKey(folder)
    if (folderDefault) contextIdParts.push(folderDefault)
  }

  if (sortByCollaboratorCommentAtEnabled) {
    contextIdParts.push(NEWEST_BY_COLLABORATOR)
    contextIdParts.push(OLDEST_BY_COLLABORATOR)
  } else {
    contextIdParts.push(NEWEST)
    contextIdParts.push(OLDEST)
  }
  return contextIdParts
}

const sortFuncs = {
  [NEWEST]: (a, b) =>
    Date.parse(b.systemUpdatedAt) - Date.parse(a.systemUpdatedAt),
  [OLDEST]: (a, b) =>
    Date.parse(a.systemUpdatedAt) - Date.parse(b.systemUpdatedAt),
  [NEWEST_BY_COLLABORATOR]: (a, b) =>
    Date.parse(b.updatedAt) - Date.parse(a.updatedAt),
  [OLDEST_BY_COLLABORATOR]: (a, b) =>
    Date.parse(a.updatedAt) - Date.parse(b.updatedAt),
  [LONGEST_UNANSWERED]: (a, b) =>
    Date.parse(a.lastUnansweredUserMessageAt) -
    Date.parse(b.lastUnansweredUserMessageAt),
  [NEWEST_BY_DELETED]: (a, b) =>
    Date.parse(b.deletedAt) - Date.parse(a.deletedAt),
  [NEWEST_BY_CLOSED]: (a, b) =>
    Date.parse(b.stateUpdatedAt) - Date.parse(a.stateUpdatedAt),
  [NEWEST_BY_SPAM]: (a, b) =>
    Date.parse(b.stateUpdatedAt) - Date.parse(a.stateUpdatedAt),
}

const defaultSortFunc = (sortOptions = {}) => {
  const { sortByCollaboratorCommentAtEnabled } = sortOptions || {}

  const sortOrder = sortByCollaboratorCommentAtEnabled
    ? DEFAULT_SORT_ORDER_BY_COLLABORATOR
    : DEFAULT_SORT_ORDER

  return sortFuncs[sortOrder]
}

const sortToCollaboratorUpdatedAtMapping = new Map([
  [NEWEST, NEWEST_BY_COLLABORATOR],
  [OLDEST, OLDEST_BY_COLLABORATOR],
])

export const sortSearchIds = (search, entityStore) => {
  const ids = search?.entityIds || []
  const queryId = search?.queryId
  if (!ids.length || !entityStore) return ids

  const byId = entityStore.conversation?.byId || {}
  const preferences =
    Object.values(entityStore.account.byId || {})[0]?.preferences || []
  const sortByCollaboratorCommentAtEnabled =
    preferences.find(
      p => p.key === 'sort_conversation_by_collaborator_comment_enabled'
    )?.value || false

  const query = queryIdToQuery(queryId)
  if (!query) return ids
  const sortOrder = query.orderBy

  let sortFunc
  if (
    sortByCollaboratorCommentAtEnabled &&
    sortToCollaboratorUpdatedAtMapping.has(sortOrder)
  ) {
    sortFunc = sortFuncs[sortToCollaboratorUpdatedAtMapping.get(sortOrder)]
  } else {
    sortFunc =
      sortFuncs[sortOrder] ||
      defaultSortFunc({ sortByCollaboratorCommentAtEnabled })
  }

  const isIdQuery = !isNaN(queryId)
  const orderedResults = ids.sort((idA, idB) => {
    const a = byId[idA]
    const b = byId[idB]
    if (!a || !b) return 0
    if (isIdQuery) {
      if (a.id == queryId) return -1 // eslint-disable-line eqeqeq
      if (b.id == queryId) return 1 // eslint-disable-line eqeqeq
    }
    if (a.updatedAt === b.updatedAt) {
      if (a.id < b.id) return 1
      if (a.id > b.id) return -1
    }
    return sortFunc(a, b)
  })
  return orderedResults
}
