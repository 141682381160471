import { mergePlain } from 'util/merge'
import { SPAM } from '../constants'
import { doUpdateState } from './doUpdateState'

export const doSpam = (ticketId, options = {}) => dispatch => {
  return dispatch(
    doUpdateState(
      ticketId,
      SPAM,
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('Ticket')} marked as spam`,
              },
              failed: {
                enabled: true,
                message: `Oops, we couldn't mark ${app.t('ticket')} as spam.`,
              },
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
