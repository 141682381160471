import { mergePlain } from 'util/merge'
import { doBulkUpdate } from './doBulkUpdate'
import { doSaveLastSnoozedDate } from './doSaveLastSnoozedDate'
import { doSnooze } from './doSnooze'

export const doBulkSnooze = (
  ticketIds,
  snoozeUntil,
  options = {}
) => dispatch => {
  dispatch(doSaveLastSnoozedDate(snoozeUntil))
  return dispatch(
    doBulkUpdate(
      ticketIds,
      doSnooze,
      [snoozeUntil],
      mergePlain(
        {
          disableSaveLastSnoozed: true,
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('N_Tickets', {
                  count: ticketIds.length,
                })} snoozed`,
              },
              failed: {
                enabled: false,
              },
            },
          },
        },
        options
      )
    )
  )
}
