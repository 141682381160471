import { SEARCH_PAGE, TICKETS_PAGE } from 'constants/pages'
import { selectCurrentFolderItem } from 'ducks/folders/selectors/items'
import { createSelector } from 'reselect'
import { selectCurrentAction } from 'selectors/location'
import { emptyObj } from 'util/objects'

export const selectFolderRouteAction = createSelector(
  selectCurrentAction,
  selectCurrentFolderItem,
  (action, folderItem) => ({
    ...action,
    type: folderItem === emptyObj ? SEARCH_PAGE : TICKETS_PAGE,
    payload: {},
  })
)
