export const FETCH_CONVERSATION_COUNTS_FOR_CHANNEL =
  'searches/FETCH_CONVERSATION_COUNTS_FOR_CHANNEL'
export const FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_STARTED =
  'searches/FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_STARTED'
export const FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_SUCCESS =
  'searches/FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_SUCCESS'
export const FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_FAILED =
  'searches/FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_FAILED'

export const SEARCH_CONVERSATIONS = 'searches/SEARCH_CONVERSATIONS'
export const SEARCH_CONVERSATIONS_STARTED =
  'searches/SEARCH_CONVERSATIONS_STARTED'
export const SEARCH_CONVERSATIONS_SUCCESS =
  'searches/SEARCH_CONVERSATIONS_SUCCESS'
export const SEARCH_CONVERSATIONS_FAILED =
  'searches/SEARCH_CONVERSATIONS_FAILED'

export const REFRESH_SEARCHES = 'searches/REFRESH_SEARCHES'
export const REFRESH_SEARCHES_STARTED = 'searches/REFRESH_SEARCHES_STARTED'
export const REFRESH_SEARCHES_SUCCESS = 'searches/REFRESH_SEARCHES_SUCCESS'
export const REFRESH_SEARCHES_FAILED = 'searches/REFRESH_SEARCHES_FAILED'

export const SEARCH_SYNC = 'searches/SYNC'
