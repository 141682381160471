import { doFetchConversations } from 'ducks/searches/operations/doFetchConversations'
import { selectChannelTypeFromUrl } from 'ducks/channels/selectors'
import {
  selectSearchNextPageCursorByQueryId,
  selectSearchByQueryId,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { queryIdToQuery } from 'ducks/searches/utils/query'
import { doOpenFirstTicketForLoad } from 'actions/search/doOpenFirstTicketForLoad'

export const doFetchTickets = (opts = {}) => async (dispatch, getState) => {
  const { isReload, loadFirst, queryId: inputQueryId } = opts
  const state = getState()
  const queryId = inputQueryId || selectCurrentQueryId(state)
  const pageChannelType = selectChannelTypeFromUrl(state)
  const {
    loaded: searchesLoaded,
    errored: searchesError,
  } = selectSearchByQueryId(state, queryId)

  // There is a dodgy interaction between the empty folder state defined on src/components/App/DesktopView/Layout/Inbox.jsx
  // and the doFetchTickets defined inside src/subapps/ticketing/components/TicketList/index.jsx. The problem is that
  // we dont show the folder empty state while we're loading and the TicketList triggers a load when the current queryid doesnt
  // match the previous query id. This causes a infinite loop where the TicketList kicks of a fresh load of tickets as the UI
  // navigates to the empty folder screen.
  if ((searchesLoaded || searchesError) && loadFirst) {
    if (opts.allowOpenFirst) {
      dispatch(doOpenFirstTicketForLoad())
    }
    return Promise.resolve()
  }
  const nextPageCursor = loadFirst
    ? null
    : selectSearchNextPageCursorByQueryId(state, queryId)
  const { type: channelType = pageChannelType } = queryIdToQuery(queryId) || {}

  const fetchResponse = await dispatch(
    doFetchConversations({
      channelType,
      queryId,
      cursor: nextPageCursor,
      size: 20,
      options: {
        skipStarted: !!isReload,
      },
    })
  )

  if (opts.allowOpenFirst) {
    dispatch(doOpenFirstTicketForLoad())
  }
  return fetchResponse
}
