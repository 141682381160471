import { selectCurrentConversationById } from '../selectors'
import { doUnstar } from './doUnstar'
import { doStar } from './doStar'

export const doToggleStar = (ticketId, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)
  if (!ticket) return null

  if (ticket.starred) {
    return dispatch(doUnstar(ticketId, options))
  }
  return dispatch(doStar(ticketId, options))
}
