import { all, any, uniq, emptyArr, compact } from 'util/arrays'
import { toDate, diff as dateDiff } from 'util/date'
import { toInt } from 'util/ordinal'
import { eq, getOperatorFn, MATCH_TYPE_ALL } from 'util/operators'
import { isDeleted, isRead } from 'ducks/tickets/utils/state'
import {
  FOLDER_CONDITION_TYPES,
  MAILBOX_CHANNEL_TYPE,
} from 'ducks/folders/constants'
import { buildId, buildIdFromAny, getRawId } from 'util/globalId'
import { emptyObj } from 'util/objects'
import { SNOOZED_INDEFINITELY } from 'util/snooze'
import {
  DATE,
  DROPDOWN,
  MULTI_SELECT,
  NUMBER,
} from 'ducks/crm/customFields/types'
import {
  customFieldKeyToSearchKey,
  getQueryConfigByKey,
  queryIdToQuery,
  removeKeysFromQueryId,
} from './query'

const CURRENT_USER_ID = '-1'

const equalsTrue = m => eq(m, true)

// Loose equality match
const isTrue = str => str == 'true' // eslint-disable-line eqeqeq

const priorityMap = {
  low: 1000,
  medium: 2000,
  high: 3000,
  urgent: 4000,
}

const statusMap = {
  unread: 1000, // Ticket
  open: 2000, // Room
  opened: 2000, // Ticket (Change to open)
  follow_up: 3000, // Ticket (Pending removal)
  pending: 4000, // Ticket (Change to snoozed)
  snoozed: 4000, // Room
  closed: 5000, // Ticket/Room
  spam: 6000, // Ticket/Room
  trash: 7000, // Room (Need to add for tickets)
}

const hoursSinceOperands = (then, value, gateCondition = true) => ({
  prop: dateDiff('hours', toDate(then), new Date()),
  value: toInt(value),
  gateCondition,
})

const hoursSinceStateOperands = (conversation, value, state = null) =>
  hoursSinceOperands(
    conversation.stateChangedAt,
    value,
    state ? conversation.state === state : true
  )

const secondsUntilOperands = (until, value, gateCondition = true) => ({
  prop: until ? dateDiff('seconds', new Date(), toDate(until)) : undefined,
  value: toInt(value),
  gateCondition,
})

const computeAssignmentType = (agentId, teamId) => {
  if (!!agentId && !!teamId) return 'both'
  if (agentId) return 'agent'
  if (teamId) return 'team'
  return null
}

export const normalizeConversation = (conversation, entitiyStore) => {
  if (!conversation) return null

  const tagsById = entitiyStore.tag?.byId || emptyObj
  const mentionsById = entitiyStore.mention?.byId || emptyObj
  const contactsById = entitiyStore.contacts?.byId || emptyObj
  // 1 to 1 port of the logic in index_room_service. Make sure you keep that version
  // insync if you change this version
  const {
    id,
    channelId: inputChannelId,
    channel,
    state: inputState,
    assignedType: inputAssignmentType,
    assignedAgentId: inputAssignedAgentId,
    assignedTeamId: inputAssignedTeamId,
    assigned,
    isStarred: inputIsStarred,
    starred: inputStarred,
    tagIds: inputTagIds,
    tags: inputTags,
    interactionCount,
    isTrash,
    channelType,
    snoozed,
    snoozedUntil: inputSnoozedUntil,
    updatedAt,
    assignedAt,
    lastUnansweredUserMessageAt,
    stateChangedByAgentId,
    stateChangedAt,
    stateUpdatedAt,
    counts: { interactions } = {},
    draftAgentIds: inputDraftAgentIds,
    drafts = [],
    mentionAgentIds: inputMentionAgentIds,
    mentions,
    // Tickets dont have a is rated folder, so these are purely for the chat system. We'll circle back once we're ready
    // to implement these
    isRated,
    lastRating,
    customFieldValues: inputCustomFieldValues,
    contact: contactId,
  } = conversation
  const { agent: assignmentAgentId, team: assignmentTeamId, at: assignmentAt } =
    assigned || {}
  const snoozedById = snoozed?.by?.id
  const input2SnoozedUntil = snoozed?.until

  // This section reconsiles the field names between chat and tickets
  // START RECONSILE
  const state = inputState.toLowerCase()
  const channelId = buildIdFromAny('Channel', inputChannelId || channel)
  const assignedType =
    inputAssignmentType ||
    computeAssignmentType(assignmentAgentId, assignmentTeamId)
  const assignedAgentId = inputAssignedAgentId || assignmentAgentId || null
  const assignedTeamId = inputAssignedTeamId || assignmentTeamId || null
  const isStarred = inputIsStarred === undefined ? inputStarred : inputIsStarred
  const tagIds = inputTagIds || inputTags || emptyArr
  const draftAgentIds =
    inputDraftAgentIds === undefined
      ? compact(drafts.map(d => d.agent?.id || null))
      : inputDraftAgentIds

  const snoozeValue = inputSnoozedUntil || input2SnoozedUntil
  let snoozedUntil = !snoozeValue ? SNOOZED_INDEFINITELY : snoozeValue
  snoozedUntil = state === 'snoozed' ? snoozedUntil : null

  const conversationMentionAgentIds =
    mentions?.map(mid => mentionsById[mid]?.agent)?.filter(aid => !!aid) ||
    emptyArr
  const mentionAgentIds = inputMentionAgentIds || conversationMentionAgentIds
  const customFieldValues = inputCustomFieldValues || {}
  const contact = contactsById[contactId] || {}
  // END RECONSILE

  return {
    id,
    channelId,
    assignedType,
    state: state.toLowerCase(),
    draftAgentIds,
    mentionAgentIds,
    isRated,
    lastRating,
    assignedAgentId,
    assignedTeamId,
    isStarred,
    tagIds,
    interactionCount:
      interactionCount === undefined ? interactions : interactionCount,
    isTrash: isTrash === undefined ? isDeleted(conversation) : isTrash,
    channelType: channelType === undefined ? MAILBOX_CHANNEL_TYPE : channelType,
    snoozedUntil,
    updatedAt,
    assignedAt: assignedAt || assignmentAt || null,
    lastUnansweredUserMessageAt,
    labels: tagIds.map(tagId => tagsById[tagId]?.name),
    stateChangedByAgentId: stateChangedByAgentId || snoozedById,
    stateChangedAt: stateChangedAt || stateUpdatedAt,
    isRead: isRead(conversation),
    customFieldValues,
    contact,
  }
}

const getOperands = (condition, conversation, entityStore) => {
  const { param, value, source } = condition
  const customFieldKey = source?.key
  const {
    state,
    isStarred,
    isTrash,
    channelType,
    snoozedUntil,
    stateChangedByAgentId,
    assignedAgentId,
    assignedTeamId,
    interactionCount,
    updatedAt,
    assignedAt,
    labels,
    lastUnansweredUserMessageAt,
    mentionAgentIds,
    customFieldValues,
  } = conversation
  switch (param) {
    case FOLDER_CONDITION_TYPES.STARRED:
      return { prop: isStarred, value: isTrue(value) }
    case FOLDER_CONDITION_TYPES.PRIORITY:
      return {
        prop: isStarred ? priorityMap.urgent : priorityMap.low,
        value: priorityMap[value.toLowerCase()],
      }
    case FOLDER_CONDITION_TYPES.STATUS: {
      // Kevin R 2025-02-25
      // We've moved over the GQL but the problem is we havent extend the rules UI with a
      // "Snoozed" state yet and in the old system snoozed and closed are considered to be the same
      // for the status filter. So in order to address this we've added the hack below, but long term
      // we should get rid of that and just have a "snooze" state. We're much closer now as the
      // snoozed state is a real boy in graphql. Same goes for trash
      // Kevin R 2025-03-07
      // Its never ending. When the condition has a value of unread, then unread tickets are just considered
      // unread. However when the condition has a value of opened, then unread tickets are considered opened.
      const stateRaw = state.toLowerCase()
      let stateValue = state
      if (channelType === MAILBOX_CHANNEL_TYPE) {
        if (['snoozed', 'trash'].includes(stateRaw)) {
          stateValue = 'closed'
        } else if (
          ['unread'].includes(stateRaw) &&
          value.toLowerCase() === 'opened'
        ) {
          stateValue = 'opened'
        }
      }
      return {
        prop: statusMap[stateValue],
        value: statusMap[value.toLowerCase()],
      }
    }
    case FOLDER_CONDITION_TYPES.DELETED:
      return { prop: isTrash, value: isTrue(value) }
    case FOLDER_CONDITION_TYPES.CHANNEL:
      return { prop: channelType, value }
    case FOLDER_CONDITION_TYPES.SNOOZE_UNTIL: {
      const indefinitely = 999999999

      // When folder filter is snoozed indefinitely and conversation
      // is snoozed indefinitely
      if (value === 'indefinitely' && snoozedUntil === SNOOZED_INDEFINITELY)
        return { prop: indefinitely, value: indefinitely }

      // when folder condition is snoozed indefinitely and conversation
      // is snoozedUntil
      if (value === 'indefinitely')
        return secondsUntilOperands(snoozedUntil, indefinitely)

      // when folder condition is snoozed unil X and conversation is
      // snoozed indefinitely
      if (snoozedUntil === SNOOZED_INDEFINITELY) {
        return { prop: indefinitely, value }
      }

      // When neither folder condition or conversation is snoozed indefinitely
      return secondsUntilOperands(snoozedUntil, value)
    }
    case FOLDER_CONDITION_TYPES.SNOOZE_STATE: {
      if (state !== 'snoozed') {
        return { prop: 0, value: 1 }
        // value of null means "Anyone", so if we have anyone,
        // we return a preformatted pair that will work in operation
      } else if (!!stateChangedByAgentId && value === null) {
        return { prop: 1, value: 1 }
      } else if (stateChangedByAgentId === null && value === null) {
        return { prop: 0, value: 1 }
      }
      return { prop: stateChangedByAgentId, value }
    }
    case FOLDER_CONDITION_TYPES.ASSIGNED_AGENT: {
      const session = entityStore.session.byId.current
      let mappedValue = value
      if (value === CURRENT_USER_ID) mappedValue = session.user
      return {
        prop: assignedAgentId,
        value: mappedValue,
      }
    }
    case FOLDER_CONDITION_TYPES.ASSIGNED_GROUP:
      return {
        prop: assignedTeamId,
        value,
      }
    case FOLDER_CONDITION_TYPES.INTERACTION_COUNT:
      return { prop: interactionCount, value }
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_UPDATED:
      return hoursSinceOperands(updatedAt, value)
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_STATUS_CHANGED:
      return hoursSinceStateOperands(conversation, value)
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_OPEN:
      return hoursSinceStateOperands(conversation, value, 'open')
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_PENDING:
      return hoursSinceStateOperands(conversation, value, 'pending')
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_CLOSED:
      return hoursSinceStateOperands(conversation, value, 'closed')
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_ASSIGNED:
      return hoursSinceOperands(assignedAt, value)
    case FOLDER_CONDITION_TYPES.HOURS_SINCE_LAST_UNANSWERED_USER_MESSAGE:
      return hoursSinceOperands(lastUnansweredUserMessageAt, value)
    case FOLDER_CONDITION_TYPES.TAGS:
      return { prop: labels, value }
    case FOLDER_CONDITION_TYPES.MENTIONS: {
      const session = entityStore.session.byId.current
      const mappedValues = value.split(',').map(v => {
        return v === CURRENT_USER_ID ? session.user : v
      })

      return {
        prop: mentionAgentIds,
        value: mappedValues,
      }
    }
    case FOLDER_CONDITION_TYPES.CUSTOM_FIELD: {
      const customField = entityStore.customFields.byId[source?.id]
      if (customField && customFieldKey) {
        let customFieldValue =
          customFieldValues[customFieldKey]?.value?.content || ''

        if ([MULTI_SELECT, DROPDOWN].includes(customField.type)) {
          customFieldValue = customFieldValue.split(',')
        } else if (customField.type === NUMBER) {
          const customFieldValueParsed = parseInt(customFieldValue, 10)
          return {
            prop: isNaN(customFieldValueParsed)
              ? undefined
              : customFieldValueParsed,
            value: parseInt(value, 10),
          }
        } else if (customField.type === DATE) {
          const customFieldValueParsed = Date.parse(customFieldValue)
          return {
            prop: isNaN(customFieldValueParsed)
              ? undefined
              : customFieldValueParsed,
            value: Date.parse(value),
          }
        }

        return { prop: customFieldValue, value }
      }
      return { prop: '', value }
    }
    default:
      return { prop: conversation[param], value }
  }
}

// Determines if the given ticket matches the given filter condition
// Returns true/false
export const matchConversation = (condition, conversation, entityStore) => {
  const opFn = getOperatorFn(condition.operator)
  const { prop, value, gateCondition = true } = getOperands(
    condition,
    conversation,
    entityStore
  )
  return prop !== undefined && opFn(prop, value) && gateCondition
}

// Determines if the given ticket matches any/all (matchType) of the the given
// filter conditions
export const matchFilter = (filter, conversation, entityStore) => {
  const { conditions, matchType } = filter
  const matchFn = matchType === MATCH_TYPE_ALL ? all : any
  const matches = conditions.map(condition =>
    matchConversation(condition, conversation, entityStore)
  )
  return matchFn(equalsTrue, matches)
}

// This method deviates from the server implementation because we already know "who"
// we're generating this for. The net effect is that the server version will generate
// a folder search for each agent and this version only generate a folder search
// for the current agent
const computeFolderSearches = (conversation, filter, entityStore) => {
  const matchesFilter = matchFilter(filter, conversation, entityStore)
  if (matchesFilter) {
    const filters = [`folder:${filter.id}`]
    if (!conversation.isRead) {
      filters.push(`folderunread:${filter.id}`)
    }
    return filters
  }
  return []
}

const computeConversationSearches = (conversation, entityStore) => {
  if (!conversation) return {}
  // 1 to 1 port of the logic in index_room_service. Make sure you keep that version
  // insync if you change this version
  const {
    channelId,
    state,
    assignedType,
    assignedAgentId,
    assignedTeamId,
    draftAgentIds,
    mentionAgentIds,
    isStarred,
    isRated,
    lastRating,
    tagIds,
    customFieldValues,
    contact,
  } = conversation

  const channel = entityStore?.channel?.byId[getRawId(channelId)]
  const currentUserId = entityStore.session.byId.current.user
  const isAssignedToCurrentUser = assignedAgentId === currentUserId
  if (channel && !channel.hasAccess) return {}

  // Channel searches
  const channels = [null, `channel:${channelId}`]

  // My searches
  const my = []

  // State searches
  const states = []
  // eslint-disable-next-line default-case
  switch (state) {
    // Unread is a modifier state of "open"
    case 'unread':
      states.push(null, 'is:open')
      if (isAssignedToCurrentUser) my.push(null, 'my:open')
      break
    case 'open':
    case 'opened':
      states.push(null, 'is:open')
      if (isAssignedToCurrentUser) my.push(null, 'my:open')
      break
    case 'snoozed':
      states.push(null, 'is:snoozed')
      if (isAssignedToCurrentUser) my.push(null, 'my:snoozed')
      break
    case 'closed':
      states.push(null, 'is:closed')
      if (isAssignedToCurrentUser) my.push(null, 'my:closed')
      break
    case 'spam':
      states.push(null, 'is:spam')
      break
    case 'deleted':
    case 'trash':
      states.push(null, 'is:deleted')
      states.push(null, 'is:trash')
      if (isAssignedToCurrentUser) {
        my.push(null, 'my:deleted')
        my.push(null, 'my:trash')
      }
      break
  }

  // Agent searches
  const agents = []
  const teams = []
  const unassigned = [null]

  switch (assignedType) {
    case 'both':
      agents.push(
        null,
        'is:assigned',
        `agent:${buildId('Agent', assignedAgentId)}`,
        `assignee:${buildId('Agent', assignedAgentId)}`
      )
      teams.push(null, 'is:assigned', `team:${buildId('Team', assignedTeamId)}`)
      break
    case 'agent':
      agents.push(
        null,
        'is:assigned',
        `agent:${buildId('Agent', assignedAgentId)}`,
        `assignee:${buildId('Agent', assignedAgentId)}`
      )
      break
    case 'team':
      teams.push(null, 'is:assigned', `team:${buildId('Team', assignedTeamId)}`)
      break
    default:
      agents.push(null, 'assignee:unassigned')
      teams.push(null, 'group:unassigned')
      unassigned.push(null, 'is:unassigned')
  }

  // Draft searches (not currently implemented)
  const drafts = []
  draftAgentIds.forEach(agentId => {
    drafts.push(`draft:${buildId('Agent', agentId)}`)
    if (agentId === currentUserId) {
      my.push(null, 'my:drafts')
    }
  })

  // Mention searches
  const mentions = []
  mentionAgentIds.forEach(agentId => {
    mentions.push(`mentions:${buildId('Agent', agentId)}`)
  })

  // From searches
  const from = contact.email ? [null, `from:${contact.email}`] : [null]

  // Starred searches
  const starred = isStarred ? [null, 'is:starred'] : [null]
  if (isStarred && isAssignedToCurrentUser) {
    my.push(null, 'my:starred')
  }

  // Unread searches (doesnt appear to be used)
  // const unread = !isRead ? [null, 'is:unread'] : [null]
  // Rating searches
  const ratings = isRated
    ? [null, 'is:rated', `rating:${lastRating.grade}`]
    : [null]
  // Tag searches
  const tags = [
    null,
    ...tagIds
      .map(tagId => {
        const tag = entityStore?.tag?.byId[buildId('Tag', tagId)]
        if (!tag) return null

        return `tag:${tag.name}`
      })
      .filter(x => !!x),
    ...tagIds.map(tagId => `tagid:${buildId('Tag', tagId)}`),
  ]

  // Folder searches
  const filters = Object.values(entityStore.folder.byId)
  const folders = filters
    .map(filter => computeFolderSearches(conversation, filter, entityStore))
    .flat()

  const customFields = Object.keys(customFieldValues)
    .map(key => {
      const searchKey = customFieldKeyToSearchKey(key)
      const value = customFieldValues[key].value
      if (Array.isArray(value)) {
        return value.map(v => `${searchKey}:${v?.content}`)
      }

      return `${searchKey}:${value?.content}`
    })
    .flat()

  return [
    channels,
    unassigned,
    states,
    teams,
    agents,
    tags,
    drafts,
    starred,
    mentions,
    ratings,
    folders,
    customFields,
    from,
    my,
  ]
    .flat()
    .filter(x => !!x)
    .reduce((combinedSearches, queryId) => {
      // eslint-disable-next-line no-param-reassign
      combinedSearches[queryId] = true
      return combinedSearches
    }, {})
}

export const calculateBasicDiff = (
  updatedConversation,
  currentConversation,
  entityStore
) => {
  const updatedSearches = computeConversationSearches(
    updatedConversation,
    entityStore
  )
  const currentSearches = computeConversationSearches(
    currentConversation,
    entityStore
  )
  // Usefull for debugging
  // console.log('Diff:calculateBasicDiff', { currentSearches, updatedSearches })

  const updatedQueryIds = Object.keys(updatedSearches)
  const currentQueryIds = Object.keys(currentSearches)
  const allQueryIds = uniq(updatedQueryIds.concat(currentQueryIds))
  const diff = {}
  allQueryIds.forEach(queryId => {
    const valueIs = currentSearches[queryId]
    const valueWillBe = updatedSearches[queryId]
    if (valueIs && !valueWillBe) {
      diff[queryId] = -1
    } else if (!valueIs && valueWillBe) {
      diff[queryId] = 1
    } else {
      diff[queryId] = 0
    }
  })
  return diff
}

export const SEARCH_DIFF_TYPES = {
  REMOVED: 'REMOVED',
  ADDED: 'ADDED',
  NO_CHANGE: 'NO_CHANGE',
}

// Kevin R
// Just a note about joinWith. This field is being used inside the calculateDiffType to determine
// if the searchDiffs should affect the queryId that is stored inside searchesV2.byQueryId.
// The important thing to note here is that if the query supports multiple and it evaluates on the
// backend as a OR statement, then we need the joinWith configured with a AND statement.
// The might seem counter intuative, but essnetially if you search for 2 channels, the backend
// returns all conversations that is in eather of those channels. However when calculating if
// the conversation should still be part of a search we need to check if the conversation is
// no longer part of ALL the channels. I'll give an example to illustrate this.

// Lets say you have the following search and there is 3 channels in the backend.
// channel:ch_1 channel:ch_2

// In example 1 we move the conversation from ch_1 to ch_2.
// The search diffs produced will say channel:ch_1: -1, channel:ch_2: 1, channel:ch_3: 0
// In this situation the conversation should still be part of the search because of the backend OR
// statement

// In example 1 we move the conversation from ch_1 to ch_3.
// The search diffs produced will say channel:ch_1: -1, channel:ch_2: 0, channel:ch_3: 1
// Because this conversation was removed from ch_1 and not added to ch_2 it means that it should
// no longer be present in the search and should be removed.
export const calculateDiffType = (
  rawQueryId,
  searchDiffs,
  type,
  { isOptimistic = false } = {}
) => {
  // const searchDiffQueryId = normalizeSearchQueryId(
  //  `${rawSearchDiffQueryId} type:${MAILBOX_CHANNEL_TYPE}`
  // )
  // eslint-disable-next-line no-param-reassign
  searchDiffs[`type:${type}`] = 0

  // NOTE: we have a know issue with , 'search', 'before', 'after'. We dont generate searches
  // from the calculateBasicDiff function, so realtime updates for those never work. This means
  // that if you have the following search "is:open test", even when closing the conversation it wont
  // remove the conversation from the list because no realtime updates work. In order to address this
  // we remove search from the state key in comparison and just look at the is:open part. The problem
  // with this however is that when a conversation is opened and we get a realtime update, that conversation
  // pops into the conversation list even if it doesnt include the word test. In order to work around this
  // we'll only remove uncalculated fields for optimistic updates and any realtime updates will be ignored.
  const keysToRemove = isOptimistic
    ? ['orderBy', 'cursor', 'search', 'before', 'after']
    : ['orderBy', 'cursor']

  const queryId = removeKeysFromQueryId(keysToRemove, rawQueryId)

  const query = queryIdToQuery(queryId)
  if (!query) {
    return SEARCH_DIFF_TYPES.NO_CHANGE
  }
  const partFinal = Object.keys(query)
    .map(key => {
      const queryConfig = getQueryConfigByKey(key)
      if (!queryConfig.allowMultiple) return searchDiffs[`${key}:${query[key]}`]

      const valueArray = Array.isArray(query[key]) ? query[key] : [query[key]]

      const countArray = valueArray.map(value => searchDiffs[`${key}:${value}`])
      if (queryConfig.joinWith === 'OR') {
        return countArray
      }

      const countSum = countArray.reduce((acc, count) => acc + count, 0)
      if (countArray.includes(undefined)) {
        return undefined
      } else if (countArray.includes(0)) {
        return 0
      } else if (countSum > 0) {
        return 1
      }
      return -1
    })
    .flat()

  let operation = SEARCH_DIFF_TYPES.NO_CHANGE
  if (!partFinal.includes(undefined)) {
    if (partFinal.includes(-1) && partFinal.includes(1)) {
      operation = SEARCH_DIFF_TYPES.NO_CHANGE
    } else if (partFinal.includes(1)) {
      operation = SEARCH_DIFF_TYPES.ADDED
    } else if (partFinal.includes(-1)) {
      operation = SEARCH_DIFF_TYPES.REMOVED
    }
  }

  // Usefull debugging infor
  // const parts = queryId.split(' ')
  // const partMap = parts.map(part => [part, searchDiffs[part]])
  // console.log('Diff:calculateDiffType', {
  //   rawQueryId,
  //   query,
  //   operation,
  //   searchDiffs,
  //   parts,
  //   partMap,
  //   partFinal,
  //   config: Object.keys(query).map(key => getQueryConfigByKey(key)),
  // })

  return operation
}
