import { createSelector } from 'reselect'
import { getRawId } from 'util/globalId'
import { selectCurrentQuery } from './selectCurrentQuery'

export const selectCurrentMailboxId = createSelector(
  selectCurrentQuery,
  query => {
    const { channel: urlMailboxIds } = query
    const urlMailboxId = urlMailboxIds ? urlMailboxIds[0] : null

    return getRawId(urlMailboxId || null)
  }
)
