/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import produce from 'immer'
import {
  CHANGE_TYPE_NOTE_MESSAGE,
  CHANGE_TYPE_SUMMARY_MESSAGE,
} from '../constants'
import {
  selectConversationEventGroupsById,
  selectMessagesById,
} from '../selectors'

export const mergeTicketEntities = getState => normalizedEntities => {
  const state = getState()
  const messagesById = selectMessagesById(state)
  const eventGroupsById = selectConversationEventGroupsById(state)

  return produce(normalizedEntities, draftTransformedEntities => {
    const reloadMessageIds = []

    if (draftTransformedEntities.message) {
      Object.keys(draftTransformedEntities.message).forEach(messageId => {
        const newMessage = draftTransformedEntities.message[messageId]
        const existingMessage = messagesById[messageId]
        if (
          // If there is no existing message. This indicates a new message being loaded
          // on the frontend for the first time. Either via a initial load or realtime load
          !existingMessage ||
          // There is an existing message, but its still the summary version so we dont need extra info
          existingMessage.__typename === CHANGE_TYPE_SUMMARY_MESSAGE
        )
          return

        if (
          // Note messages are special due to the fact that they can be edited. This means that if a note comes in
          // and it has been edited we should let normalizer update the message in the store.
          existingMessage.__typename === CHANGE_TYPE_NOTE_MESSAGE &&
          existingMessage.editedAt !== newMessage.editedAt
        ) {
          reloadMessageIds.push(messageId)
          return
        }
        newMessage.__typename = existingMessage.__typename
        // Dont overwrite parts if they are already present
        newMessage.parts = existingMessage.parts
      })
    }

    if (draftTransformedEntities.conversationEventGroup) {
      Object.keys(draftTransformedEntities.conversationEventGroup).forEach(
        eventGroupsId => {
          const newEventGroup =
            draftTransformedEntities.conversationEventGroup[eventGroupsId]
          const existingEventGroup = eventGroupsById[eventGroupsId]
          if (
            !existingEventGroup ||
            existingEventGroup.collapsed ||
            reloadMessageIds.includes(newEventGroup.summary) ||
            newEventGroup.lastEventId !== existingEventGroup.lastEventId
          )
            return
          newEventGroup.collapsed = false
        }
      )
    }
  })
}
