import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { UPDATE_CONVERSATION_ADD_TAGS } from '../actionTypes'
import { updateAddTagsToConversationMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { conversationAddTagsGraphQlResponseSchema } from '../schema'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { onUpdateAttachEventGroupIdToEvents } from '../utils/request'

export const doAddTags = (ticketId, tagIds, options = {}) => async (
  dispatch,
  getState
) => {
  const conversationId = buildId('Conversation', ticketId)
  // If all the tags are already present, then we dont need to update the ticket
  if (tagIds.length === 0) return false

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      tagIdsToAdd: tagIds,
    },
    options
  )

  return dispatch(
    doGraphqlRequest(
      UPDATE_CONVERSATION_ADD_TAGS,
      updateAddTagsToConversationMutation(),
      {
        conversationId,
        tagIds,
      },
      {
        app: true,
        throwOnError: true,
        concurrency: {
          key: ticketId,
          message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
        },
        normalizationSchema: conversationAddTagsGraphQlResponseSchema,
        transformResponse: onUpdateAttachEventGroupIdToEvents(
          'conversationTag'
        ),
        optimist,
        moduleOptions: {
          entities: {
            additionalActions,
          },
        },
        meta: {
          mergeEntities: true,
        },
        ...options,
      }
    )
  )
}
