import { doOpenTicketPage } from 'actions/pages'
import { selectSearchPreviousEntityIdByQueryIdAndEntityId } from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectCurrentTicketId } from '../selectors/selectCurrentTicketId'

export function doOpenPreviousTicketPage() {
  return async (dispatch, getState) => {
    const state = getState()
    const queryId = selectCurrentQueryId(state)
    const currentConversationId = selectCurrentTicketId(state)
    const previousConversationId = selectSearchPreviousEntityIdByQueryIdAndEntityId(
      state,
      queryId,
      currentConversationId
    )
    if (previousConversationId) {
      return dispatch(
        doOpenTicketPage(previousConversationId, { preserveQuery: true })
      )
    }

    return null
  }
}
