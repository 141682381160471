import config from 'config'
import { NEWEST, OLDEST } from 'constants/defaults'
import { SORT_CONTEXT_KEY_DELIMITER } from 'util/search/sorting'

export const DEFAULT_SORT_CONTEXT_ID = [NEWEST, OLDEST].join(
  SORT_CONTEXT_KEY_DELIMITER
)

export const MAILBOX_PERSIST_KEY = 'lastMailboxQueryId'

const wantsMore = config.isAlpha || config.isDevelopment || config.isDocker

export const MAX_REMEMBERED_SEARCHES = wantsMore ? 20 : 5

export const PAGINATION_MODE = {
  ES_CURSOR: 'ESCursor',
  CURSOR: 'cursor',
  NUMBER: 'number',
}
