import { createBasicSelector } from 'util/redux'
import { selectCurrentTicketId } from './selectCurrentTicketId'
import { selectConversationEventGroups } from '.'

export const selectCurrentTicketDenormalizedEventGroups = createBasicSelector(
  state => state,
  selectCurrentTicketId,
  state => selectConversationEventGroups(state, true),
  (state, currentTicketId, conversationEventGroups) => {
    return conversationEventGroups.filter(
      ceg => ceg.conversationId === currentTicketId
    )
  }
)
