import { actionToPath, selectLocationState } from 'redux-first-router'
import { createBasicSelector } from 'util/redux'
import querySerializer from 'query-string'
import { TICKET_PAGE, SEARCH_PAGE } from 'constants/pages'
import { selectQueryParams, selectIsOnSearchPage } from 'selectors/location'

export const selectLinkUrlForTicketId = createBasicSelector(
  selectLocationState,
  selectQueryParams,
  selectIsOnSearchPage,
  (_state, ticketId) => ticketId,
  (locationState, currentPageQuery, isOnSearchPage, ticketId) => {
    const { routesMap } = locationState
    const TicketPageType = isOnSearchPage ? SEARCH_PAGE : TICKET_PAGE
    return actionToPath(
      {
        type: TicketPageType,
        payload: {
          id: ticketId,
          changesetId: null,
          viaKeyboard: false,
          replying: false,
        },
        meta: {
          query: currentPageQuery,
        },
      },
      routesMap,
      querySerializer
    )
  }
)
