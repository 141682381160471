import createCachedSelector from 're-reselect'
import { SORT_CONTEXT_KEY_DELIMITER } from 'util/search/sorting'
import { selectSortContextIdByQueryId } from './selectSortContextIdByQueryId'

export const selectDefaultSortOrderByQueryId = createCachedSelector(
  selectSortContextIdByQueryId,
  contextId => {
    const options = contextId.split(SORT_CONTEXT_KEY_DELIMITER) || []
    // I dont know why, but on the old system longestUnanswered is always the "first" option
    // in the dropdown, but its not the "default" search option.....fuck knows why.
    return options.filter(sk => sk !== 'longestUnanswered')[0]
  }
)((_state, queryId) => queryId || 'unknown')
