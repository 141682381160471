import { selectContactsById } from 'ducks/crm/contacts/selectors/base'
import { createBasicSelector } from 'util/redux'
import { selectCurrentConversationById } from '.'

// Checks if customer email missing on non-twitter tickets
export const selectIsContactEmailBlankByConversationId = createBasicSelector(
  selectCurrentConversationById,
  selectContactsById,
  (ticket, contactsById) => {
    const contact = contactsById[ticket?.contact]
    return ticket && !ticket.isTwitterTicket && contact && !contact.email
  }
)
