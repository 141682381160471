/* eslint-disable no-param-reassign */
import Bugsnag from '@bugsnag/js'
import debug from 'util/debug'
import { doFetchTicketEventGroups } from './doFetchTicketEventGroups'
import { selectTicketEventGroupsByConversationId } from '../selectors/selectTicketEventGroupsByConversationId'

export function doRecordErrorAndFetchTicketEventGroups(
  ticketId,
  eventGroups,
  options = {}
) {
  return async (dispatch, getState) => {
    debug(
      'Changesets component detected with no event groups, attempting automatic repair',
      {
        ticketId,
        eventGroups,
      }
    )

    let response = null
    let loadedEventGroups = null

    try {
      response = await dispatch(doFetchTicketEventGroups(ticketId, options))
      loadedEventGroups = selectTicketEventGroupsByConversationId(
        getState(),
        ticketId
      )

      debug('Changesets component data after automatic repair', {
        ticketId,
        eventGroups,
        loadedEventGroups,
        response,
      })

      if (loadedEventGroups.length === 0) {
        throw new Error('Event group empty after reload')
      }
    } catch (error) {
      debug('Unable to automatically repair event groups', {
        ticketId,
        eventGroups,
        loadedEventGroups,
        response,
      })

      Bugsnag.notify(
        new Error('Changesets component detected with no event groups'),
        // eslint-disable-next-line no-loop-func
        event => {
          // Set the severity level
          // eslint-disable-next-line no-param-reassign
          event.severity = 'error'

          // Attach additional metadata, including the original error
          event.addMetadata('metaData', {
            ticketId,
            eventGroups,
            loadedEventGroups,
            response,
            originalError: {
              message: error.message,
              stack: error.stack,
              name: error.name,
            },
          })
        }
      )
    }
  }
}
