import { selectRequestByType } from 'ducks/requests/selectors'
import { createBasicSelector } from 'util/redux'
import { buildConversationRequestKey } from '../utils/request'

export const selectTicketRequestByConversationId = createBasicSelector(
  (state, conversationId) => {
    return selectRequestByType(
      state,
      buildConversationRequestKey(conversationId)
    )
  }
)
