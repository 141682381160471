import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { propFunc } from 'util/functions'
import { Button } from 'shared/ui'
import styles from './styles.less'

export default function ReadStateButtonView({
  ticketId,
  isUnread,
  markAsRead,
  markAsUnread,
}) {
  const label = `Mark as ${isUnread ? 'read' : 'unread'}`
  const onClick = isUnread
    ? propFunc(markAsRead, ticketId, {})
    : propFunc(markAsUnread, ticketId, {})
  return (
    <Button
      basic
      onClick={onClick}
      className={cn(styles.readStateButton, {
        [styles.isUnread]: isUnread,
      })}
    >
      {label}
    </Button>
  )
}

ReadStateButtonView.propTypes = {
  ticketId: PropTypes.string.isRequired,
  markAsRead: PropTypes.func.isRequired,
  markAsUnread: PropTypes.func.isRequired,
}
