import { mergePlain } from 'util/merge'
import { doSdkRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { UPDATE_CONVERSATION_TAGS } from '../actionTypes'
import { selectCurrentConversationById } from '../selectors'
import {
  conversationRemoveTagsGraphQlResponseSchema,
  conversationAddTagsGraphQlResponseSchema,
} from '../schema'
import { calculateSyncTags } from '../utils/tags'
import { doAddTags } from './doAddTags'
import { doRemoveTags } from './doRemoveTags'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'

const transformAttachEventGroupIdToEvents = data => {
  const eventGroup =
    data?.conversationUntag?.eventGroup || data?.conversationTag?.eventGroup

  if (eventGroup) {
    eventGroup.isLastMessage = true
    eventGroup.events.edges.forEach(e => {
      // eslint-disable-next-line no-param-reassign
      e.node.eventGroupId = eventGroup.id

      const change = e.node.change
      if (
        change &&
        // eslint-disable-next-line no-underscore-dangle
        change.__typename === 'IntegrationAdded' &&
        !change.conversationId
      ) {
        change.conversationId = eventGroup.conversationId
      }
    })
  }
  return data
}

export const doUpdateTags = (ticketId, tagIds, options = {}) => async (
  dispatch,
  getState
) => {
  if (!tagIds) return null
  const conversationId = buildId('Conversation', ticketId)

  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)

  const { tagsToAdd, tagsToRemove } = calculateSyncTags(tagIds, ticket.tags)
  const hasTagsToAdd = tagsToAdd.length > 0
  const hasTagsToRemove = tagsToRemove.length > 0

  if (!(hasTagsToAdd || hasTagsToRemove)) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      tagIdsToAdd: tagsToAdd,
      tagIdsToRemove: tagsToRemove,
    },
    options
  )

  return dispatch(
    doSdkRequest(
      UPDATE_CONVERSATION_TAGS,
      async () => {
        let addResponse = null
        let removeResponse = null
        if (hasTagsToAdd) {
          addResponse = await dispatch(
            doAddTags(ticketId, tagsToAdd, {
              // Normalization and optimistic updates handled
              // in this method, so override this functionality
              normalizationSchema: null,
              optimist: null,
              moduleOptions: {
                entities: null,
              },
            })
          )
        }
        if (hasTagsToRemove) {
          removeResponse = await dispatch(
            doRemoveTags(ticketId, tagsToRemove, {
              // Normalization and optimistic updates handled
              // in this method, so override this functionality
              normalizationSchema: null,
              optimist: null,
              moduleOptions: {
                entities: null,
              },
            })
          )
        }

        return hasTagsToRemove ? removeResponse : addResponse
      },
      {
        conversationId,
        tagsToAdd,
        tagsToRemove,
      },
      mergePlain(
        {
          throwOnError: true,
          normalizationSchema: hasTagsToRemove
            ? conversationRemoveTagsGraphQlResponseSchema
            : conversationAddTagsGraphQlResponseSchema,
          transformResponse: transformAttachEventGroupIdToEvents,
          optimist,
          concurrency: {
            // We need to use a key that isnt the same as the nested requests we're making
            // to prevent a deadlock from occuring. (doAddTags cant run because its waiting)
            // for doUpdateTags to finish. but do update tags will never finish because its
            // waiting for the doAddTags to execute
            key: `tags-${ticketId}`,
            message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
            queueActions: true,
          },
          moduleOptions: {
            entities: {
              additionalActions,
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
