import { selectChannelTypeFromUrl } from 'ducks/channels/selectors'
import { doSdkRequest } from 'ducks/requests/operations'
import { queryIdToQuery } from '../utils/query'
import { selectCurrentQueryId } from '../selectors/selectCurrentQueryId'
import { selectCurrentQuery } from '../selectors/selectCurrentQuery'
import { doFetchConversations } from './doFetchConversations'
import { doFetchFolderCounts } from './doFetchFolderCounts'

import { REFRESH_SEARCHES } from '../actionTypes'

export const doRefreshSearches = () => {
  return doSdkRequest(
    REFRESH_SEARCHES,
    async ({ dispatch, getState }) => {
      const state = getState()
      const queryId = selectCurrentQueryId(state)

      const query = selectCurrentQuery(state)
      const pageChannelType = selectChannelTypeFromUrl(state)
      const { type: channelType = pageChannelType } =
        queryIdToQuery(queryId) || {}

      const requests = [
        // Refetch conversations for the current ticket list
        dispatch(
          doFetchConversations({
            channelType,
            queryId,
            cursor: null,
            size: 20,
          })
        ),
        // Refetch counts for the "All" folder. This is important
        // as the top level mailbox counts also get pulled in here
        dispatch(
          doFetchFolderCounts({
            channelType: pageChannelType,
          })
        ),
      ]

      // Refetch counts for the current channels folders
      if (query.channel?.[0]) {
        requests.push(
          dispatch(
            doFetchFolderCounts({
              channelId: query.channel?.[0],
              channelType: pageChannelType,
            })
          )
        )
      }
      const results = await Promise.all(requests)
      return results
    },
    {},
    {}
  )
}
