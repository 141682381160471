import { connect } from 'react-redux'

import { selectTicketFlagsByConversationId } from 'ducks/tickets/selectors/selectTicketFlagsByConversationId'
import { selectConversationInvertStateActionLabelByConversationId } from 'ducks/tickets/selectors/selectConversationInvertStateActionLabelByConversationId'
import { selectConversationSnoozedUntilByConversationId } from 'ducks/tickets/selectors/selectConversationSnoozedUntilByConversationId'
import { selectConversationStateByConversationId } from 'ducks/tickets/selectors/selectConversationStateByConversationId'
import { doToggleTicketState } from 'ducks/tickets/actions/doToggleTicketState'

import View from './view'

const select = () => {
  return (state, props) => {
    const {
      isDeleted,
      isSnoozed,
      isOpen,
      isClosed,
      isUnread,
    } = selectTicketFlagsByConversationId(state, props.ticketId)
    return {
      isDeleted,
      isSnoozed,
      isOpen,
      isClosed,
      isUnread,
      snoozedUntil: selectConversationSnoozedUntilByConversationId(
        state,
        props.ticketId
      ),
      stateLabel: selectConversationStateByConversationId(
        state,
        props.ticketId
      ),
      invertStateActionLabel: selectConversationInvertStateActionLabelByConversationId(
        state,
        props.ticketId
      ),
    }
  }
}

const perform = {
  toggleTicketState: doToggleTicketState,
}

export default connect(select, perform)(View)
