import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import { CONVERSATION_ADD_NOTE } from '../actionTypes'
import { updateConversationAddNoteMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE, SNOOZED } from '../constants'
import { conversationAddNoteGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import {
  onUpdateAttachEventGroupIdToEvents,
  withAutoRedirect,
  withBeforeSend,
} from '../utils/request'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import {
  attachmentsToEmailAttachmentInput,
  parseToApiSnoozeUntil,
} from '../utils/payload'

export const doAddNote = (
  ticketId,
  body,
  draftId,
  inputState,
  inputSnoozeUntil,
  inputAgentId,
  inputTeamId,
  inputAttachments,
  options = {}
) => async (dispatch, getState) => {
  const attachments = attachmentsToEmailAttachmentInput(inputAttachments)
  const conversationId = buildId('Conversation', ticketId)
  const agentId = buildId('Agent', inputAgentId)
  const teamId = buildId('Team', inputTeamId)

  const store = getState()
  const ticket = selectCurrentConversationById(store, ticketId)
  const snoozeUntil = parseToApiSnoozeUntil(
    inputState === SNOOZED
      ? inputSnoozeUntil || ticket.snoozed?.until
      : inputSnoozeUntil
  )
  const state = inputSnoozeUntil ? SNOOZED : inputState
  if (!ticket || isNullOrUndefined(body)) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      state,
      snoozeUntil,
      agentId,
      teamId,
      note: {
        body,
        attachments: inputAttachments,
      },
    },
    options
  )
  const response = await dispatch(
    withBeforeSend(
      ticketId,
      draftId,
      withAutoRedirect(
        ticketId,
        (afterStartedActions, afterSuccessActions) => async () =>
          dispatch(
            doGraphqlRequest(
              CONVERSATION_ADD_NOTE,
              updateConversationAddNoteMutation(),
              {
                conversationId,
                body,
                draftId,
                state,
                snoozeUntil,
                agentId,
                teamId,
                attachments,
                skipNotifications: !!options.skipNotifications,
              },
              mergePlain(
                {
                  app: true,
                  throwOnError: true,
                  concurrency: {
                    key: ticketId,
                    message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                  },
                  normalizationSchema: conversationAddNoteGraphQlResponseSchema,
                  transformResponse: onUpdateAttachEventGroupIdToEvents(
                    'conversationAddNote'
                  ),
                  optimist,
                  moduleOptions: {
                    snackbar: {
                      enabled: true,
                      started: {
                        enabled: false,
                      },
                      success: {
                        enabled: false,
                      },
                      failed: {
                        enabled: true,
                        message: `Oops, we couldn't add your note. Please try again in a moment.`,
                      },
                    },
                    entities: {
                      additionalActions,
                    },
                  },
                  meta: {
                    mergeEntities: true,
                  },
                  afterStartedActions,
                  afterSuccessActions,
                },
                options
              )
            )
          ),
        mergePlain(
          {
            moduleOptions: {
              autoRedirect: {
                enabled: true,
                redirectFirst: true,
                // Notes never redirect away from the current ticket
                // so we're overring the ticket extraction function
                // and just always returning the current ticket id
                // to make sure it just redirects to the current ticket
                extractTicketId: () => ticketId,
                forced: true,
                operation: 'note',
              },
            },
          },
          options
        )
      )
    )
  )

  return response
}
