import { selectChannelTypeFromUrl } from 'ducks/channels/selectors'
import { doSdkRequest } from 'ducks/requests/operations'
import { selectCurrentQuery } from '../selectors/selectCurrentQuery'
import { doFetchFolderCounts } from './doFetchFolderCounts'

import { REFRESH_SEARCHES } from '../actionTypes'

export const doRefreshCounts = () => {
  return doSdkRequest(
    REFRESH_SEARCHES,
    async ({ dispatch, getState }) => {
      const state = getState()

      const query = selectCurrentQuery(state)
      const pageChannelType = selectChannelTypeFromUrl(state)

      const requests = [
        // Refetch counts for the "All" folder. This is important
        // as the top level mailbox counts also get pulled in here
        dispatch(
          doFetchFolderCounts({
            channelType: pageChannelType,
          })
        ),
      ]

      // Refetch counts for the current channels folders
      if (query.channel?.[0]) {
        requests.push(
          dispatch(
            doFetchFolderCounts({
              channelId: query.channel?.[0],
              channelType: pageChannelType,
            })
          )
        )
      }
      const results = await Promise.all(requests)
      return results
    },
    {},
    {}
  )
}
