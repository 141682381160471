import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { emptyArr, emptyObj } from 'util/objects'

export const selectBase = state => state.wallets || emptyObj
export const selectWalletsById = createSelector(
  selectBase,
  wallets => wallets.byId || emptyObj
)

const selectWalletsStatusByFeatureType = createCachedSelector(
  selectBase,
  (_state, featureTypeName) => featureTypeName,
  (wallets, featureTypeName) => {
    if (!wallets[featureTypeName]) return emptyObj
    return {
      isLoading: wallets[featureTypeName].isLoading,
      isLoaded: wallets[featureTypeName].isLoaded,
    }
  }
)((_state, featureTypeName) => featureTypeName)

const selectWalletsByFeatureType = createCachedSelector(
  selectBase,
  selectWalletsById,
  (_state, featureTypeName) => featureTypeName,
  (wallets, byId, featureTypeName) => {
    if (!wallets[featureTypeName]) return emptyArr
    return wallets[featureTypeName].ids?.map(id => byId[id]) || emptyArr
  }
)((_state, featureTypeName) => featureTypeName)

const selectAccountWalletByFeatureType = createCachedSelector(
  selectWalletsByFeatureType,
  walletList => {
    return walletList.find(wallet => wallet.ownerType === 'Account') || emptyObj
  }
)((_state, featureTypeName) => featureTypeName)

const selectUserWalletByFeatureType = createCachedSelector(
  selectWalletsByFeatureType,
  selectCurrentUserGlobalId,
  (walletList, currentUserGlobalId) => {
    return (
      walletList.find(
        wallet =>
          wallet.ownerType === 'User' && wallet.ownerId === currentUserGlobalId
      ) || emptyObj
    )
  }
)((_state, featureTypeName) => featureTypeName)

export const selectAiOverviewAccountWallet = createSelector(
  state => selectAccountWalletByFeatureType(state, 'aiOverview'),
  wallet => wallet
)

export const selectAiOverviewUserWallet = createSelector(
  state => selectUserWalletByFeatureType(state, 'aiOverview'),
  wallet => wallet
)

export const selectAiOverviewWalletsStatus = createSelector(
  state => selectWalletsStatusByFeatureType(state, 'aiOverview'),
  status => status
)

export const selectAiDraftAccountWallet = createSelector(
  state => selectAccountWalletByFeatureType(state, 'aiDraft'),
  wallet => wallet
)

export const selectAiDraftWalletsStatus = createSelector(
  state => selectWalletsStatusByFeatureType(state, 'aiDraft'),
  status => status
)
