import { doOpenTicketPage } from 'actions/pages'
import {
  selectSearchNextEntityIdByQueryIdAndEntityId,
  selectSearchHasMoreByQueryId,
  selectSearchIsLoadingMoreByQueryId,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { doFetchTickets } from '.'
import { selectCurrentTicketId } from '../selectors/selectCurrentTicketId'

export function doOpenNextTicketPage() {
  return async (dispatch, getState) => {
    const state = getState()
    const queryId = selectCurrentQueryId(state)
    const hasMore = selectSearchHasMoreByQueryId(state, queryId)
    const isLoadingMore = selectSearchIsLoadingMoreByQueryId(state, queryId)
    const currentConversationId = selectCurrentTicketId(state)
    const nextConversationId = selectSearchNextEntityIdByQueryIdAndEntityId(
      state,
      queryId,
      currentConversationId
    )
    if (nextConversationId) {
      return dispatch(
        doOpenTicketPage(nextConversationId, { preserveQuery: true })
      )
    } else if (hasMore && !isLoadingMore) {
      await dispatch(doFetchTickets())
      dispatch(doOpenNextTicketPage())
    }
    return null
  }
}
