import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { DELETE_CONVERSATION_MESSAGE } from '../actionTypes'
import { deleteNoteMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { conversationDeleteNoteGraphQlResponseSchema } from '../schema'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { onUpdateAttachEventGroupIdToEvents } from '../utils/request'

export const doDeleteNote = (ticketId, messageId, options = {}) => async (
  dispatch,
  getState
) => {
  const conversationId = buildId('Conversation', ticketId)

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      removeMessageId: messageId,
    },
    options
  )

  return dispatch(
    doGraphqlRequest(
      DELETE_CONVERSATION_MESSAGE,
      deleteNoteMutation(),
      {
        conversationId,
        messageId,
      },
      {
        app: true,
        throwOnError: true,
        concurrency: {
          key: ticketId,
          message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
        },
        normalizationSchema: conversationDeleteNoteGraphQlResponseSchema,
        transformResponse: onUpdateAttachEventGroupIdToEvents(
          'conversationDeleteNote'
        ),
        optimist,
        moduleOptions: {
          entities: {
            additionalActions,
          },
        },
        meta: {
          mergeEntities: true,
        },
        ...options,
      }
    )
  )
}
