import {
  CLOSED,
  DELETED,
  OPENED,
  SNOOZED,
  SPAM,
  TRASH,
  UNREAD,
} from '../constants'

export function invertTicketState(stateName) {
  if (stateName === DELETED) return OPENED
  return stateName === 'open' ? CLOSED : OPENED
}

// Eg: Return "Close" when state is "open"
// Return Re-open when ticket state is "closed"
export function getInvertStateActionLabel(state) {
  if (isCloseable(state)) return 'Close'
  return 'Re-open'
}

export const isRead = ticket => ticket && ticket.state !== UNREAD

export const isOpen = ticket =>
  ticket && (ticket.state === OPENED || ticket.state === UNREAD)

export const isClosed = ticket => ticket && ticket.state === CLOSED

export const isSpam = ticket => ticket && ticket.state === SPAM

export const isUnread = ticket => ticket && ticket.state === UNREAD

export const isStarred = ticket => ticket && ticket.starred

export const isDeleted = ticket =>
  ticket && [TRASH, DELETED].includes(ticket.state)

export const isSnoozed = ticket => ticket && ticket.state === SNOOZED

export function isCloseable(stateName) {
  return [UNREAD, OPENED, SNOOZED].includes(stateName)
}

export const inverseStateName = stateName => {
  if (stateName === DELETED) return OPENED
  return isCloseable(stateName) ? CLOSED : OPENED
}

export const shortTitleTitle = ticket =>
  ticket && ticket.subject && !!ticket.subject.length
    ? `${ticket.subject.substring(0, 10)}... `
    : ''
