import { connect } from 'react-redux'

import { selectConversationIsUnreadByConversationId } from 'ducks/tickets/selectors/selectConversationIsUnreadByConversationId'
import { doMarkAsRead } from 'ducks/tickets/actions/doMarkAsRead'
import { doUnread } from 'ducks/tickets/actions/doUnread'

import View from './view'

const select = () => {
  return (state, props) => {
    return {
      isUnread: selectConversationIsUnreadByConversationId(
        state,
        props.ticketId
      ),
    }
  }
}

const perform = {
  markAsRead: doMarkAsRead,
  markAsUnread: doUnread,
}

export default connect(select, perform)(View)
