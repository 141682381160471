import { selectCurrentTagsById } from 'ducks/tags/selectors'
import createCachedSelector from 're-reselect'
import { sortByKey } from 'util/arrays'
import { selectCurrentConversationById } from '.'

export const selectTagsByConversationId = createCachedSelector(
  selectCurrentTagsById,
  selectCurrentConversationById,
  (tagsById, conversation) =>
    sortByKey(conversation.tags.map(lid => tagsById[lid]), 'name')
)((_state, conversationId) => conversationId || 'unknown')
