import { doOpenTicketPage, doOpenNewTicketPage } from 'actions/pages'
import { selectPreferences } from 'ducks/currentUser/selectors/preferences/selectPreferences'
import { selectUndoSendSafe } from 'ducks/undoSend/selectors'
import { doReinstateDraft } from 'ducks/drafts2/operations/doReinstateDraft'
import {
  CONVERSATION_SAVE_UNLOAD_MESSAGE,
  NEW_CONVERSATION_ID,
} from 'ducks/tickets/constants'
import { doGraphqlRequest, doRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { selectConversationEventGroupByChangesetId } from 'ducks/tickets/selectors/selectConversationEventGroupByChangesetId'
import { doDelete } from 'ducks/tickets/actions/doDelete'
import {
  UNDO_SEND_REQUEST,
  UNDO_SEND,
  UNDO_SEND_COMPLETE,
  UNDO_SEND_TIMER_END,
  UNDO_SEND_TIMER_START,
} from './actionTypes'
import { undoSendMutation } from './mutations'

const currentUndo = { timer: null, changeset: null }

export function startTimer({ ticketId, changesetId, draft }) {
  return (dispatch, getState) => {
    // dont start the timer if it's been already running
    if (currentUndo.changeset === changesetId) return false
    const state = getState()
    const undo = selectUndoSendSafe(state)
    const { undo_send_delay: delaySeconds } = selectPreferences(state)
    if (undo && undo.state) return false // aleady sending, sent or undone

    // We support only one timer at a time so stop the previous timer
    stopTimer(currentUndo.changeset)

    // start and save the new timer
    currentUndo.changeset = changesetId
    currentUndo.timer = setTimeout(
      () =>
        dispatch(
          doEndTimer({
            changesetId,
            draftId: draft.id,
          })
        ),
      delaySeconds * 1000
    )

    return dispatch({
      type: UNDO_SEND_TIMER_START,
      payload: {
        ticketId,
        changesetId,
        draft,
      },
    })
  }
}

// Handles when the undo send timer naturally expires. This function stops the
// timer.
export function doEndTimer({ changesetId, draftId }) {
  stopTimer(changesetId)

  return dispatch => {
    dispatch({
      type: UNDO_SEND_TIMER_END,
      payload: { changesetId, draftId },
    })
  }
}

function stopTimer(changesetId) {
  if (currentUndo.changeset === changesetId) {
    clearInterval(currentUndo.timer)
    currentUndo.changeset = null
    currentUndo.timer = null
  }
  // Does not return an action - Use the UNDO_SEND_STARTED action instead
}

const doUndoSendRequest = (
  ticketId,
  changesetId,
  isNewTicket,
  options = {}
) => async (dispatch, getState) => {
  const conversationId = buildId('Conversation', ticketId)
  if (!changesetId) return false

  const eventGroup = selectConversationEventGroupByChangesetId(
    getState(),
    ticketId,
    changesetId
  )
  if (!eventGroup) return false

  const additionalActions = []
  additionalActions.push({
    entityType: 'conversationEventGroup',
    entityId: eventGroup.id,
    stores: ['current'],
    operation: 'remove',
    phases: ['STARTED'],
  })
  additionalActions.push({
    entityType: 'message',
    entityId: eventGroup.summary,
    stores: ['current'],
    operation: 'remove',
    phases: ['STARTED'],
  })
  eventGroup.events.forEach(eventId => {
    additionalActions.push({
      entityType: 'conversationEvent',
      entityId: eventId,
      stores: ['current'],
      operation: 'remove',
      phases: ['STARTED'],
    })
  })

  return dispatch(
    doRequest(
      UNDO_SEND,
      () => {
        if (isNewTicket) {
          return dispatch(
            doDelete(ticketId, {
              moduleOptions: {
                snackbar: {
                  enabled: false,
                },
                autoRedirect: { enabled: false },
              },
            })
          )
        }
        return dispatch(
          doGraphqlRequest(
            UNDO_SEND_REQUEST,
            undoSendMutation(),
            {
              conversationId,
              changesetId,
            },
            {
              app: true,
              throwOnError: true,
              concurrency: {
                key: ticketId,
                message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
              },
              optimist: {},
              moduleOptions: {
                entities: {
                  additionalActions,
                },
              },
              meta: {
                mergeEntities: true,
              },
              ...options,
            }
          )
        )
      },
      {}
    )
  )
}

export const doUndoSend = undoData => async (dispatch, getState) => {
  const { ticketId, changesetId, draft } = undoData

  const isNewTicket = draft?.ticketId === NEW_CONVERSATION_ID

  dispatch({
    type: UNDO_SEND,
    data: { ticketId, changesetId, isNewTicket },
  })

  stopTimer(changesetId)

  // When we undo, go back to the new/reply page so the user can
  // start editing the draft again
  const state = getState()
  if (isNewTicket) {
    dispatch(doOpenNewTicketPage())
  } else {
    dispatch(doOpenTicketPage(ticketId))
  }
  const { draft: undoDraft } = selectUndoSendSafe(state)
  dispatch(doReinstateDraft(undoDraft))
  await dispatch(doUndoSendRequest(ticketId, changesetId, isNewTicket))

  return dispatch({
    type: UNDO_SEND_COMPLETE,
    payload: { ticketId, changesetId, isNewTicket },
  })
}
