import { mergePlain } from 'util/merge'
import { doBulkUpdate } from './doBulkUpdate'
import { doTrash } from './doTrash'

export const doBulkTrash = (ticketIds, options = {}) => dispatch => {
  return dispatch(
    doBulkUpdate(
      ticketIds,
      doTrash,
      [],
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('N_Tickets', {
                  count: ticketIds.length,
                })} moved to trash`,
              },
              failed: {
                enabled: false,
              },
            },
          },
        },
        options
      )
    )
  )
}
