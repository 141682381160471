import debug from 'util/debug'
import { getTrackingContext, PH_DEBUG } from './context'

export const reset = ({ resetDevice = false } = {}) => {
  const { setTrackingContextValue, client, initialized } = getTrackingContext()
  if (!initialized) return
  client.reset(resetDevice)
  setTrackingContextValue(prevTrackingContextValue => ({
    ...prevTrackingContextValue,
    identified: false,
    reset: true,
  }))
}

// eslint-disable-next-line no-unused-vars
export const capture = (eventName, options = {}) => {
  if (PH_DEBUG) debug(`tracking|capture: ${eventName}`, options)
  const {
    getPendingEventQueue,
    capturePendingEvents,
    accountId,
  } = getTrackingContext()
  const queue = getPendingEventQueue()

  queue.push(['capture', [eventName, { ...options, account_id: accountId }]])
  capturePendingEvents()
}

export const record = () => {
  if (PH_DEBUG) debug(`tracking|record:`)
  const { getPendingEventQueue, capturePendingEvents } = getTrackingContext()
  const queue = getPendingEventQueue()

  queue.push(['startSessionRecording', []])
  capturePendingEvents()
}

export const stopRecording = () => {
  if (PH_DEBUG) debug(`tracking|stopRecording:`)
  const { getPendingEventQueue, capturePendingEvents } = getTrackingContext()
  const queue = getPendingEventQueue()

  queue.push(['stopSessionRecording', []])
  capturePendingEvents()
}

export const isFeatureEnabled = featureName => {
  if (PH_DEBUG) debug(`tracking|feature-flag: ${featureName}`)
  const { client } = getTrackingContext()
  if (!client) return true

  // feature flags are cached. When we want to use the flag and quickly update the values, we need to reload the flags
  return client.isFeatureEnabled(featureName)
}

export const getFeatureFlag = featureName => {
  if (PH_DEBUG) debug(`tracking|get-feature-flag: ${featureName}`)
  const { client } = getTrackingContext()
  if (!client) return null

  // feature flags are cached. When we want to use the flag and quickly update the values, we need to reload the flags
  return client.getFeatureFlag(featureName)
}

export const isFeatureFlagEnabled = (featureName, featureValue) => {
  const featureFlagValue = getFeatureFlag(featureName)
  if (PH_DEBUG)
    debug(`tracking|is-feature-flag-enabled: ${featureName} == ${featureValue}`)
  return featureFlagValue === featureValue
}

export const getSurvey = name => {
  if (PH_DEBUG) debug(`tracking|get-survey: ${name}`)
  const { client } = getTrackingContext()
  if (!client) Promise.resolve(null)
  return new Promise(resolve => {
    return client.getActiveMatchingSurveys(surveys => {
      if (!surveys) {
        resolve(null)
      }

      const survey = surveys.find(s => s.name === name)
      resolve(survey)
    })
  })
}
