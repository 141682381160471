import { areArraysEqual, without, withPush } from 'util/arrays'
import { selectCurrentConversationById } from '../selectors'
import { doUpdateTags } from './doUpdateTags'
import { doBulkUpdate } from './doBulkUpdate'

export const doBulkUpdateTags = (ticketIds, changes, options = {}) => (
  dispatch,
  getState
) => {
  return dispatch(
    doBulkUpdate(
      ticketIds,
      doUpdateTags,
      ticketId => {
        const state = getState()
        const ticket = selectCurrentConversationById(state, ticketId)
        const tagIds = [...ticket.tags]
        changes.forEach(({ id: tagId, state: newState }) => {
          if (newState === false) {
            without(tagIds, tagId)
          } else if (newState === true) {
            withPush(tagIds, tagId)
          }
        })

        // When a tag was in an intermediate state, its possible
        // for that tag to be added to the conversation that didnt have it
        // but that no changes are required on the conversation that did have
        // it. If that h appens, no point in updating the ticket.
        if (areArraysEqual(tagIds, ticket.tags)) return [null]

        return [tagIds]
      },
      options
    )
  )
}
