export const UNDO_SEND_TIMER_START = 'undoSend/UNDO_SEND_TIMER_START'
export const UNDO_SEND_TIMER_END = 'undoSend/UNDO_SEND_TIMER_END'
export const UNDO_SEND_TIMER_TICK = 'undoSend/UNDO_SEND_TIMER_TICK'

export const UNDO_SEND_REQUEST = 'undoSend/UNDO_SEND_REQUEST'
export const UNDO_SEND_REQUEST_STARTED = 'undoSend/UNDO_SEND_REQUEST_STARTED'
export const UNDO_SEND_REQUEST_SUCCESS = 'undoSend/UNDO_SEND_REQUEST_SUCCESS'

export const UNDO_SEND = 'undoSend/UNDO_SEND'
export const UNDO_SEND_STARTED = 'undoSend/UNDO_SEND_STARTED'
export const UNDO_SEND_SUCCESS = 'undoSend/UNDO_SEND_SUCCESS'
export const UNDO_SEND_FAILED = 'undoSend/UNDO_SEND_FAILED'
export const UNDO_SEND_COMPLETE = 'undoSend/UNDO_SEND_COMPLETE'
