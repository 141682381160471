import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { selectCurrentChannelById } from 'ducks/channels/selectors'
import { UPDATE_CONVERSATION_CHANNEL } from '../actionTypes'
import { updateConversationChannelMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { conversationChangeChannelGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import { onUpdateAttachEventGroupIdToEvents } from '../utils/request'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'

export const doUpdateChannel = (
  ticketId,
  inputChannelId,
  options = {}
) => async (dispatch, getState) => {
  const conversationId = buildId('Conversation', ticketId)
  const channelId = buildId('Channel', inputChannelId)

  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)
  const newChannel = selectCurrentChannelById(state, channelId)
  if (!ticket || !newChannel || ticket.channelId === channelId) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      channelId,
    },
    options
  )

  return dispatch(
    doGraphqlRequest(
      UPDATE_CONVERSATION_CHANNEL,
      updateConversationChannelMutation(),
      {
        conversationId,
        channelId,
        skipNotifications: !!options.skipNotifications,
      },
      mergePlain(
        {
          app: true,
          throwOnError: true,
          concurrency: {
            key: ticketId,
            message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
          },
          normalizationSchema: conversationChangeChannelGraphQlResponseSchema,
          transformResponse: onUpdateAttachEventGroupIdToEvents(
            'conversationChangeChannel'
          ),
          optimist,
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('Ticket')} moved to "${newChannel.name}"`,
              },
              failed: {
                enabled: true,
                message: `Oops, we couldn't move your ${app.t('ticket')}.`,
              },
            },
            modal: {
              enabled: true,
              open: false,
              phases: ['SUCCESS'],
            },
            entities: {
              additionalActions,
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
