import { isNotFunction } from 'util/functions'

export function createSearchesMiddleware() {
  return ({ getState }) => next => action => {
    if (action?.meta?.location) {
      // eslint-disable-next-line no-param-reassign
      action.meta.previousQueryId = getState()?.searchV2?.currentQueryId
    }
    if (action.type === 'BATCH_ACTIONS') {
      action.actions.filter(isNotFunction).forEach(subAction => {
        if (subAction?.meta?.location) {
          // eslint-disable-next-line no-param-reassign
          subAction.meta.previousQueryId = getState()?.searchV2?.currentQueryId
        }
      })
    }
    return next(action)
  }
}
