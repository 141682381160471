import { mergePlain } from 'util/merge'
import { UNREAD } from '../constants'
import { doUpdateState } from './doUpdateState'

export const doUnread = (ticketId, options = {}) => dispatch => {
  return dispatch(
    doUpdateState(
      ticketId,
      UNREAD,
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: false,
            },
            autoRedirect: {
              enabled: false,
            },
          },
        },
        options
      )
    )
  )
}
