import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import {
  selectCurrentEntitiesById,
  selectCurrentEntityById,
  selectPendingEntityById,
  selectCurrentEntitiesByIds,
  selectCurrentEntitiesSortedBy,
  selectCurrentEntityIds,
} from 'ducks/entities/selectors'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'
import { selectSearchesByQueriesForPartialQuery } from 'ducks/searches/selectors'
import { createBasicSelector } from 'util/redux'
import { queryIdToQuery } from 'ducks/searches/utils/query'
import { selectRequestByType } from 'ducks/requests/selectors'
import { SEARCH_TAGS_DEFAULT_QUERY } from './constants'
import { FETCH_TAGS_BY_IDS_NAMES } from './actionTypes'

export const selectTagsBase = state => state.tags || emptyObj

export const selectCurrentTagIds = state =>
  selectCurrentEntityIds(state, 'tag') || emptyArr

export const selectCurrentTagsById = state =>
  selectCurrentEntitiesById(state, 'tag') || null

export const selectCurrentTagById = (state, tagId, isLoadedFn) =>
  selectCurrentEntityById(state, 'tag', tagId, isLoadedFn) || null

export const selectCurrentTagsByIds = (state, tagIds) =>
  selectCurrentEntitiesByIds(state, 'tag', tagIds) || emptyArr

export const selectPendingTagById = (state, id) =>
  selectPendingEntityById(state, 'tag', id) || null

export const selectCurrentTags = state =>
  selectCurrentEntitiesSortedBy(state, 'tag', 'name') || emptyArr

export const selectCurrentTagsByName = createSelector(selectCurrentTags, tags =>
  tags.reduce((tagsByName, tag) => {
    // eslint-disable-next-line no-param-reassign
    tagsByName[tag.name] = tag
    return tagsByName
  }, {})
)

// exact match
export const selectCurrentTagByName = createCachedSelector(
  selectCurrentTags,
  (_, name) => name,
  (tags, name) => {
    if (!name) return null
    return tags.find(t => t.name === name) || null
  }
)((_, name) => name || 'unknown')

export const selectSearchesByQueriesForTagsSearch = createBasicSelector(state =>
  selectSearchesByQueriesForPartialQuery(state, SEARCH_TAGS_DEFAULT_QUERY)
)

export const selectSearchesByQueriesForTags = createBasicSelector(state =>
  selectSearchesByQueriesForPartialQuery(state, 'entityType:tag')
)

export const selectTagsBySearch = createSelector(
  selectSearchesByQueriesForTagsSearch,
  selectCurrentTagsById,
  (searchesById, tagsById) => {
    const result = Object.keys(searchesById).reduce((bySearch, queryId) => {
      const query = queryIdToQuery(queryId)
      if (query.search) {
        // eslint-disable-next-line no-param-reassign
        bySearch[query.search] = searchesById[queryId].entityIds.map(
          eid => tagsById[eid]
        )
      }
      return bySearch
    }, {})

    return result
  }
)

export const selectTagsByMailboxId = createSelector(
  selectSearchesByQueriesForTags,
  selectCurrentTagsById,
  (searchesById, tagsById) => {
    const result = Object.keys(searchesById).reduce((bySearch, queryId) => {
      const query = queryIdToQuery(queryId)
      if (query.channel) {
        // eslint-disable-next-line no-param-reassign
        bySearch[query.search] = searchesById[queryId].entityIds.map(
          eid => tagsById[eid]
        )
      }
      return bySearch
    }, {})

    return result
  }
)

export const selectRequestFetchTagsV2ByIdNames = createBasicSelector(state =>
  selectRequestByType(state, FETCH_TAGS_BY_IDS_NAMES)
)
