/* eslint-disable no-param-reassign */
import createCachedSelector from 're-reselect'
import { selectCurrentAgentsById } from 'selectors/agents/base'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { without } from 'util/arrays'
import { selectReactionsByReactionForEventGroupId } from './selectReactionsByReactionForEventGroupId'

const OWN_REACTION_PRONOUN = 'You'

export const selectReactionTitlesByReactionForEventGroup = createCachedSelector(
  selectReactionsByReactionForEventGroupId,
  selectCurrentAgentsById,
  selectCurrentUserGlobalId,
  (reactionsByReaction, agentsById, currentUserId) => {
    return Object.keys(reactionsByReaction).reduce((byReaction, reaction) => {
      const reactions = reactionsByReaction[reaction]

      const userNameList = reactions.map(action => {
        if (action.actor.id === currentUserId) {
          return OWN_REACTION_PRONOUN
        }

        return agentsById[action.actor.id].name
      })

      if (userNameList.indexOf(OWN_REACTION_PRONOUN) > -1) {
        without(userNameList, OWN_REACTION_PRONOUN)
        userNameList.unshift(OWN_REACTION_PRONOUN)
      }

      byReaction[reaction] = userNameList.join(', ')

      return byReaction
    }, {})
  }
)((_state, groupId) => groupId || 'unknown')
