import { flatten, uniq } from 'util/arrays'
import { createSelector } from 'reselect'
import { selectConversationsBySelectedTicketIds } from './selectConversationsBySelectedTicketIds'

export const selectTagsWithIntermediateBySelectedTicketIds = createSelector(
  selectConversationsBySelectedTicketIds,
  conversations => {
    const tagIds = uniq(flatten(conversations.map(c => c.tags)))

    return tagIds.map(tagId => ({
      id: tagId,
      state: conversations.some(c => !c.tags.includes(tagId)) ? null : true,
    }))
  }
)
