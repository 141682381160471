import { SAVE_LAST_SNOOZED_DATE } from 'constants/action_types'

export const doSaveLastSnoozedDate = lastSnoozedDate => {
  return {
    type: SAVE_LAST_SNOOZED_DATE,
    data: {
      lastSnoozedDate,
    },
  }
}
