import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'
import { emptyArr, compact } from 'util/arrays'
import { selectRoomFromUrl } from 'ducks/chat/selectors/rooms'
import { selectWidgetFromUrl } from 'ducks/widgets/selectors/widgets'
import { selectCurrentFoldersById } from 'ducks/folders/selectors/folders'
import selectFolderItems from 'ducks/folders/selectors/items/selectItems'
import { matchFilter, normalizeConversation } from 'ducks/searches/utils/diff'
import {
  selectCurrentEntitiesStore,
  selectCurrentEntitiesNormalizrStoreByEntityType,
} from 'ducks/entities/selectors'
import { denormalize } from 'normalizr'
import entitySchemas from 'ducks/entities/schema'
import { selectIsInChat } from 'selectors/location'
import { selectRequestByType } from 'ducks/requests/selectors'
import {
  normalizeSearchQueryId,
  queryIdToQuery,
  removeKeysFromQueryId,
  toBaseQueryId,
  toFilterQueryId,
} from '../utils/query'
import { REFRESH_SEARCHES, SEARCH_CONVERSATIONS } from '../actionTypes'
import { selectCurrentQuery } from './selectCurrentQuery'
import { selectCurrentQueryId } from './selectCurrentQueryId'
import { selectBase } from './selectBase'
import { selectDefaultSortOrderByQueryId } from './selectDefaultSortOrderByQueryId'

export const selectByQueryId = createBasicSelector(
  selectBase,
  base => base.byQueryId
)

export const selectCurrentBaseQueryId = createSelector(
  selectCurrentQueryId,
  queryId => toBaseQueryId(queryId)
)

export const selectQueryByQueryId = createBasicSelector(
  (_state, queryId) => queryIdToQuery(queryId) || emptyObj
)

export const selectCurrentFilterQuery = createSelector(
  selectCurrentQueryId,
  queryId => queryIdToQuery(toFilterQueryId(queryId)) || emptyObj
)

export const selectCurrentQueryOrderBy = createBasicSelector(
  selectCurrentQuery,
  query => query.orderBy
)

export const selectDefaultSortOrderByCurrentQueryId = createBasicSelector(
  selectCurrentQueryId,
  state => state,
  (queryId, state) => selectDefaultSortOrderByQueryId(state, queryId)
)

export const selectCurrentQueryOrderByOrDefault = createBasicSelector(
  selectCurrentQueryOrderBy,
  selectDefaultSortOrderByCurrentQueryId,
  selectIsInChat,
  (orderBy, defaultOrderBy, isInChat) =>
    orderBy ||
    (isInChat ? 'LAST_USER_OR_AGENT_MESSAGE_AT_DESC' : defaultOrderBy)
)

export const selectLastUpdatedAt = createBasicSelector(
  selectBase,
  base => base.lastUpdatedAt
)

export function selectQueryIdFromProps(_, props) {
  return props.queryId || null
}

function selectQueryId(_state, queryId) {
  return normalizeSearchQueryId(removeKeysFromQueryId(['cursor'], queryId))
}

export const selectSearchByQueryId = createBasicSelector(
  selectByQueryId,
  selectQueryId,
  (byQueryId, queryId) => {
    return byQueryId[queryId] || emptyObj
  }
)

export const selectSearchesByQueriesForPartialQuery = createCachedSelector(
  selectByQueryId,
  selectQueryId,
  (byQueryId, partialQueryId) => {
    return Object.keys(byQueryId).reduce((partialByQueryId, queryId) => {
      if (queryId.includes(partialQueryId)) {
        // eslint-disable-next-line no-param-reassign
        partialByQueryId[queryId] = byQueryId[queryId]
      }
      return partialByQueryId
    }, {})
  }
)(selectQueryId)

export const selectSearchFilterByQueryId = createBasicSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.filter || emptyObj
)

export const selectSearchIsLoadingByQueryId = createBasicSelector(
  selectSearchByQueryId,
  searchByQueryId => !!searchByQueryId.loading
)

export const selectSearchIsLoadedByQueryId = createBasicSelector(
  selectSearchByQueryId,
  searchByQueryId => !!searchByQueryId.loaded
)

export const selectSearchCurrentPageCursorByQueryId = createBasicSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.currentPageCursor || null
)

export const selectSearchNextPageCursorByQueryId = createBasicSelector(
  selectSearchByQueryId,
  searchByQueryId => {
    return searchByQueryId.nextPageCursor || null
  }
)

export const selectSearchHasMoreByQueryId = createBasicSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.hasAllPages !== true
)

export const selectSearchEntityIdsByQueryId = createBasicSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.entityIds || emptyArr
)

export const selectSearchEntitiesByQueryId = createCachedSelector(
  selectSearchByQueryId,
  selectCurrentEntitiesStore,
  (searchByQueryId, entitiesStore) => {
    const { entityType } = searchByQueryId.filter || {}
    if (!entityType) return emptyArr
    if (!entitiesStore[entityType]) return emptyArr
    const entityIds = searchByQueryId.entityIds || emptyArr
    const entityById = entitiesStore[entityType].byId

    return entityIds.map(entityId => entityById[entityId]).filter(e => !!e)
  }
)(selectQueryId)

export const selectSearchEntitiesDenormalizedByQueryId = createCachedSelector(
  selectSearchByQueryId,
  (state, queryId) => {
    const { entityType } = selectSearchByQueryId(state, queryId).filter || {}
    if (!entityType) return emptyObj
    return selectCurrentEntitiesNormalizrStoreByEntityType(
      state,
      'current',
      entityType
    )
  },
  (searchByQueryId, currentEntitiesNormalizrStore) => {
    const { entityType } = searchByQueryId.filter || {}
    if (!entityType) return emptyArr
    if (!currentEntitiesNormalizrStore[entityType]) return emptyArr
    const entityIds = searchByQueryId.entityIds || emptyArr

    return compact(
      denormalize(
        entityIds,
        [entitySchemas[entityType]],
        currentEntitiesNormalizrStore
      )
    )
  }
)(selectQueryId)

export const selectSearchCursorByQueryIdAndCursor = createBasicSelector(
  selectSearchByQueryId,
  (_state, _queryId, cursor) => cursor,
  (searchByQueryId, cursor) =>
    (searchByQueryId.cursors ? searchByQueryId.cursors[cursor] : emptyObj) ||
    emptyObj
)

export const selectSearchEntityIdsByQueryIdAndCursor = createBasicSelector(
  selectSearchCursorByQueryIdAndCursor,
  searchCursor => searchCursor.entityIds || emptyArr
)

const queryIdAndCursor = (_state, queryId, cursor) => `${queryId}-${cursor}`
export const selectSearchEntitiesByQueryIdAndCursor = createCachedSelector(
  selectSearchByQueryId,
  selectSearchEntityIdsByQueryIdAndCursor,
  selectCurrentEntitiesStore,
  (searchByQueryId, searchEntityIds, entitiesStore) => {
    const { entityType } = searchByQueryId.filter || {}
    if (!entityType) return emptyArr
    if (!entitiesStore[entityType]) return emptyArr
    const entityById = entitiesStore[entityType].byId

    return searchEntityIds
      .map(entityId => entityById[entityId])
      .filter(e => !!e)
  }
)(queryIdAndCursor)

export const selectSearchEntitiesDenormalizedByQueryIdAndCursor = createCachedSelector(
  selectSearchByQueryId,
  selectSearchEntityIdsByQueryIdAndCursor,
  (state, queryId) => {
    const { entityType } = selectSearchByQueryId(state, queryId).filter || {}
    if (!entityType) return emptyObj
    return selectCurrentEntitiesNormalizrStoreByEntityType(
      state,
      'current',
      entityType
    )
  },
  (searchByQueryId, searchEntityIds, currentEntitiesNormalizrStore) => {
    const { entityType } = searchByQueryId.filter || {}
    if (!entityType) return emptyArr
    if (!currentEntitiesNormalizrStore[entityType]) return emptyArr

    return compact(
      denormalize(
        searchEntityIds,
        [entitySchemas[entityType]],
        currentEntitiesNormalizrStore
      )
    )
  }
)(queryIdAndCursor)

export const selectSearchIsLoadingMoreByQueryId = createBasicSelector(
  selectSearchIsLoadingByQueryId,
  selectSearchIsLoadedByQueryId,
  (isLoading, isLoaded) => isLoading && isLoaded
)

export const selectCurrentSearchIsError = createSelector(
  selectByQueryId,
  selectCurrentQueryId,
  (byQueryId, queryId) => !!(byQueryId[queryId] || emptyObj).error
)

export const selectSearchIsErrorByQueryId = createBasicSelector(
  selectSearchByQueryId,
  searchByQueryId => !!searchByQueryId.errored
)

export const selectEntityCountByQueryId = createBasicSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.entityCount || 0
)

export const selectConversationUnreadCountByQueryId = createCachedSelector(
  selectByQueryId,
  selectQueryId,
  selectRoomFromUrl,
  selectCurrentFoldersById,
  selectCurrentEntitiesStore,
  (searchesByQueryId, queryId, roomFromUrl, foldersById, entitiesStore) => {
    if (!queryId.includes('folder:')) return null

    const unreadQueryId = queryId.replace('folder:', 'folderunread:')
    const { entityCount = 0 } = searchesByQueryId[unreadQueryId] || emptyObj
    const isRoomOpen = !!roomFromUrl.id

    // While a conversation is open, we want to always regard is as "read"
    if (
      entityCount > 0 &&
      isRoomOpen &&
      !roomFromUrl.isRead &&
      !roomFromUrl.ignoreNextClose
    ) {
      const { folder: folderId } = queryIdToQuery(queryId) || {}
      const folder = foldersById[folderId]
      if (folder) {
        const isInFolder = matchFilter(
          folder,
          normalizeConversation(roomFromUrl, entitiesStore),
          entitiesStore
        )
        if (isInFolder) return entityCount - 1
      }
    }

    return entityCount
  }
)(selectQueryId)

export const selectUnreadByChannelType = createSelector(
  selectFolderItems,
  selectRoomFromUrl,
  selectByQueryId,
  selectCurrentFoldersById,
  selectCurrentEntitiesStore,
  (folderItems, roomFromUrl, searchesByQueryId, foldersById, entitiesStore) => {
    const results = folderItems
      .filter(f => f.displayUnreadIndicator)
      .reduce((unreadByChannelType, folderItem) => {
        const { queryId } = folderItem
        const unreadQueryId = queryId.replace('folder:', 'folderunread:')
        let { entityCount = 0 } = searchesByQueryId[unreadQueryId] || emptyObj
        const { folder: folderId, type } = queryIdToQuery(queryId) || {}

        const isRoomOpen = !!roomFromUrl.id
        if (
          entityCount > 0 &&
          isRoomOpen &&
          !roomFromUrl.isRead &&
          !roomFromUrl.ignoreNextClose
        ) {
          const folder = foldersById[folderId]
          if (folder) {
            const isInFolder = matchFilter(
              folder,
              normalizeConversation(roomFromUrl, entitiesStore),
              entitiesStore
            )
            if (isInFolder) entityCount -= 1
          }
        }
        if (unreadByChannelType[type] === undefined) {
          Object.assign(unreadByChannelType, { [type]: false })
        }
        Object.assign(unreadByChannelType, {
          [type]: unreadByChannelType[type] || entityCount > 0,
        })
        return unreadByChannelType
      }, {})
    return results
  }
)

export const selectSortKeyForAgent = () => 'LAST_USER_OR_AGENT_MESSAGE_AT'

const hackAgentSortOptions = [
  {
    id: 'newest',
    name: 'Newest',
    selectedOptionText: 'Newest first',
    orderBy: 'LAST_USER_OR_AGENT_MESSAGE_AT_DESC',
  },
  {
    id: 'oldest',
    name: 'Oldest',
    selectedOptionText: 'Oldest first',
    orderBy: 'LAST_USER_OR_AGENT_MESSAGE_AT_ASC',
  },
  {
    id: 'longestWaiting',
    name: 'Waiting Longest',
    selectedOptionText: 'Waiting Longest',
    orderBy: 'LAST_UNANSWERED_USER_MESSAGE_AT_ASC',
  },
]

export const selectAgentSortOptions = createSelector(
  selectWidgetFromUrl,
  widget => {
    return widget?.kind !== 'facebook'
      ? hackAgentSortOptions
      : [
          {
            id: 'expiringSoon',
            name: 'Expiring soonest',
            selectedOptionText: 'Expiring soonest',
            orderBy: 'EXPIRES_AT_ASC',
          },
          ...hackAgentSortOptions,
        ]
  }
)

export const selectSortOptions = createSelector(
  selectAgentSortOptions,
  selectCurrentQueryOrderBy,
  (agentSortOptions, currentOrderBy) => {
    return agentSortOptions.map(
      ({ id, name, orderBy, selectedOptionText }) => ({
        active: currentOrderBy === orderBy,
        key: id,
        text: name,
        selectedOptionText,
        value: orderBy,
      })
    )
  }
)

export const selectSearchEntityIndexByQueryIdAndEntityId = createBasicSelector(
  selectSearchEntityIdsByQueryId,
  (_state, _queryId, entityId) => entityId,
  (ids, entityId) => {
    if (!ids) return null
    if (!entityId) return null
    return ids.indexOf(entityId)
  }
)

export const selectSearchNextEntityIdByQueryIdAndEntityId = createBasicSelector(
  selectSearchEntityIdsByQueryId,
  (_state, _queryId, entityId) => entityId,
  (ids, entityId) => {
    if (!ids) return null
    if (!entityId) return null
    const currentEntityIndex = ids.indexOf(entityId)
    return ids[currentEntityIndex + 1]
  }
)

export const selectSearchNextEntityIdByForCurrentQueryIdAndTicketId = createBasicSelector(
  selectCurrentQueryId,
  state => state,
  (_state, ticketId) => ticketId,
  (currentQueryId, state, ticketId) =>
    selectSearchNextEntityIdByQueryIdAndEntityId(
      state,
      currentQueryId,
      ticketId
    )
)

export const selectSearchPreviousEntityIdByQueryIdAndEntityId = createBasicSelector(
  selectSearchEntityIdsByQueryId,
  (_state, _queryId, entityId) => entityId,
  (ids, entityId) => {
    if (!ids) return null
    if (!entityId) return null
    const currentEntityIndex = ids.indexOf(entityId)
    return ids[currentEntityIndex - 1]
  }
)

export const selectIsRefreshingSearches = createBasicSelector(
  state => selectRequestByType(state, REFRESH_SEARCHES).loading
)

export const selectSearchConversationsRequest = createBasicSelector(state =>
  selectRequestByType(state, SEARCH_CONVERSATIONS)
)

// Intentionally using a basic selector here so that the caching is handled by
// re-reselect selectSearchTotalCountByQueryId
export const selectSearchTotalCountForCurrentQueryId = createBasicSelector(
  selectCurrentQueryId,
  state => state,
  (currentQueryId, state) => {
    return selectEntityCountByQueryId(state, currentQueryId)
  }
)

export const selectSearchEntityIdsByCurrentQueryId = createBasicSelector(
  selectCurrentQueryId,
  state => state,
  (queryId, state) => selectSearchEntityIdsByQueryId(state, queryId)
)
