import { createBasicSelector } from 'util/redux'
import { selectIsOnSearchPage } from 'selectors/location'
import { queryIdToQuery, toFilterQueryId } from '../utils/query'

export const selectSearchQueryStringByQueryId = createBasicSelector(
  (_state, queryId) => toFilterQueryId(queryId),
  selectIsOnSearchPage,
  (queryId, isSearchPage) => {
    if (!isSearchPage || !queryId) return null
    return queryIdToQuery(queryId).search
  }
)
