import createCachedSelector from 're-reselect'
import { selectTicketEventGroupsByConversationId } from './selectTicketEventGroupsByConversationId'
import { selectMessagesById } from '.'

export const selectMessagesByConversationId = createCachedSelector(
  selectTicketEventGroupsByConversationId,
  selectMessagesById,
  (eventGroups, messagesById) => {
    return eventGroups.map(eg => messagesById[eg.summary])
  }
)((_state, conversationId) => conversationId || 'unknown')
