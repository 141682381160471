import createCachedSelector from 're-reselect'
import { createBasicSelector } from 'util/redux'
import {
  selectCurrentEntitiesById,
  selectPendingEntityById,
  selectPendingEntitiesById,
  selectCurrentEntitiesSortedBy,
  selectCurrentEntityById,
} from 'ducks/entities/selectors'
import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { selectBase } from './selectBase'

const selectRule = createBasicSelector(
  selectBase,
  base => base.rule || emptyObj
)

export const selectIsLoadingRule = createBasicSelector(
  selectRule,
  rule => rule.isLoading
)

export const selectHasLoadedRule = createBasicSelector(
  selectRule,
  rule => rule.isLoaded
)

export const selectHasErrorRule = createBasicSelector(
  selectRule,
  rule => rule.hasError
)

export const selectCurrentRulesById = state =>
  selectCurrentEntitiesById(state, 'rule') || null

export const selectCurrentRuleById = (state, id) =>
  selectCurrentEntityById(state, 'rule', id) || null

export const selectPendingRuleForId = (state, id) =>
  selectPendingEntityById(state, 'rule', id) || null

export const selectPendingRuleActionMessageTemplateById = (state, id) =>
  selectPendingEntityById(state, 'ruleReplyTemplate', id) || null

export const selectPendingRuleActionMessageTemplatesById = state =>
  selectPendingEntitiesById(state, 'ruleReplyTemplate')

export const selectPendingRuleActionById = (state, id) =>
  selectPendingEntityById(state, 'ruleAction', id) || null

const selectPendingRuleActionsById = state =>
  selectPendingEntitiesById(state, 'ruleAction')

export const selectPendingRuleActionsByIds = createCachedSelector(
  selectPendingRuleActionsById,
  (_state, ids) => ids,
  (ruleActionsById, ids = []) => {
    const entities = []

    ids.forEach(id => {
      const entity = ruleActionsById[id]
      if (entity) {
        entities.push(entity)
      }
    })

    return entities
  }
)((_state, ids) => (ids || []).join(','))

export const selectPendingRuleConditionById = (state, id) =>
  selectPendingEntityById(state, 'ruleCondition', id) || null

const selectPendingRuleConditionsById = state =>
  selectPendingEntitiesById(state, 'ruleCondition')

const selectPendingRuleTriggersById = state =>
  selectPendingEntitiesById(state, 'ruleTrigger')

export const selectPendingRuleConditionsByIds = createCachedSelector(
  selectPendingRuleConditionsById,
  (_state, ids) => ids,
  (ruleConditionsById, ids = []) => {
    const entities = []

    ids.forEach(id => {
      const entity = ruleConditionsById[id]
      if (entity) {
        entities.push(entity)
      }
    })

    return entities
  }
)((_state, ids) => (ids || []).join(','))

export const selectPendingRuleTriggersByIds = createCachedSelector(
  selectPendingRuleTriggersById,
  (_state, ids) => ids,
  (ruleTriggersById, ids = []) => {
    const entities = []

    ids.forEach(id => {
      const entity = ruleTriggersById[id]
      if (entity) {
        entities.push(entity)
      }
    })

    return entities
  }
)((_state, ids) => (ids || []).join(','))

export const selectCurrentRules = state =>
  selectCurrentEntitiesSortedBy(state, 'rule', 'row_order', 'asc') || emptyArr
