import { rule } from 'ducks/entities/schema'

export const rulesResponseSchema = {
  rules: {
    edges: [{ node: rule }],
  },
}

export const ruleCreateSchema = {
  ruleCreate: {
    rule,
  },
}

export const ruleUpdateSchema = {
  ruleUpdate: {
    rule,
  },
}

export const ruleFetchSchema = {
  node: rule,
}
