import { createSelector } from 'reselect'
import { selectCurrentPath, selectQueryParams } from 'selectors/location'

export const selectCurrentTicketId = createSelector(
  selectCurrentPath,
  selectQueryParams,
  (path, params) => {
    if (!path) return null
    if (path.match('/tickets/')) {
      if (path.match(/\/tickets\/new/)) return 'new'
      if (path.match(/\/tickets\/log-conversation/)) return 'new'
      return path.match(/\/tickets\/(\w*)/)[1]
    }
    if (path.match('/search/')) {
      return path.match(/\/search\/(\w*)/)[1]
    }
    if (params && params.conversationId) return params.conversationId
    return null
  }
)
