import createCachedSelector from 're-reselect'
import { CHANGE_TYPE_RATING } from '../constants'
import { selectConversationEventsByGroupId } from '.'

export const selectRatingEventForGroupId = createCachedSelector(
  selectConversationEventsByGroupId,
  events => {
    // eslint-disable-next-line no-underscore-dangle
    return events.find(e => e.change?.__typename === CHANGE_TYPE_RATING)
  }
)((_state, groupId) => groupId || 'unknown')
