import { conversation, tag } from 'ducks/entities/schema'

export const conversationsGraphQlResponseSchema = {
  conversations: { nodes: [conversation] },
}

export const conversationGraphQlResponseSchema = { node: conversation }

export const ticketConversationCountQueryGraphQlResponseSchema = {
  tags: {
    nodes: [tag],
  },
}

export const ticketConversationCountForChannelQueryQueryGraphQlResponseSchema = {
  node: {
    tags: {
      nodes: [tag],
    },
  },
}
