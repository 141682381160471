export const FETCH_CONVERSATION = 'tickets/FETCH_CONVERSATION'
export const FETCH_CONVERSATION_STARTED = 'tickets/FETCH_CONVERSATION_STARTED'
export const FETCH_CONVERSATION_SUCCESS = 'tickets/FETCH_CONVERSATION_SUCCESS'
export const FETCH_CONVERSATION_FAILED = 'tickets/FETCH_CONVERSATION_FAILED'

export const PRELOAD_CONVERSATIONS = 'tickets/PRELOAD_CONVERSATIONS'
export const PRELOAD_CONVERSATIONS_STARTED =
  'tickets/PRELOAD_CONVERSATIONS_STARTED'
export const PRELOAD_CONVERSATIONS_SUCCESS =
  'tickets/PRELOAD_CONVERSATIONS_SUCCESS'
export const PRELOAD_CONVERSATIONS_FAILED =
  'tickets/PRELOAD_CONVERSATIONS_FAILED'

export const FETCH_CONVERSATION_EVENT_GROUPS =
  'tickets/FETCH_CONVERSATION_EVENT_GROUPS'
export const FETCH_CONVERSATION_EVENT_GROUPS_STARTED =
  'tickets/FETCH_CONVERSATION_EVENT_GROUPS_STARTED'
export const FETCH_CONVERSATION_EVENT_GROUPS_SUCCESS =
  'tickets/FETCH_CONVERSATION_EVENT_GROUPS_SUCCESS'
export const FETCH_CONVERSATION_EVENT_GROUPS_FAILED =
  'tickets/FETCH_CONVERSATION_EVENT_GROUPS_FAILED'

export const FETCH_CONVERSATION_EVENTS_BY_GROUP_ID =
  'tickets/FETCH_CONVERSATION_EVENTS_BY_GROUP_ID'
export const FETCH_CONVERSATION_EVENTS_BY_GROUP_ID_STARTED =
  'tickets/FETCH_CONVERSATION_EVENTS_BY_GROUP_ID_STARTED'
export const FETCH_CONVERSATION_EVENTS_BY_GROUP_ID_SUCCESS =
  'tickets/FETCH_CONVERSATION_EVENTS_BY_GROUP_ID_SUCCESS'
export const FETCH_CONVERSATION_EVENTS_BY_GROUP_ID_FAILED =
  'tickets/FETCH_CONVERSATION_EVENTS_BY_GROUP_ID_FAILED'

export const UPDATE_CONVERSATION_TITLE = 'tickets/UPDATE_CONVERSATION_TITLE'
export const UPDATE_CONVERSATION_TITLE_STARTED =
  'tickets/UPDATE_CONVERSATION_TITLE_STARTED'
export const UPDATE_CONVERSATION_TITLE_SUCCESS =
  'tickets/UPDATE_CONVERSATION_TITLE_SUCCESS'
export const UPDATE_CONVERSATION_TITLE_FAILED =
  'tickets/UPDATE_CONVERSATION_TITLE_FAILED'

export const UPDATE_CONVERSATION_ADD_TAGS =
  'tickets/UPDATE_CONVERSATION_ADD_TAGS'
export const UPDATE_CONVERSATION_ADD_TAGS_STARTED =
  'tickets/UPDATE_CONVERSATION_ADD_TAGS_STARTED'
export const UPDATE_CONVERSATION_ADD_TAGS_SUCCESS =
  'tickets/UPDATE_CONVERSATION_ADD_TAGS_SUCCESS'
export const UPDATE_CONVERSATION_ADD_TAGS_FAILED =
  'tickets/UPDATE_CONVERSATION_ADD_TAGS_FAILED'

export const UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION =
  'tickets/UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION'
export const UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION_STARTED =
  'tickets/UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION_STARTED'
export const UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION_SUCCESS =
  'tickets/UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION_SUCCESS'
export const UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION_FAILED =
  'tickets/UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION_FAILED'

export const UPDATE_CONVERSATION_REMOVE_TAGS =
  'tickets/UPDATE_CONVERSATION_REMOVE_TAGS'
export const UPDATE_CONVERSATION_REMOVE_TAGS_STARTED =
  'tickets/UPDATE_CONVERSATION_REMOVE_TAGS_STARTED'
export const UPDATE_CONVERSATION_REMOVE_TAGS_SUCCESS =
  'tickets/UPDATE_CONVERSATION_REMOVE_TAGS_SUCCESS'
export const UPDATE_CONVERSATION_REMOVE_TAGS_FAILED =
  'tickets/UPDATE_CONVERSATION_REMOVE_TAGS_FAILED'

export const UPDATE_CONVERSATION_TAGS = 'tickets/UPDATE_CONVERSATION_TAGS'
export const UPDATE_CONVERSATION_TAGS_STARTED =
  'tickets/UPDATE_CONVERSATION_TAGS_STARTED'
export const UPDATE_CONVERSATION_TAGS_SUCCESS =
  'tickets/UPDATE_CONVERSATION_TAGS_SUCCESS'
export const UPDATE_CONVERSATION_TAGS_FAILED =
  'tickets/UPDATE_CONVERSATION_TAGS_FAILED'

export const UPDATE_CONVERSATION_STAR = 'tickets/UPDATE_CONVERSATION_STAR'
export const UPDATE_CONVERSATION_STAR_STARTED =
  'tickets/UPDATE_CONVERSATION_STAR_STARTED'
export const UPDATE_CONVERSATION_STAR_SUCCESS =
  'tickets/UPDATE_CONVERSATION_STAR_SUCCESS'
export const UPDATE_CONVERSATION_STAR_FAILED =
  'tickets/UPDATE_CONVERSATION_STAR_FAILED'

export const UPDATE_CONVERSATION_UNSTAR = 'tickets/UPDATE_CONVERSATION_UNSTAR'
export const UPDATE_CONVERSATION_UNSTAR_STARTED =
  'tickets/UPDATE_CONVERSATION_UNSTAR_STARTED'
export const UPDATE_CONVERSATION_UNSTAR_SUCCESS =
  'tickets/UPDATE_CONVERSATION_UNSTAR_SUCCESS'
export const UPDATE_CONVERSATION_UNSTAR_FAILED =
  'tickets/UPDATE_CONVERSATION_UNSTAR_FAILED'

export const UPDATE_CONVERSATION_STATE = 'tickets/UPDATE_CONVERSATION_STATE'
export const UPDATE_CONVERSATION_STATE_STARTED =
  'tickets/UPDATE_CONVERSATION_STATE_STARTED'
export const UPDATE_CONVERSATION_STATE_SUCCESS =
  'tickets/UPDATE_CONVERSATION_STATE_SUCCESS'
export const UPDATE_CONVERSATION_STATE_FAILED =
  'tickets/UPDATE_CONVERSATION_STATE_FAILED'

export const UPDATE_CONVERSATION_STATE_SNOOZE =
  'tickets/UPDATE_CONVERSATION_STATE_SNOOZE'
export const UPDATE_CONVERSATION_STATE_SNOOZE_STARTED =
  'tickets/UPDATE_CONVERSATION_STATE_SNOOZE_STARTED'
export const UPDATE_CONVERSATION_STATE_SNOOZE_SUCCESS =
  'tickets/UPDATE_CONVERSATION_STATE_SNOOZE_SUCCESS'
export const UPDATE_CONVERSATION_STATE_SNOOZE_FAILED =
  'tickets/UPDATE_CONVERSATION_STATE_SNOOZE_FAILED'

export const DELETE_CONVERSATION = 'tickets/DELETE_CONVERSATION'
export const DELETE_CONVERSATION_STARTED = 'tickets/DELETE_CONVERSATION_STARTED'
export const DELETE_CONVERSATION_SUCCESS = 'tickets/DELETE_CONVERSATION_SUCCESS'
export const DELETE_CONVERSATION_FAILED = 'tickets/DELETE_CONVERSATION_FAILED'
export const DELETE_CONVERSATION_REALTIME =
  'tickets/DELETE_CONVERSATION_REALTIME'

export const DELETE_CONVERSATION_MESSAGE = 'tickets/DELETE_CONVERSATION_MESSAGE'
export const DELETE_CONVERSATION_MESSAGE_STARTED =
  'tickets/DELETE_CONVERSATION_MESSAGE_STARTED'
export const DELETE_CONVERSATION_MESSAGE_SUCCESS =
  'tickets/DELETE_CONVERSATION_MESSAGE_SUCCESS'
export const DELETE_CONVERSATION_MESSAGE_FAILED =
  'tickets/DELETE_CONVERSATION_MESSAGE_FAILED'

export const UPDATE_CONVERSATION_FOLLOW = 'tickets/UPDATE_CONVERSATION_FOLLOW'
export const UPDATE_CONVERSATION_FOLLOW_STARTED =
  'tickets/UPDATE_CONVERSATION_FOLLOW_STARTED'
export const UPDATE_CONVERSATION_FOLLOW_SUCCESS =
  'tickets/UPDATE_CONVERSATION_FOLLOW_SUCCESS'
export const UPDATE_CONVERSATION_FOLLOW_FAILED =
  'tickets/UPDATE_CONVERSATION_FOLLOW_FAILED'

export const UPDATE_CONVERSATION_UNFOLLOW =
  'tickets/UPDATE_CONVERSATION_UNFOLLOW'
export const UPDATE_CONVERSATION_UNFOLLOW_STARTED =
  'tickets/UPDATE_CONVERSATION_UNFOLLOW_STARTED'
export const UPDATE_CONVERSATION_UNFOLLOW_SUCCESS =
  'tickets/UPDATE_CONVERSATION_UNFOLLOW_SUCCESS'
export const UPDATE_CONVERSATION_UNFOLLOW_FAILED =
  'tickets/UPDATE_CONVERSATION_UNFOLLOW_FAILED'

export const UPDATE_CONVERSATION_CHANNEL = 'tickets/UPDATE_CONVERSATION_CHANNEL'
export const UPDATE_CONVERSATION_CHANNEL_STARTED =
  'tickets/UPDATE_CONVERSATION_CHANNEL_STARTED'
export const UPDATE_CONVERSATION_CHANNEL_SUCCESS =
  'tickets/UPDATE_CONVERSATION_CHANNEL_SUCCESS'
export const UPDATE_CONVERSATION_CHANNEL_FAILED =
  'tickets/UPDATE_CONVERSATION_CHANNEL_FAILED'

export const BULK_UPDATE_CONVERSATIONS = 'tickets/BULK_UPDATE_CONVERSATIONS'
export const BULK_UPDATE_CONVERSATIONS_STARTED =
  'tickets/BULK_UPDATE_CONVERSATIONS_STARTED'
export const BULK_UPDATE_CONVERSATIONS_SUCCESS =
  'tickets/BULK_UPDATE_CONVERSATIONS_SUCCESS'
export const BULK_UPDATE_CONVERSATIONS_FAILED =
  'tickets/BULK_UPDATE_CONVERSATIONS_FAILED'

export const MERGE_CONVERSATION = 'tickets/MERGE_CONVERSATION'
export const MERGE_CONVERSATION_STARTED = 'tickets/MERGE_CONVERSATION_STARTED'
export const MERGE_CONVERSATION_SUCCESS = 'tickets/MERGE_CONVERSATION_SUCCESS'
export const MERGE_CONVERSATION_FAILED = 'tickets/MERGE_CONVERSATION_FAILED'

export const MERGE_AND_RELOAD_CONVERSATION =
  'tickets/MERGE_AND_RELOAD_CONVERSATION'
export const MERGE_AND_RELOAD_CONVERSATION_STARTED =
  'tickets/MERGE_AND_RELOAD_CONVERSATION_STARTED'
export const MERGE_AND_RELOAD_CONVERSATION_SUCCESS =
  'tickets/MERGE_AND_RELOAD_CONVERSATION_SUCCESS'
export const MERGE_AND_RELOAD_CONVERSATION_FAILED =
  'tickets/MERGE_AND_RELOAD_CONVERSATION_FAILED'

export const UPDATE_CONVERSATION_ASSIGNEE =
  'tickets/UPDATE_CONVERSATION_ASSIGNEE'
export const UPDATE_CONVERSATION_ASSIGNEE_STARTED =
  'tickets/UPDATE_CONVERSATION_ASSIGNEE_STARTED'
export const UPDATE_CONVERSATION_ASSIGNEE_SUCCESS =
  'tickets/UPDATE_CONVERSATION_ASSIGNEE_SUCCESS'
export const UPDATE_CONVERSATION_ASSIGNEE_FAILED =
  'tickets/UPDATE_CONVERSATION_ASSIGNEE_FAILED'

export const UPDATE_CONVERSATION_UNASSIGN =
  'tickets/UPDATE_CONVERSATION_UNASSIGN'
export const UPDATE_CONVERSATION_UNASSIGN_STARTED =
  'tickets/UPDATE_CONVERSATION_UNASSIGN_STARTED'
export const UPDATE_CONVERSATION_UNASSIGN_SUCCESS =
  'tickets/UPDATE_CONVERSATION_UNASSIGN_SUCCESS'
export const UPDATE_CONVERSATION_UNASSIGN_FAILED =
  'tickets/UPDATE_CONVERSATION_UNASSIGN_FAILED'

export const CONVERSATION_REPLY = 'tickets/CONVERSATION_REPLY'
export const CONVERSATION_REPLY_STARTED = 'tickets/CONVERSATION_REPLY_STARTED'
export const CONVERSATION_REPLY_SUCCESS = 'tickets/CONVERSATION_REPLY_SUCCESS'
export const CONVERSATION_REPLY_FAILED = 'tickets/CONVERSATION_REPLY_FAILED'

export const CONVERSATION_ADD_NOTE = 'tickets/CONVERSATION_ADD_NOTE'
export const CONVERSATION_ADD_NOTE_STARTED =
  'tickets/CONVERSATION_ADD_NOTE_STARTED'
export const CONVERSATION_ADD_NOTE_SUCCESS =
  'tickets/CONVERSATION_ADD_NOTE_SUCCESS'
export const CONVERSATION_ADD_NOTE_FAILED =
  'tickets/CONVERSATION_ADD_NOTE_FAILED'

export const CONVERSATION_EDIT_NOTE = 'tickets/CONVERSATION_EDIT_NOTE'
export const CONVERSATION_EDIT_NOTE_STARTED =
  'tickets/CONVERSATION_EDIT_NOTE_STARTED'
export const CONVERSATION_EDIT_NOTE_SUCCESS =
  'tickets/CONVERSATION_EDIT_NOTE_SUCCESS'
export const CONVERSATION_EDIT_NOTE_FAILED =
  'tickets/CONVERSATION_EDIT_NOTE_FAILED'

export const CONVERSATION_NEW = 'tickets/CONVERSATION_NEW'
export const CONVERSATION_NEW_STARTED = 'tickets/CONVERSATION_NEW_STARTED'
export const CONVERSATION_NEW_SUCCESS = 'tickets/CONVERSATION_NEW_SUCCESS'
export const CONVERSATION_NEW_FAILED = 'tickets/CONVERSATION_NEW_FAILED'

export const CONVERSATION_LOG = 'tickets/CONVERSATION_LOG'
export const CONVERSATION_LOG_STARTED = 'tickets/CONVERSATION_LOG_STARTED'
export const CONVERSATION_LOG_SUCCESS = 'tickets/CONVERSATION_LOG_SUCCESS'
export const CONVERSATION_LOG_FAILED = 'tickets/CONVERSATION_LOG_FAILED'

export const CONVERSATION_FORWARD = 'tickets/CONVERSATION_FORWARD'
export const CONVERSATION_FORWARD_STARTED =
  'tickets/CONVERSATION_FORWARD_STARTED'
export const CONVERSATION_FORWARD_SUCCESS =
  'tickets/CONVERSATION_FORWARD_SUCCESS'
export const CONVERSATION_FORWARD_FAILED = 'tickets/CONVERSATION_FORWARD_FAILED'

export const CONVERSATION_LINK_RESOURCE = 'tickets/CONVERSATION_LINK_RESOURCE'
export const CONVERSATION_LINK_RESOURCE_STARTED =
  'tickets/CONVERSATION_LINK_RESOURCE_STARTED'
export const CONVERSATION_LINK_RESOURCE_SUCCESS =
  'tickets/CONVERSATION_LINK_RESOURCE_SUCCESS'
export const CONVERSATION_LINK_RESOURCE_FAILED =
  'tickets/CONVERSATION_LINK_RESOURCE_FAILED'
