import {
  CONVERSATION_TYPE_FACEBOOK,
  CONVERSATION_TYPE_TWITTER,
  CONVERSATION_TYPE_WIDGET,
} from '../constants'

export const isTwitter = ticket =>
  // eslint-disable-next-line no-underscore-dangle
  ticket && ticket.__typename === CONVERSATION_TYPE_TWITTER
export const isWidget = ticket =>
  // eslint-disable-next-line no-underscore-dangle
  ticket && ticket.__typename === CONVERSATION_TYPE_WIDGET

export const isFacebook = ticket =>
  // eslint-disable-next-line no-underscore-dangle
  ticket && ticket.__typename === CONVERSATION_TYPE_FACEBOOK
