import { selectLocationState } from 'redux-first-router'
import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'
import { selectCurrentPayload, selectIsInChat } from 'selectors/location'
import { getRawId } from 'util/globalId'
import { selectRoomFromUrl } from 'ducks/chat/selectors/rooms'
import { createBasicSelector } from 'util/redux'
import { selectIsChatBootstrapped } from 'ducks/chat/selectors/bootstrap'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import { selectBase } from './base'

export const selectWidgetsIds = createSelector(
  selectBase,
  base => base.ids || emptyArr
)

export const selectWidgetsById = createSelector(
  selectBase,
  base => base.byId || emptyObj
)

const selectWidgetIdFromParam = (_state, widgetId) => widgetId

export const selectWidgetById = createBasicSelector(
  selectWidgetsById,
  selectWidgetIdFromParam,
  (widgetsById, widgetId) => widgetsById[widgetId]
)

export const selectWidgetColorById = createBasicSelector(
  selectWidgetById,
  widget => widget?.channelColor
)

export const selectWidgetChannelTypeById = createBasicSelector(
  selectWidgetById,
  widget => widget.channelType
)

export const selectWidgets = createSelector(
  selectWidgetsById,
  selectWidgetsIds,
  (byId, ids) =>
    // FIXME: Hiding facebook widgets until we have an overview for them
    ids
      .map(id => byId[id])
      .filter(widget => !isBridgeChannelType(widget.channelType))
)

export const selectWidgetsWithBridged = createSelector(
  selectWidgetsById,
  selectWidgetsIds,
  (byId, ids) => ids.map(id => byId[id])
)

export const selectBridgedWidgets = createSelector(
  selectWidgetsById,
  selectWidgetsIds,
  (byId, ids) =>
    ids
      .map(id => byId[id])
      .filter(widget => isBridgeChannelType(widget.channelType))
)

export const selectHasWidgetsWithoutBridged = createSelector(
  selectWidgets,
  widgets => widgets.length > 0
)

export const selectHasWidgetsWithBridged = createSelector(
  selectWidgetsWithBridged,
  widgets => widgets.length > 0
)

export const selectCurrentWidget = createSelector(
  selectCurrentPayload,
  selectWidgetsById,
  (payload, widgetsById) => widgetsById[payload.id] || emptyObj
)

export const selectIsCurrentWidgetFacebook = createSelector(
  selectCurrentWidget,
  widget => widget?.kind === 'facebook'
)

export const selectIsCurrentWidgetInstagram = createSelector(
  selectCurrentWidget,
  widget => widget?.kind === 'instagram'
)

export const selectIsCurrentWidgetMessenger = createSelector(
  selectIsCurrentWidgetFacebook,
  selectIsCurrentWidgetInstagram,
  (isFacebook, isInstagram) => isFacebook || isInstagram
)

export const selectDisconnectedWidgets = createSelector(
  selectWidgetsById,
  selectWidgetsIds,
  (byId, ids) =>
    ids.map(id => byId[id]).filter(widget => widget.status === 'disconnected')
)

export const selectRecentlyCreatedWidgetId = createSelector(
  selectBase,
  base => base.recentlyCreatedWidgetId
)

export const selectCurrentWidgetId = createSelector(
  selectCurrentPayload,
  payload => payload.id
)

export const selectWidgetsWithChat = createSelector(
  selectWidgetsWithBridged,
  widgets => {
    return widgets.filter(widget => {
      const {
        publishedSettings: { 'chat.enabled': chatEnabled = false },
        channelType,
      } = widget
      return chatEnabled || isBridgeChannelType(channelType)
    })
  }
)

export const selectWidgetsWithLiveChat = createSelector(
  selectWidgetsWithBridged,
  widgets => {
    return widgets.filter(widget => {
      const {
        publishedSettings: { 'chat.enabled': chatEnabled = false },
        channelType,
      } = widget
      return chatEnabled && !isBridgeChannelType(channelType)
    })
  }
)

export const selectWidgetsWithoutChat = createSelector(
  selectWidgets,
  selectWidgetsWithChat,
  (widgets, widgetsWithChat) => {
    return widgets.filter(
      widget => !widgetsWithChat.some(chat => chat.id === widget.id)
    )
  }
)

export const selectIsChatEnabled = createSelector(
  selectWidgetsWithChat,
  widgets => widgets && widgets.length > 0
)

export const selectCurrentWidgetFromChannelId = createSelector(
  selectCurrentPayload,
  selectWidgetsById,
  (payload, widgetsById) => widgetsById[payload.id] || emptyObj
)

export const selectChannelIdFromUrl = createSelector(
  selectLocationState,
  location =>
    location &&
    location.query &&
    location.query.channelId &&
    // Not a big fan of parsing the roomId to integer here, but as far as I can see the location
    // reducer is part of the redux-first-router and I dont have a way to tell it to save this variable
    // as a integer
    getRawId(location.query.channelId)
)

export const selectWidgetFromUrlChannelId = createSelector(
  selectChannelIdFromUrl,
  selectWidgetsById,
  (channelId, widgetsById) => widgetsById[channelId]
)

export const selectWidgetFromUrlConversationId = createSelector(
  selectRoomFromUrl,
  selectWidgetsById,
  (room, widgetsById) => {
    const { channelId = '' } = room || {}
    return widgetsById[getRawId(channelId)] || null
  }
)

export const selectWidgetFromUrl = createSelector(
  selectWidgetFromUrlChannelId,
  selectWidgetFromUrlConversationId,
  (channelWidget, conversationWidget) =>
    conversationWidget || channelWidget || null
)

export const selectIsReactionsEnabledForCurrentWidget = createSelector(
  selectWidgetFromUrl,
  widget => {
    const {
      publishedSettings: {
        'chat.reactionsEnabled': reactionsEnabled = false,
      } = {},
    } =
      widget || {}
    return reactionsEnabled
  }
)

export const selectIsChatAvailableForCurrentWidget = createSelector(
  selectWidgetFromUrl,
  widget => {
    const {
      publishedSettings: { 'chat.availability': availability = 'offline' } = {},
      status,
    } =
      widget || {}
    return availability !== 'offline' && status !== 'disconnected'
  }
)

export const selectCurrentUserHasChatAccessToCurrentWidget = createSelector(
  selectWidgetFromUrl,
  currentWidget => {
    // NOTE (jscheel): Possible that the widget hasn't been loaded yet
    if (!currentWidget) return true
    return currentWidget.hasAccess
  }
)

export const selectCurrentUserHasChatAccessToSomeWidgets = createSelector(
  selectWidgetsWithChat,
  widgets => {
    widgets.some(widget => widget.hasAccess)
  }
)

export const selectIsWidgetFromUrlDisconnected = createBasicSelector(
  selectWidgetFromUrl,
  selectIsChatBootstrapped,
  selectIsInChat,
  (widget, isChatBootstrapped, isInChat) => {
    if (!isInChat || !isChatBootstrapped) {
      return false
    }

    return widget?.status === 'disconnected'
  }
)

export const selectIsChatEnabledForCurrentWidget = createSelector(
  selectCurrentWidget,
  widget => widget.publishedSettings?.['chat.enabled']
)

export const selectIsBridgeWidgetById = createBasicSelector(
  selectWidgetById,
  widget => isBridgeChannelType(widget?.channelType)
)
