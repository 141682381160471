import { combineReducers } from 'redux'
import { byQueryId } from './byQueryId'
import { lastUpdatedAt } from './lastUpdatedAt'
import { currentQueryId } from './currentQueryId'
import { previousQueryId } from './previousQueryId'

export default combineReducers({
  byQueryId,
  lastUpdatedAt,
  currentQueryId,
  previousQueryId,
})
