import { selectCurrentConversationById } from '../selectors'
import { isSpam } from '../utils/state'
import { doRestore } from './doRestore'
import { doSpam } from './doSpam'

export const doToggleSpam = (ticketId, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)
  if (!ticket) return null

  if (isSpam(ticket)) {
    return dispatch(doRestore(ticketId, options))
  }
  return dispatch(doSpam(ticketId, options))
}
