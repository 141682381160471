import { createSelector } from 'reselect'
import { selectCurrentConversationsById } from 'ducks/tickets/selectors'
import { selectCurrentTicketId } from './selectCurrentTicketId'

export const selectCurrentConversation = createSelector(
  selectCurrentConversationsById,
  selectCurrentTicketId,
  (byId, id) => {
    return byId[id] || null
  }
)
