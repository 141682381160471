/* eslint-disable no-param-reassign */
import { createBasicSelector } from 'util/redux'
import { selectReactionsByReactionForEventGroupId } from './selectReactionsByReactionForEventGroupId'

export const selectTotalReactionsForEventGroupAndReaction = createBasicSelector(
  selectReactionsByReactionForEventGroupId,
  (_state, _groupId, reaction) => reaction,
  (reactionsByReaction, reaction) => {
    return reactionsByReaction[reaction].length
  }
)
