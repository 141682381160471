import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION } from '../actionTypes'
import { updateToggleNoteReactionMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { conversationToggleNoteReactionGraphQlResponseSchema } from '../schema'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { onUpdateAttachEventGroupIdToEvents } from '../utils/request'

export const doToggleNoteReaction = (
  ticketId,
  messageId,
  reaction,
  options = {}
) => async (dispatch, getState) => {
  const conversationId = buildId('Conversation', ticketId)
  if (!reaction) return false

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      messageId,
      reaction,
    },
    options
  )

  return dispatch(
    doGraphqlRequest(
      UPDATE_CONVERSATION_TOGGLE_NOTE_REACTION,
      updateToggleNoteReactionMutation(),
      {
        conversationId,
        messageId,
        reaction,
      },
      {
        app: true,
        throwOnError: true,
        concurrency: {
          key: ticketId,
          message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
        },
        normalizationSchema: conversationToggleNoteReactionGraphQlResponseSchema,
        transformResponse: onUpdateAttachEventGroupIdToEvents(
          'conversationToggleNoteReaction'
        ),
        optimist,
        moduleOptions: {
          entities: {
            additionalActions,
          },
        },
        meta: {
          mergeEntities: true,
        },
        ...options,
      }
    )
  )
}
