import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { selectCurrentAgentById } from 'ducks/agents/selectors'
import { selectCurrentTeamById } from 'ducks/teams/selectors'
import { UPDATE_CONVERSATION_ASSIGNEE } from '../actionTypes'
import { updateConversationdAssigneeMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { selectCurrentConversationById } from '../selectors'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import {
  onUpdateAttachEventGroupIdToEvents,
  withAutoRedirect,
} from '../utils/request'

import { conversationAssignGraphQlResponseSchema } from '../schema'
import { doUnassign } from './doUnassign'
import { selectWillAutoRedirectForTicketId } from '../selectors/selectWillAutoRedirectForTicketId'

export const doAssign = (
  ticketId,
  inputTeamId,
  inputAgentId,
  options = {}
) => async (dispatch, getState) => {
  const isUnassign = !inputAgentId && !inputTeamId
  if (isUnassign) return dispatch(doUnassign(ticketId, true, true, options))

  const conversationId = buildId('Conversation', ticketId)
  const agentId = buildId('Agent', inputAgentId)
  const teamId = buildId('Team', inputTeamId)

  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)
  const agent = selectCurrentAgentById(state, inputAgentId)
  const team = selectCurrentTeamById(state, inputTeamId)

  if (!ticket && (agent || team)) return null

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      agentId: inputAgentId,
      teamId: inputTeamId,
    },
    options
  )

  const backHref = window.location.href
  const willAutoRedirect = selectWillAutoRedirectForTicketId(
    getState(),
    ticketId,
    'save',
    !!options?.moduleOptions?.autoRedirect?.forced
  )

  return dispatch(
    withAutoRedirect(
      ticketId,
      (afterStartedActions, afterSuccessActions) => async () =>
        dispatch(
          doGraphqlRequest(
            UPDATE_CONVERSATION_ASSIGNEE,
            updateConversationdAssigneeMutation(),
            {
              conversationId,
              teamId: teamId || null,
              agentId: agentId || null,
              skipNotifications: !!options.skipNotifications,
            },
            mergePlain(
              {
                app: true,
                throwOnError: true,
                concurrency: {
                  key: ticketId,
                  message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                },
                normalizationSchema: conversationAssignGraphQlResponseSchema,
                transformResponse: onUpdateAttachEventGroupIdToEvents(
                  'conversationAssign'
                ),
                optimist,
                moduleOptions: {
                  snackbar: {
                    enabled: true,
                    success: {
                      enabled: true,
                      message: `${app.t('Ticket')} reassigned`,
                      link: willAutoRedirect && {
                        href: backHref,
                        text: `Go back to ${app.t('Ticket')}`,
                      },
                    },
                    failed: {
                      enabled: true,
                      message: `Oops, we couldn't reassign your ${app.t(
                        'ticket'
                      )}.`,
                    },
                  },
                  entities: {
                    additionalActions,
                  },
                },
                meta: {
                  mergeEntities: true,
                },
                afterStartedActions,
                afterSuccessActions,
              },
              options
            )
          )
        ),
      mergePlain(
        {
          moduleOptions: {
            autoRedirect: {
              enabled: true,
              redirectFirst: true,
              operation: 'save',
            },
          },
        },
        options
      )
    )
  )
}
