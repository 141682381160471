import { selectContactsById } from 'ducks/crm/contacts'
import createCachedSelector from 're-reselect'
import selectAgentsById from 'selectors/agents/selectAgentsById'
import { getType } from 'util/globalId'

export const selectAuthorById = createCachedSelector(
  selectContactsById,
  selectAgentsById,
  (_state, authorId) => authorId,
  (contactsById, agentsById, authorId) => {
    const isAgent = getType(authorId) === 'Agent'
    return isAgent ? agentsById[authorId] : contactsById[authorId]
  }
)((_state, authorId) => authorId || 'unknown')
