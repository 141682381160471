import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectPrefersOpenFolderOnTicketSave } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketSave'
import {
  selectSearchHasMoreByQueryId,
  selectSearchIsLoadingMoreByQueryId,
  selectSearchEntityIdsByQueryId,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { doOpenTicketPage } from 'actions/pages'
import { selectCurrentUserPrefersAutoadvanceToNextTicket } from 'ducks/currentUser/selectors/preferences/selectCurrentUserPrefersAutoadvanceToNextTicket'
import { selectPrefersOpenFolderOnTicketReply } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketReply'
import { selectPrefersOpenFolderOnNoteAdd } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnNoteAdd'
import { selectFolderRouteAction } from '../selectors/selectFolderRouteAction'
import { doOpenNextTicketPage } from '../actions/doOpenNextTicketPage'
import { doFetchTickets } from '../actions'
import { selectCurrentTicketId } from '../selectors/selectCurrentTicketId'

// Ensure the logic in here matches the logic in selectWillAutoRedirectForTicketId
export const doAutoRedirect = (
  ticketId,
  inputNextTicketId,
  operation,
  options = {}
) => async (dispatch, getState) => {
  const state = getState()
  const forced = options.forced || false
  const queryId = selectCurrentQueryId(state)
  const classicView = selectPrefersClassicView(state)
  const shouldOpenFolderOnSave = selectPrefersOpenFolderOnTicketSave(state)
  const shouldOpenFolderOnReply = selectPrefersOpenFolderOnTicketReply(state)
  const shouldOpenFolderOnNote = selectPrefersOpenFolderOnNoteAdd(state)

  const shouldOpenFolder =
    (operation === 'save' && shouldOpenFolderOnSave) ||
    (operation === 'reply' && shouldOpenFolderOnReply) ||
    (operation === 'note' && shouldOpenFolderOnNote)

  const linkToFolder = selectFolderRouteAction(state)
  const hasMore = selectSearchHasMoreByQueryId(state, queryId)
  const isLoadingMore = selectSearchIsLoadingMoreByQueryId(state, queryId)
  const shouldAdvance = selectCurrentUserPrefersAutoadvanceToNextTicket(state)
  const currentTicketId = selectCurrentTicketId(state)
  const currentTicketIds = selectSearchEntityIdsByQueryId(state, queryId)

  const isStillInCurrentQueryList = currentTicketIds.includes(ticketId)
  const nextTicketId =
    forced && classicView && operation === 'delete' ? null : inputNextTicketId

  if (forced) {
    if (nextTicketId) {
      dispatch(doOpenTicketPage(nextTicketId, { preserveQuery: true }))
    } else {
      dispatch(linkToFolder)
    }
  } else if (currentTicketId !== ticketId || currentTicketId === nextTicketId) {
    // The customer or agent has manually navigated away from the ticket
    // where the request originated from, and we should not auto-redirect.

    // Also we dont redirect if we're already on the next ticket
    return null
  } else if (shouldAdvance) {
    if (classicView) {
      if (shouldOpenFolder) {
        dispatch(linkToFolder)
      }
    } else if (!isStillInCurrentQueryList) {
      // The rule here is that if the action I've taken causes the conversation to no longer
      // be in the conversation list im in, then I should move to the next conversation.
      // so for example, leaving a note doesnt change status so it wont move out of the
      // current folder. However leaving a note & closing would move it out of the current
      // folder and would mean the ticket should auto advance.
      if (nextTicketId) {
        dispatch(doOpenTicketPage(nextTicketId, { preserveQuery: true }))
      } else if (hasMore && !isLoadingMore) {
        await dispatch(doFetchTickets())
        dispatch(doOpenNextTicketPage())
      }
    }
  }

  return null
}
