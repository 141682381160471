import { selectRequestByType } from 'ducks/requests/selectors'
import { createBasicSelector } from 'util/redux'
import { buildConversationEventsByGroupIdRequestKey } from '../utils/request'

export const selectTicketEventsRequestByEventGroupId = createBasicSelector(
  (state, eventGroupId) => {
    return selectRequestByType(
      state,
      buildConversationEventsByGroupIdRequestKey(eventGroupId)
    )
  }
)
