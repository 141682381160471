import { isCloseable, isDeleted, isSnoozed } from '../utils/state'
import { doClose } from './doClose'
import { doOpen } from './doOpen'
import { doRestore } from './doRestore'

export function doToggleTicketState(ticketId, state, options) {
  return dispatch => {
    if (isDeleted({ state })) {
      return dispatch(doRestore(ticketId, options))
    }
    if (isSnoozed({ state }) || isCloseable(state)) {
      return dispatch(doClose(ticketId, options))
    }
    return dispatch(doOpen(ticketId, options))
  }
}
