import { mergePlain } from 'util/merge'
import { doBulkUpdate } from './doBulkUpdate'
import { doAssign } from './doAssign'

export const doBulkAssign = (
  ticketIds,
  teamId,
  agentId,
  options = {}
) => dispatch => {
  const isUnassign = !teamId && !agentId
  return dispatch(
    doBulkUpdate(
      ticketIds,
      doAssign,
      [teamId, agentId],
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('N_Tickets', {
                  count: ticketIds.length,
                })} ${isUnassign ? 'unassigned' : 'reassigned'}.`,
              },
              failed: {
                enabled: true,
                message: "Oops, we couldn't reassign those conversations.",
              },
            },
          },
        },
        options
      )
    )
  )
}
