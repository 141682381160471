/* eslint-disable no-param-reassign */
import { BEGIN } from 'redux-optimist'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { createActionTypeReducer } from 'util/reducers'
import { without, withUnshift, withPush, difference, uniq } from 'util/arrays'
import {
  normalizeSearchQueryId,
  constructGraphQLFilterObject,
  constructGraphQLOrderByObject,
  removeContextFromQueryId,
  isForCurrentUser,
  removeKeysFromQueryId,
  queryIdToQuery,
  toBaseQueryId,
  toFilterQueryId,
} from 'ducks/searches/utils/query'
import debug from 'util/debug'
import { deepCopy, emptyObj } from 'util/objects'
import { byLongestUnanswered, byOldest } from 'util/search/sorting'
import {
  ROOM_OPTIMISTIC_UPDATED,
  ROOMS_UPDATED,
  ROOM_DELETE_STARTED,
} from 'ducks/chat/actionTypes/rooms'

import { getRoomId } from 'ducks/chat/utils/rooms'
import { getRawId } from 'util/globalId'
import { DELETE_CONVERSATION_STARTED } from 'ducks/tickets/actionTypes'
import { DELETE_MODE_HARD } from 'ducks/tickets/constants'

import {
  FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_SUCCESS,
  SEARCH_SYNC,
} from '../actionTypes'
import {
  calculateBasicDiff,
  calculateDiffType,
  normalizeConversation,
  SEARCH_DIFF_TYPES,
} from '../utils/diff'
import { PAGINATION_MODE } from '../constants'
import { sortSearchIds } from '../utils/sort'

const byQueryIdInitialState = {}

function createDefaultQuery(queryId) {
  const orderBy = constructGraphQLOrderByObject(queryId) || {}
  const defaultQ = {
    queryId,
    entityIds: [],
    removedEntityIds: [],
    addedEntityIds: [],
    // highlights: {},
    filter: constructGraphQLFilterObject(queryId),
    orderBy,
    cursors: {},
    currentPageCursor: null,
    previousPageCursor: null,
    nextPageCursor: null,
    loading: false,
    loaded: false,
    errored: false,
    error: null,
    paginationHistory: [],
    entityCount: 0,
  }
  return defaultQ
}

function storeQueryEntityCount({
  queryType,
  channelId,
  draftState,
  countKey = 'entityCount',
  channelType = 'widget',
}) {
  return ({ id, [countKey]: entityCount }) => {
    let queryId = `type:${channelType} ${queryType}${id}`
    if (channelId) {
      queryId = `channel:${channelId} ${queryId}`
    }
    queryId = normalizeSearchQueryId(queryId)
    if (!draftState[queryId]) {
      draftState[queryId] = createDefaultQuery(queryId)
    }
    Object.assign(draftState[queryId], {
      entityCount: entityCount || 0,
    })
  }
}

function findLastCursor(cursors) {
  return Object.values(cursors).find(cursor => !cursor.hasNextPage)
}

function findFirstCursor(cursors) {
  return Object.values(cursors).find(cursor => !cursor.hasPreviousPage)
}

function cursorsWithout(cursors, entityId) {
  Object.values(cursors).forEach(cursor => {
    without(cursor.entityIds, entityId)
  })
}

function withUnshiftToFirstCursor(cursors, entityId) {
  const firstCursor = findFirstCursor(cursors)
  if (firstCursor) {
    withUnshift(firstCursor.entityIds, entityId)
  }
}

function withPushToLastCursor(cursors, entityId) {
  const lastCursor = findLastCursor(cursors)
  if (lastCursor) {
    withPush(lastCursor.entityIds, entityId)
  }
}

function entityIdsFromCursors(cursors) {
  return Object.values(cursors).reduce((eIds, { entityIds }) => {
    entityIds.forEach(entityId => withPush(eIds, entityId))
    return eIds
  }, [])
}

function shouldAppendTicketToEnd(sortOrder) {
  return byOldest(sortOrder) || byLongestUnanswered(sortOrder)
}

const reducers = {}

reducers[FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_SUCCESS] = (
  draftState,
  action
) => {
  const {
    payload,
    meta: {
      requestParameters: { channelId },
      channelType,
    },
  } = action

  const folderCounts = payload?.folders?.nodes || []
  const tagCounts = payload?.tags?.nodes || []
  const agentCounts = payload?.agents?.nodes || []
  const channelCounts = payload?.channels?.nodes || []
  const pinnedSearchCounts = payload?.pinnedSearches?.nodes || []

  folderCounts.forEach(
    storeQueryEntityCount({
      queryType: 'folder:',
      draftState,
      channelId,
      countKey: 'conversationCount',
      channelType,
    })
  )

  folderCounts.forEach(
    storeQueryEntityCount({
      queryType: 'folderunread:',
      draftState,
      channelId,
      countKey: 'unreadCount',
      channelType,
    })
  )

  tagCounts.forEach(
    storeQueryEntityCount({
      queryType: 'is:open tagid:',
      draftState,
      channelId,
      countKey: 'conversationCount',
      channelType,
    })
  )

  agentCounts.forEach(
    storeQueryEntityCount({
      queryType: 'is:open assignee:',
      draftState,
      channelId,
      countKey: 'conversationCount',
      channelType,
    })
  )

  if (pinnedSearchCounts) {
    const savePinnedSearchCount = storeQueryEntityCount({
      queryType: '',
      draftState,
      countKey: 'conversationCount',
      channelType,
      channelId,
    })

    pinnedSearchCounts.forEach(({ conversationCount, queryId }) => {
      savePinnedSearchCount({ id: queryId, conversationCount })
    })
  }

  if (!channelId) {
    channelCounts.forEach(
      storeQueryEntityCount({
        queryType: 'is:open channel:',
        draftState,
        countKey: 'conversationCount',
        channelType,
      })
    )
    const allFolderCount = channelCounts.reduce((total, count) => {
      return total + count.conversationCount
    }, 0)
    storeQueryEntityCount({
      queryType: 'is:open',
      draftState,
      countKey: 'conversationCount',
      channelType,
    })({ id: '', conversationCount: allFolderCount })
  }
  return draftState
}

reducers[SEARCH_SYNC] = (
  draftState,
  {
    payload: {
      currentLastUpdatedAt,
      lastUpdatedAt,
      searches,
      currentUser: { id: currentUserId },
      widgetsById,
      pageChannelType,
    },
  }
) => {
  // We can only start syncing counts after the initial counts has been loaded via
  // FETCH_CONVERSATION_COUNTS_FOR_CHANNEL
  if (!currentLastUpdatedAt) return
  // We're using ephemeral events in matrix which will automatically remove the
  // json content after the specified period (currently its 1 minute after creation)
  // The event still existing, but there is no content. If we recieve an event like
  // this we should just ignore it
  if (!lastUpdatedAt || !searches) return
  // If the data in our store is up to date or newer that the data getting loaded, then
  // just ignore this update. We disabled this check completely for tickets as the date being
  // returned is too unreliable. We also cant use a "global" date here because tickets can be
  // delivered via realtime out of order. This means that we could be getting a ticket we have
  // no knowledge off which is older after we recieved a new ticket.
  if (
    pageChannelType !== MAILBOX_CHANNEL_TYPE &&
    currentLastUpdatedAt >= lastUpdatedAt
  )
    return

  Object.keys(draftState).forEach(rawQueryId => {
    const queryId = toBaseQueryId(rawQueryId)

    Object.keys(searches).forEach(rawSearchDiffQueryId => {
      // Remove the type:widget when we implement the unified conversation index
      if (!isForCurrentUser(rawSearchDiffQueryId, currentUserId)) return

      const { channel } = queryIdToQuery(queryId) || {}
      const channelId = channel?.[0]
      let channelType = pageChannelType
      if (channelId && widgetsById) {
        const { channelType: widgetChannelType } =
          widgetsById[getRawId(channelId)] || {}
        channelType = widgetChannelType || channelType
      }

      const searchDiffQueryId = normalizeSearchQueryId(
        `${removeContextFromQueryId(rawSearchDiffQueryId)} type:${channelType}`
      )

      if (searchDiffQueryId !== queryId) return

      const searchDiff = searches[rawSearchDiffQueryId]
      const search = draftState[rawQueryId]

      const currentSearch = debug.enabled ? deepCopy(search) : null
      const { plus, minus } = searchDiff

      let increaseCountBy = searchDiff.plus.length
      let decreaseCountBy = searchDiff.minus.length

      // All the ticket ids we have cached on all pages
      const {
        entityIds,
        removedEntityIds,
        addedEntityIds,
        orderBy,
        cursors,
      } = search

      plus.forEach(inputConversationId => {
        const conversationId = inputConversationId.toString()
        // conversation is already in the list, no need to add
        if (
          entityIds.includes(conversationId) ||
          addedEntityIds.includes(conversationId)
        ) {
          // and also no need to change the search count for this conversation
          increaseCountBy -= 1
          without(addedEntityIds, conversationId)
        } else {
          // If sorting by oldest and we have *all* pages cached, then we
          // can simply add the new conversation to the end of the list. If we
          // dont have all pages cached, we cant know where to insert it,
          // so we do nothing.
          // eslint-disable-next-line no-lonely-if
          if (shouldAppendTicketToEnd(orderBy)) {
            // see if we have the last page cached...
            if (search.hasAllPages) {
              withPushToLastCursor(cursors, conversationId)
              without(removedEntityIds, conversationId)
            }
          } else {
            // ASSUMES ORDERED BY NEWEST
            withUnshiftToFirstCursor(cursors, conversationId)
            without(removedEntityIds, conversationId)
          }
        }
      })

      minus.forEach(inputConversationId => {
        const conversationId = inputConversationId.toString()
        const currentIndex = entityIds.indexOf(conversationId)
        if (currentIndex >= 0) {
          cursorsWithout(cursors, conversationId)
          without(addedEntityIds, conversationId)
          // ticket is present in removed, do not decrease count
        } else if (removedEntityIds.includes(conversationId)) {
          decreaseCountBy -= 1
        }
        without(removedEntityIds, conversationId)
      })

      search.entityCount += increaseCountBy
      search.entityCount -= decreaseCountBy
      search.entityIds = entityIdsFromCursors(search.cursors)

      if (search.entityCount < 0) {
        if (debug.enabled) {
          // eslint-disable-next-line no-console
          console.error('got negative search count', {
            queryId,
            currentSearch,
            updatedSearch: deepCopy(search),
            update: searchDiff,
          })
        }
        search.entityCount = 0
      }
    })
  })
}

const optimisticUpdate = (draftState, action) => {
  const {
    payload: { roomId: inputRoomId, room, oldRoom, widgetsById },
  } = action
  const roomId = inputRoomId.toString()

  const oldEntities = action.entities?.state?.current

  // Note technically we should pass newEntities over here, but because chat
  // doesnt use the entity store yet, they would be the same.
  const newEntity = normalizeConversation(room, oldEntities)
  const oldEntity = normalizeConversation(oldRoom, oldEntities)

  const searchDiffs = calculateBasicDiff(newEntity, oldEntity, oldEntities)

  Object.keys(draftState).forEach(rawQueryId => {
    const search = draftState[rawQueryId]
    const { channelType } = widgetsById[getRawId(room.channelId)]

    const diffType = calculateDiffType(rawQueryId, searchDiffs, channelType, {
      isOptimistic: true,
    })
    if (diffType === SEARCH_DIFF_TYPES.REMOVED) {
      cursorsWithout(search.cursors, roomId)
      without(search.addedEntityIds, roomId)
      withPush(search.removedEntityIds, roomId)
      search.entityCount += -1
      search.entityIds = entityIdsFromCursors(search.cursors)
    } else if (
      diffType === SEARCH_DIFF_TYPES.ADDED &&
      !search.entityIds.includes(roomId)
    ) {
      withUnshiftToFirstCursor(search.cursors, roomId)
      withPush(search.addedEntityIds, roomId)
      without(search.removedEntityIds, roomId)
      search.entityCount += 1
      search.entityIds = entityIdsFromCursors(search.cursors)
    }
  })
  return draftState
}

reducers[ROOM_OPTIMISTIC_UPDATED] = optimisticUpdate

reducers[ROOMS_UPDATED] = (draftState, action) => {
  const {
    payload: { roomUpdates, loadingMatrixIds },
  } = action
  if (!roomUpdates) return draftState

  const eventsByRoom = roomUpdates.reduce((out, { room, events }) => {
    // If we're loading this room as part of a ROOMS_FETCH, then no resorting
    // rules should be applied as the ES order is authorative
    if (loadingMatrixIds.includes(room.roomId)) return out

    const roomId = getRoomId(room)
    const roomMessageEvents = events.filter(event => {
      const roomEvents = room.getLiveTimeline().getEvents()
      const lastEvent = roomEvents[roomEvents.length - 1]

      return (
        event.getType() === 'm.room.message' &&
        (!roomEvents.includes(event) || event === lastEvent)
      )
    })
    if (roomId && roomMessageEvents.length > 0) {
      out[roomId] = roomMessageEvents
    }
    return out
  }, {})

  // Break out early if we have nothing to update
  if (Object.keys(eventsByRoom).length === 0) return draftState

  Object.keys(draftState).forEach(queryId => {
    const { entityIds, orderBy, loaded, hasAllPages, cursors } = draftState[
      queryId
    ]
    if (loaded) {
      Object.keys(eventsByRoom).forEach(roomId => {
        if (entityIds.includes(roomId)) {
          if (shouldAppendTicketToEnd(orderBy)) {
            // see if we have the last page cached...
            if (hasAllPages) {
              cursorsWithout(cursors, roomId)
              withUnshiftToFirstCursor(cursors, roomId)
              draftState[queryId].entityIds = entityIdsFromCursors(cursors)
            }
          } else {
            // ASSUMES ORDERED BY NEWEST
            cursorsWithout(cursors, roomId)
            withUnshiftToFirstCursor(cursors, roomId)
            draftState[queryId].entityIds = entityIdsFromCursors(cursors)
          }
        }
      })
    }
  })

  return draftState
}

reducers[ROOM_DELETE_STARTED] = (draftState, action) => {
  const {
    payload: { conversationId },
  } = action

  Object.keys(draftState).forEach(queryId => {
    const search = draftState[queryId]
    if (search.entityIds.includes(conversationId)) {
      const { cursors, removedEntityIds } = search
      cursorsWithout(cursors, conversationId)
      withPush(removedEntityIds, conversationId)
      search.entityIds = entityIdsFromCursors(search.cursors)
    }
  })
  return draftState
}

reducers[DELETE_CONVERSATION_STARTED] = (draftState, action) => {
  const {
    payload: { conversationIds, deleteMode },
  } = action
  if (deleteMode !== DELETE_MODE_HARD) return draftState

  Object.keys(draftState).forEach(queryId => {
    const search = draftState[queryId]
    conversationIds.forEach(conversationId => {
      if (search.entityIds.includes(conversationId)) {
        const { cursors, removedEntityIds } = search
        cursorsWithout(cursors, conversationId)
        withPush(removedEntityIds, conversationId)
        search.entityIds = entityIdsFromCursors(search.cursors)
      }
    })
  })
  return draftState
}

function searchStartedReducer(draftState, action) {
  const { searches: { queries = {} } = {} } = action || {}

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = normalizeSearchQueryId(
      removeKeysFromQueryId(['cursor'], rawQueryId)
    )
    const { type } = queries[rawQueryId]
    if (type === 'STARTED') {
      Object.assign(draftState[queryId], {
        loading: true,
        errored: false,
      })
    }
  })
  return draftState
}

function searchFailedReducer(draftState, action) {
  const { searches: { queries = {} } = {} } = action || {}

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = normalizeSearchQueryId(
      removeKeysFromQueryId(['cursor'], rawQueryId)
    )
    const { type, error } = queries[rawQueryId]
    if (type === 'FAILED') {
      Object.assign(draftState[queryId], {
        loading: false,
        errored: true,
        error,
      })
    }
  })
  return draftState
}

function searchInvalidateReducer(draftState, action) {
  const { searches: { queries = {} } = {} } = action || {}

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = normalizeSearchQueryId(
      removeKeysFromQueryId(['cursor'], rawQueryId)
    )
    const { type } = queries[rawQueryId]
    if (type === 'INVALIDATE') {
      Object.keys(draftState[queryId].cursors).forEach(cursorId => {
        draftState[queryId].cursors[cursorId].isStale = true
      })
    }
  })
  return draftState
}

function searchUnloadReducer(draftState, action) {
  const { searches: { queries = {} } = {} } = action || {}

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = normalizeSearchQueryId(
      removeKeysFromQueryId(['cursor'], rawQueryId)
    )
    const { type } = queries[rawQueryId]
    if (type === 'UNLOAD') {
      draftState[queryId] = {
        ...createDefaultQuery(queryId),
        // Preserve the current entity count when unloading the search so that folders
        // remain operational
        entityCount: draftState[queryId].entityCount,
      }
    }
  })
  return draftState
}

function searchUpdateCursorReducer(draftState, action) {
  const { searches: { updateCursor } = {} } = action || {}

  if (updateCursor) {
    Object.keys(updateCursor).forEach(rawQueryId => {
      const updates = updateCursor[rawQueryId]
      const { cursor } = constructGraphQLFilterObject(rawQueryId)
      const queryId = normalizeSearchQueryId(
        removeKeysFromQueryId(['cursor'], rawQueryId)
      )
      const draftCursor = draftState[queryId]?.cursors[cursor]
      if (draftCursor) {
        Object.assign(draftCursor, updates)
      }
    })
  }
  return draftState
}

function searchAddCursorEntityIdsReducer(draftState, action) {
  const { searches: { addCursorEntityIds } = {} } = action || {}

  if (addCursorEntityIds) {
    Object.keys(addCursorEntityIds).forEach(rawQueryId => {
      const entityIds = addCursorEntityIds[rawQueryId]

      if (entityIds?.length) {
        const { cursor } = constructGraphQLFilterObject(rawQueryId)
        const queryId = normalizeSearchQueryId(
          removeKeysFromQueryId(['cursor'], rawQueryId)
        )
        const draftCursor = draftState[queryId]?.cursors[cursor]

        const existingEntityIds = draftCursor?.entityIds || []
        // no duplicates adds
        const uniqEntityIdsToAdd = difference(entityIds, existingEntityIds)

        if (uniqEntityIdsToAdd) {
          Object.assign(draftState[queryId], {
            entityIds: [...existingEntityIds, ...uniqEntityIdsToAdd],
          })
        }

        if (draftCursor && uniqEntityIdsToAdd) {
          Object.assign(draftCursor, {
            entityIds: [...existingEntityIds, ...uniqEntityIdsToAdd],
          })
        }
      }
    })
  }

  return draftState
}

function searchRemoveCursorEntityIdsReducer(draftState, action) {
  const { searches: { removeCursorEntityIds } = {} } = action || {}

  if (removeCursorEntityIds) {
    Object.keys(removeCursorEntityIds).forEach(rawQueryId => {
      const entityIds = removeCursorEntityIds[rawQueryId]

      if (entityIds?.length) {
        const { cursor } = constructGraphQLFilterObject(rawQueryId)
        const queryId = normalizeSearchQueryId(
          removeKeysFromQueryId(['cursor'], rawQueryId)
        )
        const draftCursor = draftState[queryId]?.cursors[cursor]

        const existingEntityIds = draftCursor?.entityIds || []

        if (existingEntityIds) {
          Object.assign(draftState[queryId], {
            entityIds: existingEntityIds.filter(id => !entityIds.includes(id)),
          })
        }

        if (draftCursor && existingEntityIds) {
          Object.assign(draftCursor, {
            entityIds: existingEntityIds.filter(id => !entityIds.includes(id)),
          })
        }
      }
    })
  }

  return draftState
}

function searchInvalidateEntityReducer(draftState, action) {
  const { searches: { invalidateEntities } = {} } = action || {}

  if (invalidateEntities) {
    Object.keys(draftState).forEach(queryId => {
      const { entityType } = queryIdToQuery(queryId) || {}
      if (invalidateEntities.includes(entityType)) {
        Object.keys(draftState[queryId].cursors).forEach(cursorId => {
          draftState[queryId].cursors[cursorId].isStale = true
        })
      }
    })
  }
  return draftState
}

function searchSuccessReducer(draftState, action) {
  const { searches: { queries = {} } = {}, meta: { paginationMode } = {} } =
    action || {}

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = normalizeSearchQueryId(
      removeKeysFromQueryId(['cursor'], rawQueryId)
    )
    const {
      type,
      result: {
        nodes = [],
        pageInfo: {
          startCursor,
          endCursor,
          hasNextPage,
          hasPreviousPage: pageInfoHasPreviousPage,
        } = {},
        totalCount,
        totalPageCount,
      } = {},
      request: { cursor, orderBy } = {},
    } = queries[rawQueryId]
    if (type !== 'SUCCESS') return

    // The first page doesnt have a cursor, and so its value would be null
    const currentPageCursor = cursor || null
    const currentEntityIds = nodes.map(item => item.id || item.node.id)

    const paginationHistorySet = new Set(draftState[queryId].paginationHistory)
    // Add unique current request cursor to bottom of list
    paginationHistorySet.add(currentPageCursor)
    const paginationHistory = Array.from(paginationHistorySet)

    let hasPreviousPage = pageInfoHasPreviousPage
    let previousPageCursor = startCursor

    if (paginationMode === PAGINATION_MODE.ES_CURSOR) {
      // For API ES queries that use search_after pagination
      // search_after pagination does not have a simple way for backwards pagination
      // so we store a list of all request cursors to allow for backwards pagination
      const index = paginationHistory.indexOf(cursor) || 0
      const previousPageCursorIndex = index - 1

      if (previousPageCursorIndex < 0) {
        hasPreviousPage = false
        previousPageCursor = null
      } else {
        hasPreviousPage = true
        previousPageCursor = paginationHistory[previousPageCursorIndex]
      }
    }

    Object.keys(draftState[queryId].cursors).forEach(cursorId => {
      // The current cursor will always be overwritten with the latest information
      // so there is no reason to apply a differential update
      if (cursorId === currentPageCursor) return

      const stateCursor = draftState[queryId].cursors[cursorId]
      currentEntityIds.forEach(entityId => {
        if (stateCursor.entityIds.includes(entityId)) {
          without(stateCursor.entityIds, entityId)
          stateCursor.isStale = true
        }
      })
    })

    const cursors = Object.assign(draftState[queryId].cursors, {
      [currentPageCursor]: Object.assign(
        draftState[queryId].cursors[currentPageCursor] || {},
        {
          current: currentPageCursor,
          next: endCursor,
          previous: previousPageCursor,
          hasNextPage,
          hasPreviousPage,
          entityIds: currentEntityIds,
          // Has a realtime or optimistic update happened that
          // affected an entity in this page
          isStale: false,
        }
      ),
    })

    let entityCount = totalCount
    // When update conversations, there timestamps get updated which means they bubble to the top
    // of the conversation list. This means that when we load the first page in the default order
    // we can use the results to check if the recent changes has been indexed and clear the
    // addedConversations array which keeps track of local changes
    if (currentPageCursor === null && orderBy === null) {
      const { addedEntityIds, removedEntityIds } = draftState[queryId]
      // When we optimistically move conversations around we add them to addedEntityIds
      // and removedEntityIds, this means that if the customer quickly navigates to
      // the new folder before ES has been updated, we'll add/remove the conversation in the
      // list even if the latest respond from ES differs.
      currentEntityIds.forEach(conversationId => {
        without(addedEntityIds, conversationId)
        if (removedEntityIds.includes(conversationId)) {
          without(
            draftState[queryId].cursors[currentPageCursor],
            conversationId
          )
          entityCount -= 1
        }
      })
      entityCount += addedEntityIds.length
    }

    const entityIds = entityIdsFromCursors(draftState[queryId].cursors)

    Object.assign(draftState[queryId], {
      queryId,
      entityCount,
      totalPages: totalPageCount,
      loading: false,
      loaded: true,
      errored: false,
      hasAllPages: draftState[queryId].hasAllPages || !hasNextPage,
      entityIds,
      cursors,
      previousPageCursor,
      currentPageCursor,
      nextPageCursor: endCursor,
      paginationHistory,
    })
  })

  Object.keys(draftState).forEach(rawQueryId => {
    const queryId = toFilterQueryId(rawQueryId)

    Object.keys(queries).forEach(rawSearchDiffQueryId => {
      const searchDiffQueryId = toFilterQueryId(rawSearchDiffQueryId)

      if (searchDiffQueryId !== queryId) return
      if (draftState[rawQueryId].loaded) return

      const { type, result: { totalCount: entityCount } = {} } = queries[
        rawSearchDiffQueryId
      ]
      if (type !== 'SUCCESS') return

      draftState[rawQueryId] = createDefaultQuery(rawQueryId)
      Object.assign(draftState[rawQueryId], {
        entityCount: entityCount || 0,
      })
    })
  })

  return draftState
}

const searchObserverReducer = (draftState, action) => {
  const { searches = emptyObj } = action || {}
  if (searches === emptyObj) return draftState
  const queries = searches.queries || emptyObj

  Object.keys(queries).forEach(rawQueryId => {
    const queryId = normalizeSearchQueryId(
      removeKeysFromQueryId(['cursor'], rawQueryId)
    )
    if (!draftState[queryId]) {
      draftState[queryId] = createDefaultQuery(queryId)
    }
  })
  // Each reducer will determine if if needs to execute based on the
  // action payload
  if (queries !== emptyObj) {
    searchStartedReducer(draftState, action)
    searchSuccessReducer(draftState, action)
    searchFailedReducer(draftState, action)
    searchInvalidateReducer(draftState, action)
    searchUnloadReducer(draftState, action)
  }
  searchUpdateCursorReducer(draftState, action)
  searchAddCursorEntityIdsReducer(draftState, action)
  searchRemoveCursorEntityIdsReducer(draftState, action)
  searchInvalidateEntityReducer(draftState, action)
  return draftState
}

const SEARCH_OPTIMIST_SUPPORTED_ENTITIES = ['conversation']

const searchOptimisticObserverReducer = (draftState, action) => {
  const runOptimistic =
    action.meta?.updateSearches ||
    action.meta?.createSearches ||
    action.optimist?.type === BEGIN
  if (!runOptimistic) return draftState
  const entities =
    action?.optimist?.payload?.entities || action?.transformedEntities
  const oldEntities = action.entities?.state?.current

  if (!entities || !oldEntities) return draftState
  if (
    !Object.keys(entities).some(entityType =>
      SEARCH_OPTIMIST_SUPPORTED_ENTITIES.includes(entityType)
    )
  )
    return draftState

  const newEntities = uniq([
    ...Object.keys(entities),
    ...Object.keys(oldEntities),
  ]).reduce((result, entityType) => {
    result[entityType] = {
      byId: {
        ...(oldEntities[entityType]?.byId || {}),
        ...(entities[entityType] || {}),
      },
      ids: [
        ...(oldEntities[entityType]?.ids || []),
        Object.keys(entities[entityType] || {}),
      ],
    }
    return result
  }, {})

  SEARCH_OPTIMIST_SUPPORTED_ENTITIES.forEach(entityType => {
    const newEntityStore = newEntities[entityType]?.byId || {}
    const oldEntityStore = oldEntities[entityType]?.byId || {}

    Object.keys(entities[entityType]).forEach(entityId => {
      const newEntity = normalizeConversation(
        newEntityStore[entityId],
        newEntities
      )
      const oldEntity = normalizeConversation(
        oldEntityStore[entityId],
        oldEntities
      )

      const searchDiffs = calculateBasicDiff(newEntity, oldEntity, oldEntities)

      Object.keys(draftState).forEach(rawQueryId => {
        const search = draftState[rawQueryId]

        let resort = false
        const diffType = calculateDiffType(
          rawQueryId,
          searchDiffs,
          MAILBOX_CHANNEL_TYPE,
          { isOptimistic: action.optimist?.type === BEGIN }
        )
        if (
          diffType === SEARCH_DIFF_TYPES.REMOVED ||
          // When a conversation is loaded into state for the first time, the only valid SEARCH_DIFF_TYPES
          // are NO_CHANGE and ADDED. If the entity ID appears in any search that isn't on the ADDED list,
          // it indicates a stale state. Here's a sequence of events that can lead to this situation:
          //
          // 1. A new conversation is created, triggering a real-time update with counts indicating the conversation is open.
          // 2. Simultaneously, a rule is executed that closes the conversation.
          // 2. The real-time update is recieved by the frontend and executes a SEARCH_SYNC,
          //    which adds the conversation to the "Open" folder due to the diffs.
          // 4. The frontend begins preloading the newly discovered conversation because it has been added
          //    to the current folder (marked as "Open").
          // 5. Before the frontend finishes loading the conversation, the rule modifies the ticket,
          //    transitioning it to a "Closed" state. The preload action then returns a ticket in a closed state
          //
          // As a result, the DIFF received from the real-time update still indicates the conversation is
          // "Open", but when the conversation is fully loaded, it is actually in a "Closed" state.
          // The code below will cleanup these state searches.
          //
          // One final note, once a conversation has been loaded into state we completely ignore any diffs
          // from realtime and rather use the conversation data to calculate which counts needs to be updated.
          (diffType === SEARCH_DIFF_TYPES.NO_CHANGE &&
            !oldEntityStore[entityId] &&
            search.entityIds.includes(entityId))
        ) {
          cursorsWithout(search.cursors, entityId)
          without(search.addedEntityIds, entityId)
          withPush(search.removedEntityIds, entityId)
          search.entityCount += -1
          search.entityIds = entityIdsFromCursors(search.cursors)
          resort = true
        } else if (
          diffType === SEARCH_DIFF_TYPES.ADDED &&
          !search.entityIds.includes(entityId) &&
          // In this version of optimistic a conversation can only be added to the searches store via
          // 1. Conversation is is discovered thanks to a realtime update
          // 2. Conversation is loaded in after a search or fetch ticket call
          // 3. Conversation is loaded in after it is created on the same browser
          //
          // We only want to update the ticket searches when the id has been discovered by realtime or createSearches.
          // The reason for this is that tickets that we dont know about and wasnt created on this browser will first
          // added to the search store using the SEARCH_SYNC reducer. If the discovered conversation is is part of the
          // current open search, the TicketList component will trigger a doPreloadTicket with a single ticket id.
          // That call will have updateSearches:true, but because of SEARCH_SYNC already updated the realtime store
          // we should not update the search store again. This logic is implemetned below by checking if the entity
          // existed in the old entity store when updateSearches is set to true.
          (!action.meta?.updateSearches ||
            (!!oldEntityStore[entityId] || action.meta?.createSearches))
        ) {
          // If sorting by oldest and we have *all* pages cached, then we
          // can simply add the new conversation to the end of the list. If we
          // dont have all pages cached, we cant know where to insert it,
          // so we do nothing.
          // eslint-disable-next-line no-lonely-if
          if (shouldAppendTicketToEnd(search.orderBy)) {
            if (search.hasAllPages) {
              withPushToLastCursor(search.cursors, entityId)
              without(search.removedEntityIds, entityId)
            }
          } else {
            withUnshiftToFirstCursor(search.cursors, entityId)
            withPush(search.addedEntityIds, entityId)
            without(search.removedEntityIds, entityId)
          }
          search.entityCount += 1
          search.entityIds = entityIdsFromCursors(search.cursors)
          resort = true
        }
        if (resort || search.entityIds.includes(entityId)) {
          search.entityIds = sortSearchIds(search, newEntities)
        }
      })
    })
  })
  return draftState
}

reducers['*'] = [searchObserverReducer, searchOptimisticObserverReducer]

export const byQueryId = createActionTypeReducer(
  reducers,
  byQueryIdInitialState
)
