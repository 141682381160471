import { mergePlain } from 'util/merge'
import { TRASH } from '../constants'
import { doUpdateState } from './doUpdateState'
import { shortTitleTitle } from '../utils/state'
import { selectCurrentConversationById } from '../selectors'

export const doTrash = (ticketId, options = {}) => (dispatch, getState) => {
  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)
  if (!ticket) return null

  return dispatch(
    doUpdateState(
      ticketId,
      TRASH,
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('Ticket')} ${shortTitleTitle(
                  ticket
                )} moved to Trash`,
              },
              failed: {
                enabled: true,
                message: `Oops, we couldn't move your ${app.t(
                  'ticket'
                )} to Trash.`,
              },
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
