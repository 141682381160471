import { mergePlain } from 'util/merge'
import { selectCurrentChannelById } from 'ducks/channels/selectors'
import { doBulkUpdate } from './doBulkUpdate'
import { doUpdateChannel } from './doUpdateChannel'

export const doBulkUpdateChannel = (ticketIds, channelId, options = {}) => (
  dispatch,
  getState
) => {
  const newChannel = selectCurrentChannelById(getState(), channelId)
  if (!newChannel) return null

  return dispatch(
    doBulkUpdate(
      ticketIds,
      doUpdateChannel,
      [channelId],
      mergePlain(
        {
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('N_Tickets', {
                  count: ticketIds.length,
                })} moved to "${newChannel.name}"`,
              },
              failed: {
                enabled: false,
              },
            },
          },
        },
        options
      )
    )
  )
}
