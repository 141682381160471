import { createBasicSelector } from 'util/redux'
import { selectCurrentTicketId } from './selectCurrentTicketId'
import { selectMessagesByConversationId } from './selectMessagesByConversationId'

export const selectCurrentConversationMessages = createBasicSelector(
  state => state,
  selectCurrentTicketId,
  (state, currentTicketId) => {
    return selectMessagesByConversationId(state, currentTicketId)
  }
)
