import { selectChannelCustomFields } from 'ducks/crm/channels/selectors/selectChannelCustomFields'
import { createSelector } from 'reselect'
import { VALID_OPERATORS } from 'util/search/constants'
import { DROPDOWN, MULTI_SELECT } from 'ducks/crm/customFields/types'
import { customFieldKeyToSearchKey } from '../utils/query'

const operatorForCustomField = cf => {
  if ([DROPDOWN, MULTI_SELECT].includes(cf.type)) {
    return cf.options.map(o => o.value).join(' ')
  }
  return '*'
}

export const selectValidOperators = createSelector(
  selectChannelCustomFields,
  customFields => {
    const validOperatorsWithCustomnFields = { ...VALID_OPERATORS }
    customFields.forEach(cf => {
      const searchKey = customFieldKeyToSearchKey(cf.key)
      validOperatorsWithCustomnFields[searchKey] = operatorForCustomField(cf)
    })
    return validOperatorsWithCustomnFields
  }
)
