/* eslint-disable no-underscore-dangle */
import { doDeleteDraftLocally } from 'ducks/drafts2/operations'
import { saveRequests } from 'ducks/drafts2/operations/saveRequests'
import { doUpdateEditorVisibility } from 'ducks/editor'
import { isPromise } from 'util/functions'
import { FAILED_SEND } from 'ducks/drafts2/constants'
import { selectDraftById } from 'ducks/drafts2/selectors'
import { selectSearchNextEntityIdByQueryIdAndEntityId } from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import Bugsnag from '@bugsnag/js'
import {
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_EVENT_GROUPS,
  FETCH_CONVERSATION_EVENTS_BY_GROUP_ID,
} from '../actionTypes'
import { doAutoRedirect } from '../actions/doAutoRedirect'

export const buildConversationRequestKey = conversationId =>
  `${FETCH_CONVERSATION}_${conversationId}`

export const buildConversationEventGroupsRequestKey = conversationId =>
  `${FETCH_CONVERSATION_EVENT_GROUPS}_${conversationId}`

export const buildConversationEventsByGroupIdRequestKey = eventGroupId =>
  `${FETCH_CONVERSATION_EVENTS_BY_GROUP_ID}_${eventGroupId}`

// eslint-disable-next-line no-unused-vars
const defaultExtractTicketId = () => null

/*
  Auto-Advance Actions

  | Action                         | Forced |
  |--------------------------------|--------|
  | Hard delete ticket             | true   |
  | Create new email               | true   |
  | Log a new conversation         | true   |
  | Leave a note                   | true   |
  | State changes                  | false  |
  | Reply/Forward conversation     | false  |
  | Assign/Unassign a conversation | false  |
*/

/*
  Global Auto-Redirect Logic

  The logic here applies to both forced and non-forced actions.

  | forced | hasNextTicket | classicView | isOnOriginalTicket | isOnDesiredTicket | Action        |
  |--------|--------------|--------------|--------------------|-------------------|---------------|
  | true   | Any          | true         | Any                | Any               | TicketList    |
  | true   | true         | false        | Any                | Any               | NextTicket    |
  | true   | false        | false        | Any                | Any               | TicketList    |
  | false  | Any          | any          | false              | Any               | None          |
  | false  | Any          | any          | Any                | true              | None          |
  | false  | Any          | any          | true               | false             | See below     |
*/

/*
  Auto-Redirect Logic (Classic View: classicView = true)

  | autoadvance_to_next_ticket | open_folder_on_ticket_save | open_folder_on_ticket_reply| open_folder_on_note_add | operation           | Action     |
  |----------------------------|----------------------------|----------------------------|-------------------------|---------------------|------------|
  | true                       | true                       | Any                        | Any                     | save                | TicketList |
  | true                       | Any                        | true                       | Any                     | reply               | TicketList |
  | true                       | Any                        | Any                        | true                    | note                | TicketList |
  | true                       | Any                        | Any                        | Any                     | Any other operation | None       |
  | false                      | Any                        | Any                        | Any                     | Any                 | None       |
*/

/*
  Auto-Redirect Logic (Modern View: classicView = false)

  | autoadvance_to_next_ticket | isStillInCurrentQueryList | HasNextTicket | Action     |
  |----------------------------|--------------------------|----------------|------------|
  | true                       | false                    | true           | NextTicket |
  | true                       | false                    | false          | None       |
  | true                       | true                     | Any            | None       |
  | false                      | Any                      | Any            | None       |
*/

export const withAutoRedirect = (ticketId, action, options = {}) => async (
  dispatch,
  getState
) => {
  const {
    moduleOptions: {
      autoRedirect: {
        enabled: attemptRedirect = true,
        redirectFirst,
        extractTicketId: inputExtractTicketId,
        // Ignore the default rule and force a redirection
        forced = false,
        operation = 'save',
      } = {},
    } = {},
  } = options

  const extractTicketId = inputExtractTicketId || defaultExtractTicketId

  // Its important to calculate the next ticket id before the action is taken because
  // the action can remove the ticket from the current ticket list meaning we cant determine
  // where in the ticket list this conversation was.
  const state = getState()
  const queryId = selectCurrentQueryId(state)
  const nextTicketId = selectSearchNextEntityIdByQueryIdAndEntityId(
    state,
    queryId,
    ticketId
  )

  if (attemptRedirect) {
    const afterStartedAction = () => async () => {
      if (redirectFirst) {
        const extractedTicketId = extractTicketId(null)
        await dispatch(
          doAutoRedirect(
            ticketId,
            extractedTicketId || nextTicketId,
            operation,
            { forced }
          )
        )
      }
    }
    const afterSuccessAction = ({ response }) => async () => {
      if (!redirectFirst) {
        const extractedTicketId = extractTicketId(response)
        dispatch(
          doAutoRedirect(
            ticketId,
            extractedTicketId || nextTicketId,
            operation,
            {
              forced,
            }
          )
        )
      }
    }
    return dispatch(action([afterStartedAction], [afterSuccessAction]))
  }
  return dispatch(action([], []))
}

export const onUpdateAttachEventGroupIdToEvents = mutationName => {
  return data => {
    const linkedResources = []
    if (data[mutationName].eventGroup) {
      const eventGroup = data[mutationName].eventGroup
      eventGroup.isLastMessage = true
      eventGroup.events.edges.forEach(e => {
        // eslint-disable-next-line no-param-reassign
        e.node.eventGroupId = eventGroup.id

        const change = e.node.change

        if (change?.__typename === 'IntegrationAdded') {
          linkedResources.push(change)
        }
        if (change?.__typename === 'IntegrationRemoved') {
          const index = linkedResources.findIndex(
            resource =>
              resource.externalId === change.externalId &&
              resource.provider === change.provider
          )

          if (index !== -1) {
            linkedResources.splice(index, 1)
          }
        }

        if (
          change &&
          change.__typename === 'IntegrationAdded' &&
          !change.conversationId
        ) {
          change.conversationId = eventGroup.conversationId
        }
      })
    }
    if (!data[mutationName].linkedResources) {
      // eslint-disable-next-line no-param-reassign
      data[mutationName].linkedResources = linkedResources
    }
    return data
  }
}

export const withBeforeSend = (ticketId, draftId, action) => async (
  dispatch,
  getState
) => {
  const draft = selectDraftById(getState(), draftId)

  dispatch(doDeleteDraftLocally(ticketId, draftId))
  // TODO: We can rather have the editor hide as part of the START operation
  // on the send
  dispatch(doUpdateEditorVisibility(false))
  const draftSavePromise = saveRequests[draftId]
  if (isPromise(draftSavePromise)) {
    await draftSavePromise
    delete saveRequests[draftId]
  }
  try {
    const response = await dispatch(action)
    return response
  } catch (error) {
    const errorMessage = 'Failure detected inside withBeforeSend'
    const errorDetails = {
      ticketId,
      draftId,
      draft,
      originalError: {
        message: error.message,
        stack: error.stack,
        name: error.name,
      },
    }
    Bugsnag.notify(new Error(errorMessage), event => {
      // eslint-disable-next-line no-param-reassign
      event.severity = 'error'
      event.addMetadata('metaData', errorDetails)
    })
    dispatch({
      type: FAILED_SEND,
      payload: { draftId, draft },
    })
    dispatch(doUpdateEditorVisibility(true))
    throw error
  }
}

export const isValidTicketId = inputTicketId => {
  if (!inputTicketId) return false
  const ticketId = Number(inputTicketId.toString().replace('cnv_', ''))
  return Number.isInteger(ticketId) && ticketId > 0
}

export const isTicketNoAccess = error =>
  error?.errors?.find(e => e?.extensions?.code === 'UNAUTHORIZED')

export const isTicketDeletedError = error =>
  error?.errors?.find(e => e?.extensions?.code === 'RESOURCE_DELETED')

export const isTicketMergeError = error =>
  error?.errors?.find(e => e?.extensions?.code === 'RESOURCE_MERGED')
