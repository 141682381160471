import { createSelector } from 'reselect'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { selectCurrentConversation } from './selectCurrentConversation'

export const selectCurrentConversationIsFollowing = createSelector(
  selectCurrentConversation,
  selectCurrentUserGlobalId,
  (ticket, currentUserGid) =>
    ticket?.followers && ticket.followers.includes(currentUserGid)
)
