import { mergePlain } from 'util/merge'
import * as types from 'constants/action_types'
import { selectEditingTicketTitleDraft } from 'selectors/app'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { UPDATE_CONVERSATION_TITLE } from '../actionTypes'
import { updateConversationSubjectMutation } from '../mutations'
import { selectCurrentConversationById } from '../selectors'
import { conversationUpdateSubjectGraphQlResponseSchema } from '../schema'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { buildConversationOptimistRequestOptions } from '../utils/optimistic'
import { onUpdateAttachEventGroupIdToEvents } from '../utils/request'

export const doUpdateTitle = (ticketId, options = {}) => async (
  dispatch,
  getState
) => {
  const conversationId = buildId('Conversation', ticketId)

  const state = getState()
  const title = selectEditingTicketTitleDraft(state) // Ideally passed as an arg.
  const ticket = selectCurrentConversationById(state, ticketId)

  dispatch({
    type: types.TICKET_TITLE_UPDATE_REQUEST,
    data: { ticketId, title },
  })

  if (ticket.title === title) return false

  const {
    optimist,
    additionalActions,
  } = await buildConversationOptimistRequestOptions(
    getState,
    ticketId,
    {
      conversationId: ticketId,
      subject: title,
    },
    options
  )

  return dispatch(
    doGraphqlRequest(
      UPDATE_CONVERSATION_TITLE,
      updateConversationSubjectMutation(),
      {
        conversationId,
        subject: title,
      },
      mergePlain(
        {
          app: true,
          throwOnError: true,
          normalizationSchema: conversationUpdateSubjectGraphQlResponseSchema,
          transformResponse: onUpdateAttachEventGroupIdToEvents(
            'conversationSubject'
          ),
          optimist,
          concurrency: {
            key: ticketId,
            message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
          },
          moduleOptions: {
            entities: {
              additionalActions,
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
