import { mergePlain } from 'util/merge'
import { doGraphqlRequest, doRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import {
  MERGE_AND_RELOAD_CONVERSATION,
  MERGE_CONVERSATION,
} from '../actionTypes'
import { updateMergeConversationMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { selectCurrentConversationById } from '../selectors'
import { conversationMergeGraphQlResponseSchema } from '../schema'
import { doFetchTicketEventGroups } from './doFetchTicketEventGroups'

export const doMerge = (targetTicketId, sourceTicketId, options = {}) => (
  dispatch,
  getState
) => {
  const sourceConversationId = buildId('Conversation', sourceTicketId)
  const targetConversationId = buildId('Conversation', targetTicketId)

  const state = getState()
  const sourceTicket = selectCurrentConversationById(state, sourceTicketId)
  const targetTicket = selectCurrentConversationById(state, targetTicketId)
  if (!sourceTicket || !targetTicket) return null

  return dispatch(
    doRequest(
      MERGE_AND_RELOAD_CONVERSATION,
      async () => {
        const response = await dispatch(
          doGraphqlRequest(
            MERGE_CONVERSATION,
            updateMergeConversationMutation(),
            {
              sourceConversationId,
              targetConversationId,
            },
            mergePlain(
              {
                app: true,
                throwOnError: true,
                concurrency: {
                  key: targetTicketId,
                  message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
                },
                normalizationSchema: conversationMergeGraphQlResponseSchema,
                moduleOptions: {
                  snackbar: {
                    enabled: true,
                    success: {
                      enabled: true,
                      message: `${app.t('Ticket')} merged`,
                    },
                    failed: {
                      enabled: true,
                      message: `Oops, we couldn't merge your ${app.t(
                        'ticket'
                      )}.`,
                    },
                  },
                  entities: {
                    additionalActions: [
                      {
                        entityType: 'conversation',
                        entityId: sourceTicketId,
                        stores: ['pending', 'current'],
                        operation: 'remove',
                        phases: ['SUCCESS'],
                      },
                    ],
                  },
                },
                meta: {
                  mergeEntities: true,
                },
              },
              options
            )
          )
        )
        await dispatch(doFetchTicketEventGroups(targetTicketId, options))
        return response
      },
      {
        sourceConversationId,
        targetConversationId,
      },
      mergePlain(
        {
          throwOnError: true,
        },
        options
      )
    )
  )
}
