import { flatten, uniq } from 'util/arrays'
import { SORT_CONTEXT_KEY_DELIMITER } from 'util/search/sorting'
import { selectAccountPreferenceSortByCollaboratorCommentEnabled } from 'selectors/app/selectAccountPreferences'
import { selectCurrentFoldersById } from 'ducks/folders/selectors/folders'
import createCachedSelector from 're-reselect'
import { buildSortContextParts } from '../utils/sort'

export const selectSortContextIdByQueryId = createCachedSelector(
  selectAccountPreferenceSortByCollaboratorCommentEnabled,
  selectCurrentFoldersById,
  (_state, queryId) => queryId,
  (sortByCollaboratorCommentAtEnabled, foldersById, queryId) => {
    const contextIdParts = buildSortContextParts(
      queryId,
      sortByCollaboratorCommentAtEnabled,
      foldersById
    )

    // It is possible for you to specify a search which has a folder and a is:state selector.
    // In this situation the same sort option could be added to the context parts twice because
    // the folder could for example be a deleted ticket folder, and the customer has specified
    // is:deleted. In this case we just want to make sure we stripe out those duplicates
    return uniq(
      flatten(
        contextIdParts.map(part => part.split(SORT_CONTEXT_KEY_DELIMITER))
      )
    ).join(SORT_CONTEXT_KEY_DELIMITER)
  }
)((_state, queryId) => queryId || 'unknown')
