export const undoSendMutation = () => `
mutation TicketUndoSend(
    $conversationId: ID!,
    $changesetId: ID!
) {
    conversationUndoSend( input: { conversationId: $conversationId, changesetId: $changesetId }) {
      changesetId
    }
}
`
