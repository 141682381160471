import { createBasicSelector } from 'util/redux'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectPrefersOpenFolderOnTicketSave } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketSave'
import {
  selectSearchEntityIdsByCurrentQueryId,
  selectSearchNextEntityIdByForCurrentQueryIdAndTicketId,
} from 'ducks/searches/selectors'
import { selectCurrentUserPrefersAutoadvanceToNextTicket } from 'ducks/currentUser/selectors/preferences/selectCurrentUserPrefersAutoadvanceToNextTicket'
import { selectPrefersOpenFolderOnTicketReply } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketReply'
import { selectPrefersOpenFolderOnNoteAdd } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnNoteAdd'
import { selectCurrentTicketId } from '../selectors/selectCurrentTicketId'

// Ensure the logic in here matches the logic in doAutoRedirect
export const selectWillAutoRedirectForTicketId = createBasicSelector(
  selectPrefersClassicView,
  selectPrefersOpenFolderOnTicketSave,
  selectPrefersOpenFolderOnTicketReply,
  selectPrefersOpenFolderOnNoteAdd,
  selectSearchEntityIdsByCurrentQueryId,
  selectSearchNextEntityIdByForCurrentQueryIdAndTicketId,
  selectCurrentUserPrefersAutoadvanceToNextTicket,
  selectCurrentTicketId,
  (_state, ticketId) => ticketId,
  (_state, _ticketId, operation) => operation,
  (_state, _ticketId, _operation, forced) => forced,
  (
    classicView,
    shouldOpenFolderOnSave,
    shouldOpenFolderOnReply,
    shouldOpenFolderOnNote,
    currentTicketIds,
    nextTicketId,
    shouldAdvance,
    currentTicketId,
    ticketId,
    operation,
    forced
  ) => {
    const shouldOpenFolder =
      (operation === 'save' && shouldOpenFolderOnSave) ||
      (operation === 'reply' && shouldOpenFolderOnReply) ||
      (operation === 'note' && shouldOpenFolderOnNote)

    const isStillInCurrentQueryList = currentTicketIds.includes(ticketId)

    if (forced) {
      return true
    } else if (
      currentTicketId !== ticketId ||
      currentTicketId === nextTicketId
    ) {
      return false
    } else if (shouldAdvance) {
      if (classicView) {
        if (shouldOpenFolder) {
          return true
        }
      } else if (!isStillInCurrentQueryList && nextTicketId) {
        return true
      }
    }
    return false
  }
)
