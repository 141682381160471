import createCachedSelector from 're-reselect'
import { emptyArr } from 'util/arrays'
import { selectMessageById } from '.'
import { selectAttachmentsById } from './selectAttachmentsById'

export const selectAttachmentsByMessageId = createCachedSelector(
  selectMessageById,
  selectAttachmentsById,
  (_state, messageId) => messageId,
  (message, attachmentsById) => {
    if (!message.attachments) return emptyArr
    return message.attachments.map(aid => attachmentsById[aid])
  }
)((_state, messageId) => messageId || 'unknown')
