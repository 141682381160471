import { mergePlain } from 'util/merge'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { emptyArr, without } from 'util/arrays'
import GA from 'util/googleAnalytics'
import { UPDATE_CONVERSATION_UNFOLLOW } from '../actionTypes'
import { updateUnfollowConversationdMutation } from '../mutations'
import { CONVERSATION_SAVE_UNLOAD_MESSAGE } from '../constants'
import { conversationUnfollowGraphQlResponseSchema } from '../schema'
import { selectCurrentConversationById } from '../selectors'
import { onUpdateAttachEventGroupIdToEvents } from '../utils/request'

export const doUnfollow = (ticketId, options = {}) => (dispatch, getState) => {
  const conversationId = buildId('Conversation', ticketId)

  const state = getState()
  const ticket = selectCurrentConversationById(state, ticketId)
  const currentUserGid = selectCurrentUserGlobalId(state)
  const currentFollowers = ticket?.followers || emptyArr
  if (
    !ticket ||
    currentFollowers.length === 0 ||
    !currentFollowers.includes(currentUserGid)
  )
    return null

  const updatedConversation = {
    ...ticket,
    followers: without([...ticket.followers], currentUserGid),
  }

  if (options.trackType) {
    GA.track(options.trackType, 'Clicked', 'Follow ticket')
  }

  return dispatch(
    doGraphqlRequest(
      UPDATE_CONVERSATION_UNFOLLOW,
      updateUnfollowConversationdMutation(),
      {
        conversationId,
        followerIds: [currentUserGid],
      },
      mergePlain(
        {
          app: true,
          throwOnError: true,
          concurrency: {
            key: ticketId,
            message: CONVERSATION_SAVE_UNLOAD_MESSAGE,
          },
          normalizationSchema: conversationUnfollowGraphQlResponseSchema,
          transformResponse: onUpdateAttachEventGroupIdToEvents(
            'conversationUnfollow'
          ),
          optimist: {
            entities: {
              conversation: {
                [ticketId]: updatedConversation,
              },
            },
          },
          moduleOptions: {
            snackbar: {
              enabled: true,
              success: {
                enabled: true,
                message: `${app.t('Ticket')} unfollowed`,
              },
              failed: {
                enabled: true,
                message: `Oops, we couldn't unfollow your ${app.t('ticket')}.`,
              },
            },
          },
          meta: {
            mergeEntities: true,
          },
        },
        options
      )
    )
  )
}
