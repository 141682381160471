import { createBasicSelector } from 'util/redux'
import { selectCurrentTicketId } from './selectCurrentTicketId'
import { selectConversationEventsForConversationId } from '.'

export const selectCurrentConversationEvents = createBasicSelector(
  state => state,
  selectCurrentTicketId,
  (state, currentTicketId) => {
    return selectConversationEventsForConversationId(state, currentTicketId)
  }
)
