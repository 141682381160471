import { doBulkUpdate } from './doBulkUpdate'
import { doToggleTicketState } from './doToggleTicketState'

export const doBulkToggleTicketState = (
  ticketIds,
  state,
  options = {}
) => dispatch => {
  return dispatch(
    doBulkUpdate(ticketIds, doToggleTicketState, [state], options)
  )
}
